.prev-sessions-modal {
	max-height: 500px;
	overflow: auto;
	min-width: 500px;
	display: flex;
	align-items: stretch;
	flex-direction: column;
	padding: 0 0.5rem;
	min-height: 90px;
}

.modal.modal-prevsessions {
	background-color: var(--lightlightgrey);
}
