$content-border-radius: $section-border-radius;

.in-side-call .initial-information-modal {
	right: var(--filmstrip-width);
}

.initial-information-modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $modal-overlay-background-color;
	display: flex;
	flex-direction: column;
	z-index: 2;

	opacity: 1;
	animation: 0.3s fadeInAnim ease-out;

	.icon-button {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0;
		border-radius: 0;
		.icon {
			color: $white;
			fill: $white;
		}
	}

	&__main {
		flex-grow: 1;
		height: 1px;

		display: flex;
		flex-direction: column;
		align-items: center;

		justify-content: center;

		color: #ffffffdb;
		z-index: 100;

		.bubble-heading {
			color: white;
			margin-bottom: 2rem;
		}

		> * {
			margin-bottom: 2rem;
		}
	}

	&__content {
		border: solid 1px var(--accent-colour-1);
		padding: 1.5rem 2rem 2rem 2rem;
		border-radius: $section-border-radius;
		background-color: var(--panel-background-colour);
		color: var(--base-text-colour);

		min-width: 600px;
		width: 66vw;
		max-width: 1000px;

		min-height: 70px;

		// font-size: 1.2rem;
		line-height: 1.6rem;
		word-break: break-word;
		white-space: pre-wrap;

		overflow: auto;

		display: flex;
		flex-direction: column;
		// align-items: center;
		align-items: flex-start;
		justify-content: center;

		h2 {
			margin-top: 0;
			margin-bottom: 20px !important;
		}
	}

	&__button-row {
		height: 50px;
		width: calc(100% + 6rem);
		margin-top: 1rem;
		align-items: center;
		justify-content: space-between;
		flex-direction: row-reverse;

		border-top: solid 1px var(--border-colour-1);
		margin-left: -3rem;
		margin-right: -3rem;
		padding-left: 3rem;
		padding-right: 2.5rem;
		margin-bottom: -1rem;
		padding-top: 15px;
		margin-top: 2rem;
	}

	&__translate {
		color: var(--primary-button-colour);

		display: flex;
		align-items: center;
		justify-content: center;

		flex-shrink: 0;

		padding-bottom: 5px;

		cursor: pointer;

		&:hover {
			color: var(--primary-button-hover-colour);
		}
	}
}

@keyframes fadeInAnim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
