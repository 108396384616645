.welcome-screen {
	position: relative;
	width: 100%;
	// height: calc(100% - 90px);

	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0px;
	font-size: 18px;
	overflow: hidden;

	&__content {
		height: 100%;
		max-height: calc(100% - 80px);

		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__time {
		font-size: 55px;
		font-style: normal;
		font-weight: 300;
		line-height: 75px;
		margin-top: 15px;
	}

	&__date {
		font-size: 20px;
		font-style: normal;
		font-weight: 300;
		line-height: 27px;
	}

	&__logo {
		width: 80%;
		height: 23%;
		margin: 40px 10px;
		background: var(--logo-1);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}

	&__message {
		flex-grow: 1;
		max-height: 55%;

		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: 22px;
		overflow-y: auto;
		overflow-x: hidden;
		white-space: pre-line;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 4px;
	}

	&__translate {
		color: var(--primary-button-colour);
		font-size: 1rem;
		font-weight: 500;
		cursor: pointer;
		line-height: 1.4rem;
		&:hover {
			color: var(--primary-button-hover-colour);
		}
	}

	.virtual-phone-apps {
		flex-shrink: 0;
		// position: absolute;
		// bottom: 0;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
