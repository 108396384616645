/**********************************************************************************************************************/
/* Several styles need to be applied to range inputs in all browsers to override their basic appearance.              */
/**********************************************************************************************************************/
input[type='range'] {
	-webkit-appearance: none; /* Hides the slider so that custom slider can be made */
	width: 100%; /* Specific width is required for Firefox. */
	background: transparent; /* Otherwise white in Chrome */
}

input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
}

input[type='range']:focus {
	outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

/**********************************************************************************************************************/
/* The widget that you drag along the track is called the thumb. It can be styled just like a regular HTML element.   */
/**********************************************************************************************************************/
/* Special styling for WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	// border: 1px solid #000000;
	height: 12px;
	width: 12px;
	border-radius: 99px;
	background: #ffffff;
	cursor: pointer;
	margin-top: -4px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
	// box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}
input[type='range']:active::-webkit-slider-thumb {
	background: var(--accent-colour-2);
}

// /* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
	// border: 1px solid #000000;
	height: 10px;
	width: 10px;
	border-radius: 5px;
	background: #ffffff;
	cursor: pointer;
}

/**********************************************************************************************************************/
/* The line that the thumb moves across is called the track. It is also styled just like a regular HTML element.      */
/**********************************************************************************************************************/
input[type='range']::-webkit-slider-runnable-track {
	width: 100%;
	height: 6px;
	cursor: pointer;
	// box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
	background: var(--accent-colour-1);
	border-radius: 99px;
	border: 0.2px solid #010101;
}

input[type='range']:focus::-webkit-slider-runnable-track {
	// background: var(--accent-colour-2);
}

input[type='range']::-moz-range-track {
	width: 100%;
	height: 6px;
	cursor: pointer;
	// box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
	background: var(--accent-colour-1);
	border-radius: 99px;
	border: 0.2px solid #010101;
}

input[type='range']::-webkit-slider-fill {
	background-color: orange;
}
