.tutor-groups {
	display: flex;
	flex-direction: row;
	width: 100%;

	padding: 0.1rem 0.3rem 1rem 0.3rem;

	// background-color: $white;

	.messages-panel__message-list-container,
	.groups-list,
	.decision-log__scroller,
	.media-feed {
		background: $lightlightgrey;
	}

	.in-call & {
		// Transparent background if in a call
		background-color: transparent;
		.messages-panel__message-list-container,
		.groups-list,
		.decision-log__scroller,
		.media-feed {
			background: rgba(255, 255, 255, 0.8);
		}
	}

	&__col {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	&__col:nth-child(1) {
		width: 220px;
		flex-grow: 1;
		min-width: 220px;
		max-width: 220px;
		flex-shrink: 0;
	}

	&__col:nth-child(2) {
		min-width: 200px;
		flex-grow: 1;
	}

	&__col:nth-child(3) {
		width: 250px;
		flex-grow: 1;
		max-width: 350px;
	}

	&__col:nth-child(4) {
		width: 250px;
		flex-grow: 1;
		min-width: 250px;
		max-width: 350px;
	}

	.groups-list,
	.decision-log,
	.media-feed {
		width: 100%;
		max-width: 100%;
		min-width: 100%;
	}

	.bubble-heading {
		// margin-bottom: -2px;
		// z-index: 1;
		// padding: 0rem 2.5rem;
		// font-size: 20px;
		margin-bottom: 0px;
		z-index: 1;
		padding: 0;
		font-size: 18px;
		padding-top: 3px;
		line-height: 38px;
		height: 42px;
		transform: translateY(0px);
	}

	.groups-list,
	.decision-log__scroller,
	.messages-panel__message-list-container {
		padding-top: 1.15rem;
	}

	.media-feed__content {
		padding-top: 1.15rem;
	}

	.groups-list,
	.decision-log__scroller,
	.media-feed__content,
	.message-list {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	/* ---------------------------------------------------------------------------------------------*/

	.decision-log {
		flex-grow: 1;
		.decision-log__inner {
			padding: 0;
		}
	}

	.decision-log__scroller {
		border: solid 1px var(--border-colour-1);
		border-radius: $section-border-radius;
		padding-bottom: 1rem;
	}

	/* ---------------------------------------------------------------------------------------------*/

	.media-feed {
		border: solid 1px var(--border-colour-1);
		height: 100%;
		max-height: 100%;
	}

	.media-item {
		cursor: pointer;
		* {
			cursor: pointer;
		}
	}

	/* ---------------------------------------------------------------------------------------------*/

	.messages-panel {
		width: 100%;
		min-width: 100%;
		max-width: 100%;

		.messages-panel__message-list-container {
			padding: 0;
			border: solid 1px var(--border-colour-1);
		}
		.message-list {
			padding: 1.15rem 1rem 1rem 1rem;
		}
	}
}
