$silver: #ebebeb;
$offsilver: #aaaaaa;
$darksilver: #333333;
$darkdarksilver: #282828;
$mazda-black: #202020;
$mazda-blue: #428227;
$mazda-blue2: #4da02a;

body.alixpartners {
	--accent-colour-1: #{$silver};
	--primary-button-colour: #{$mazda-blue};
	--primary-button-hover-colour: #{lighten($mazda-blue, 15%)};

	--button-colour: #{$mazda-blue};
	--button-hover-colour: #{lighten($mazda-blue, 15%)};
	--accent-colour-2: #{$mazda-blue};
	--button-2-hover-colour: #{$mazda-blue};

	--header-colour: #{$mazda-black};
	--header-text-colour: white;
	--header-border-colour: #{$mazda-blue};

	--border-colour-2: #{$silver};

	--heading-colour: #{$mazda-black};
	--heading-text-colour: #{$mazda-black};
	--heading-border-colour: #{$silver};

	--sidebar-colour: #{$darkdarksilver};

	--login-button-colour: #{$silver};
	--login-button-icon-colour: #{$mazda-black};
	--login-button-hover-colour: #{$mazda-blue};
	--login-button-hover-icon-colour: white;

	--login-background-colour: #{$mazda-black};
	--logo-4-width: 170px;
	--login-logo-width: 400px;
	--login-logo-transform: translate(70px, 0px);
	--logo-1: url('/src/themes/alixpartners/fulllogo.png');
	--logo-3: url('/src/themes/alixpartners/AP_Wordmark_Black.svg');
	--logo-4: url('/src/themes/alixpartners/AP_Wordmark_White.svg');
	// --logo-5: url('/src/themes/alixpartners/AP_Wordmark_Black.svg');
	--scrollbar-background-colour: #{transparentize($mazda-blue, 0.9)};
}
