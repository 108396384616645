.section {
	@include configPanelCard();
	position: relative;
	margin-bottom: 1rem;
	transition: background-color 500ms, color 500ms;
	padding-bottom: 12px;

	> h2 {
		margin-top: 0.6rem;
		margin-bottom: 1.6rem;
	}

	> p {
		padding-left: 2px;
	}

	.button {
		width: auto;
		margin-left: 1rem;
	}

	label {
		margin-bottom: 0.5rem;
	}

	h2 {
		transition: color 500ms;
	}

	&--disabled {
		opacity: 0.5;
		pointer-events: none;

		select,
		textarea,
		input {
			background-color: transparent;
			color: $darkgrey;
		}
		button {
			opacity: 0.8;
		}
		.pill {
			opacity: 0.8;
		}
	}
}
