// =================================================================================================
// New:

.side-call {
	max-width: var(--filmstrip-width);
	min-width: var(--filmstrip-width);
	width: var(--filmstrip-width);
	flex-shrink: 0;
	border-left: solid 1px var(--border-colour-1);
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: var(--panel-background-colour);
	padding-top: 4px;

	padding-left: 4px;
	padding-right: 4px;

	&__message {
		height: 2rem;
		margin: -2px -4px 0px -4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;

		.dot {
			height: 1rem;
			width: 1rem;
			margin-right: 0.5rem;
			border-radius: 1rem;
			transform: translate(0px, -1px);
		}
	}

	&__participants {
		height: 1px;
		flex-grow: 1;
		overflow: auto;
		padding-top: 70px;
	}

	.video-call-participant {
		.video-call-participant__inner {
			border-color: $mediumgrey;
		}

		// Ensure they appear clickable
		cursor: pointer;
		* {
			cursor: pointer;
		}
	}
}

// =================================================================================================
// Old:

#side-call {
	max-width: var(--filmstrip-width);
	min-width: var(--filmstrip-width);
	width: var(--filmstrip-width);
	flex-shrink: 0;
	border-left: solid 1px var(--border-colour-1);
	display: flex;
	flex-direction: column;
	height: 100%;

	&__content {
		height: 1px;
		flex-grow: 1;
	}
}
