$app-margin: 0px;

.virtual-phone-apps {
	width: 100%;
	display: grid;
	background-color: #000;

	padding: 10px 35px 14px 35px;

	display: grid;
	grid-column-gap: 10px;
	// grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-row-gap: 10px;
	justify-content: center;

	&__app-container {
		width: 50px;
		padding-top: 50px;
		position: relative;
	}

	&__app {
		background-color: rgba(255, 255, 255, 0.06);
		border-radius: 10px;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		.virtual-phone-apps__app-overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 10px;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 80%, rgba(255, 255, 255, 0.15) 100%);
		}

		&:hover {
			.virtual-phone-apps__app-overlay {
				background: rgba(255, 255, 255, 0.2);
			}
		}

		* {
			cursor: pointer;
		}
		.icon {
			height: 28px;
			width: 28px;
			font-size: 28px;
		}
		.icon.icon-whatsapp {
			height: 32px;
			width: 32px;
			font-size: 32px;
		}
		.icon.icon-message {
			height: 24px;
			width: 24px;
			font-size: 24px;
			transform: translateY(2px);
		}
		.badge {
			height: 18px;
			width: 18px;
			border-radius: 99px;
			border: solid 2px white;
			background: var(--red);
			position: absolute;
			top: -5px;
			right: -5px;
		}
	}
}

.virtual-phone-apps__app--alert {
	animation: flash 1s steps(1) infinite;

	@keyframes flash {
		0% {
			background: var(--accent-colour-1);
		}
		50% {
			background: var(--accent-colour-2);
		}
	}
}
