$neonred: #f72717;
$nickel: #747779;
$black: #000000;

body.garda {
	--accent-colour-1: #{$neonred};
	--header-border-colour: #{$neonred};

	--button-colour: #{$nickel};
	--button-hover-colour: #{$neonred};
	--accent-colour-2: #{$neonred};
	--button-2-hover-colour: #{$nickel};

	--header-colour: #{$black};
	--header-text-colour: white;

	--border-colour-2: #{$neonred};

	// --heading-colour: #{$nickel};
	--heading-text-colour: #{$nickel};
	--heading-border-colour: #{$neonred};

	--sidebar-colour: #{$nickel};

	// --login-text-colour: #{white};
	--login-button-colour: #{$nickel};
	--login-button-icon-colour: white;

	--login-button-hover-colour: #{$neonred};
	--login-button-hover-icon-colour: white;

	--login-background-colour: #{$black};
	--logo-4-width: 57px;
	--logo-1: url('/src/themes/garda/crest-white.png');
	--logo-3: url('/src/themes/garda/Gardaworld-Logo.png');
	--logo-4: url('/src/themes/garda/logo-small.jpg');
	--logo-5: url('/src/themes/garda/logo-small.jpg');

	--scrollbar-background-colour: #{transparentize($neonred, 0.9)};

	--primary-button-colour: var(--accent-colour-1);
	--primary-button-hover-colour: #{$nickel};
}
