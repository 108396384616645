.tutor-prev-sessions {
	@include tutorConfigPanel();

	.prev-session {
		flex-direction: row;

		.left {
			flex-grow: 1;
		}

		.right {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-end;
			max-width: 50%;

			.button {
				button {
					margin: 0;
				}
			}
		}
	}
}
