.icon-button {
	position: relative;

	height: 40px;
	width: 40px;
	padding: 4px;
	margin: 0;

	border-radius: 99px;

	font-size: 24px;
	font-weight: 200;
	text-align: center;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	* {
		cursor: pointer;
	}

	&--disabled {
		opacity: 0.3;
		pointer-events: none;
	}

	.icon {
		background-color: transparent !important;
		color: var(--base-text-colour);
		fill: var(--base-text-colour);
	}

	&__text {
		font-size: 13px;
		line-height: 13px;
		font-family: var(--base-font-family) !important;
		position: absolute;
		bottom: -20px;
		text-transform: uppercase;
		opacity: 0.9;
		white-space: nowrap;
		text-align: center;
		overflow: visible;
	}

	// &:focus,
	// &:hover {
	// 	transition: none;
	// 	background-color: var(--button-hover-colour);
	// 	.icon {
	// 		transition: none;
	// 		background-color: var(--button-hover-colour);
	// 		color: var(--button-text-colour);
	// 	}
	// }

	&:hover {
		transition: none;
		background-color: var(--button-hover-colour);
		.icon {
			transition: none;
			background-color: var(--button-hover-colour);
			color: var(--button-text-colour);
		}
	}

	&:active,
	&--selected,
	&--selected:hover {
		background-color: var(--accent-colour-2);
		.icon {
			background-color: var(--accent-colour-2);
			color: var(--button-text-colour);
			fill: var(--base-text-colour);
		}
	}

	.badge {
		background-color: $red;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		position: absolute;
		bottom: 6px;
		right: 6px;
		border: solid 2px $sidebar-background-color;
	}
}
