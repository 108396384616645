.tutor-participants {
	@include tutorConfigPanel();
	max-width: 1000px;
}

.tutor-participants__bottom {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.tutor-participants {
}
