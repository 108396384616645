.alert {
	&__title {
		font-size: 1.4rem;
		line-height: 1.8rem;
	}

	&__button {
		@extend %button;
		width: 10rem !important;
		margin: 0.4rem 1rem !important;
	}

	&__button--cancel {
		background-color: #696969 !important;
		&:hover {
			background-color: #888888 !important;
		}
	}
}

/* Double selector to ensure it overrides the .swal2-title selector */
.alert__title.alert__title {
	font-size: 1.4rem;
	line-height: 1.8rem;
}

.alert__toast-icon {
	color: white;
}

body {
	.swal2-popup {
		.alert__toast-title {
			color: white;
		}
		.alert__toast-content {
			color: white;
		}
		.alert__toast-icon.alert__toast-icon {
			color: rgba(255, 255, 255, 0.8);
			border-color: rgba(255, 255, 255, 0.4);
		}

		&.swal2-toast.alert__toast {
			background-color: $red;
			box-shadow: none;
			padding: 0.7rem 1rem;
		}

		.swal2-icon.swal2-warning {
			border-color: var(--accent-colour-2);
			color: var(--accent-colour-2);
		}

		&.swal2-modal {
			border-radius: $section-border-radius;
		}

		.swal2-content {
			opacity: 0.7;
			font-size: 1rem;
			// padding: 1rem 0;
			margin-top: 0.7rem;
			line-height: 1.5rem;
		}

		&.swal2-toast {
			padding: 0.2rem 1rem;
			margin-bottom: 1rem;

			.swal2-content {
				margin-top: 0;
			}
			.alert__button {
				width: auto !important;
				margin-right: 0 !important;
			}
			.swal2-icon.swal2-info .swal2-icon-content {
				transform: translate(0px, 2px);
			}
			.swal2-icon {
				zoom: 0.75;
			}
		}
	}

	/* Prevent scrollbar on container for bottom toast alerts */
	.swal2-container.swal2-bottom {
		overflow: hidden !important;
	}

	/* Popup overlay colour */
	.swal2-container.swal2-backdrop-show,
	.swal2-container.swal2-noanimation {
		background: $modal-overlay-background-color;
	}
}

// -------------------------------------------------------------------------------------------------

@keyframes alert__show {
	0% {
		transform: translateY(-10em);
	}

	33% {
		transform: translateY(0);
	}

	66% {
		transform: translateY(0.3125em);
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes alert__hide {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-10em);
	}
}

.alert__show {
	animation: alert__show 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); // ease-out-cubic
}
.alert__hide {
	animation: alert__hide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); // ease-out-cubic
}

// -------------------------------------------------------------------------------------------------

@keyframes alert__show-bottom {
	0% {
		transform: translateY(10em);
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes alert__hide-bottom {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(10em);
	}
}

.alert__show-bottom {
	animation: alert__show-bottom 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); // ease-out-cubic
}
.alert__hide-bottom {
	animation: alert__hide-bottom 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); // ease-out-cubic
}

// -------------------------------------------------------------------------------------------------
