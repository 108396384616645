#main-call {
	display: flex;
	flex-direction: column;
}

.tutor-view {
	#main-call {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.group-view {
	#main-call {
		flex-grow: 1;
		height: 100%; /* For iOS Safari */
	}
}

#main-call__content {
	flex-grow: 1;
	height: 100%; /* For iOS Safari */
}

.main-call__mute-message {
	text-align: center;
	background-color: $red;
	line-height: 1.2rem;
	font-size: 1.2rem;
	color: white;
	padding: 0.5rem;
	padding-top: 0.7rem;
}
