.login-group {
	&__main {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: flex-start;
		text-align: center;

		span {
			text-align: center;
			margin-bottom: 20px;
		}
	}
}
