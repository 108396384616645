.pdf-viewer {
	overflow: auto;
	box-sizing: content-box;
	display: flex;
	position: relative;
	max-height: 83vh;
	justify-content: center;
	// max-width: 1280px; // This is set in PDFViewer.js

	.react-pdf__Page {
		border-bottom: solid 2px grey;
	}

	.react-pdf__Page:last-child {
		border-bottom: none;
	}
}

.pdf-viewer--loading {
	overflow: hidden;
}
