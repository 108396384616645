.wait-message {
	background-color: white;
	border-radius: $section-border-radius;
	box-shadow: 0px 25px 25px -15px rgba(0, 0, 0, 0.25);
	border: none;
	min-width: 500px;
	max-width: 80%;
	height: auto;
	padding: 0.5rem;

	&__inner {
		align-items: center;
		justify-content: center;
		flex-direction: column;
		pointer-events: all;
		text-align: center;
		p {
			flex-grow: 1;
			padding: 0rem 1rem;
			white-space: pre-wrap;
		}
		p,
		h3 {
			margin: 1.2rem 0;
			text-align: center !important;
		}
		p:first-of-type {
			margin-top: 0;
		}
		.bubble-heading {
			margin-bottom: 1.2rem;
			margin-top: 0.4rem;
		}
	}
}

.wait-message-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.4);
}

.participant-view {
	.wait-message {
		display: flex;
		align-items: center;
		flex-direction: column;
		// padding-bottom: 0;

		.bubble-heading {
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--accent-colour-2);
		}
		p {
			margin: 0rem 0 2.1rem 0;
		}
		h3 {
			// height: 60px;
			// width: 60px;
			font-size: 2rem;
			border-radius: 200px;
			display: flex;
			align-items: center;
			justify-content: center;
			// background-color: var(--panel-background-colour);
			margin: auto;
			margin-bottom: 1.3rem;
			text-align: center;
		}
	}
}
