.app-header {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50px;
	flex-shrink: 0;
	background: #282c44;

	h2 {
		margin: 0;
	}

	.icon-button {
		position: absolute;
		left: 0;
		background-color: none;
		border-radius: 0;
		width: 50px;
		height: 50px;
	}
}

.video-player {
	overflow: hidden;

	&__content {
		justify-content: flex-start;
		padding-top: 10%;

		> * {
			margin-bottom: 0;
		}

		.video-player__video-container {
			position: relative;
			max-height: 33%;
		}

		video {
			width: 100%;
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
			margin-bottom: -3px;
		}

		.video-player__clock1, .video-player__clock2 {
			color: $darkgrey
		}

		.video-player__controls-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background-color: rgba(255, 255, 255, 0.5);
			opacity: 0;
			transition: opacity 100ms;

			cursor: pointer;
			* {
				cursor: pointer;
			}
			&:hover {
				opacity: 1;
			}

			p {
				color: black;
				padding: 0;
				text-transform: uppercase;
			}

			.icon-button {
				width: 80px;
				height: 80px;
				background: transparent;
				.icon {
					color: rgba(0, 0, 0, 0.8);
					font-size: 2.2rem;
				}
			}
		}

		progress {
			height: 5px;
			background-color: rgba(128, 128, 128, 0.1);
			border-radius: 5px;
			&::-webkit-progress-bar {
				background-color: rgba(128, 128, 128, 0.1);
			}
			&::-webkit-progress-value {
				background-color: var(--accent-colour-2);
			}
			&::-moz-progress-bar {
				background-color: var(--accent-colour-2);
			}
		}

		.row {
			justify-content: space-evenly;
			align-items: center;
			height: 30px;
		}

		.row.video-player__controls {
			height: auto;
			padding: 14px 0;

			.icon-button {
				background-color: var(--accent-colour-1);
				&:hover {
					background-color: var(--accent-colour-2);
				}
			}
		}

		.video-player__titles {
			text-align: center;
			
			h3 {
				color: $darkgrey !important;
				text-align: center;
			}
		}

		h3 {
			padding-left: 10px;
			// margin-bottom: 4px;
			color: $darkgrey;
		}

		p {
			padding-left: 10px;
			margin: 0;
			margin-bottom: 6px;
		}

		.icon-button {
			height: 48px;
			width: 48px;

			.icon {
				font-size: 1.5rem;
			}
			.icon.icon-play {
				transform: translateX(-2px);
			}
		}

		.video-player__expand-section {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			// flex-grow: 1;
			p {
				text-align: center;
				margin: 30px 20px 10px 20px;
			}
		}
	}
}

.light-mode {
	.video_player {
		color: black;
		background: white;

		&__header {
			background: white;
			border-bottom: solid 1px var(--border-colour-1);
		}
		.row {
			border-bottom: solid 1px var(--border-colour-1);
		}
		.video-player__titles {
			border-bottom: solid 1px var(--border-colour-1);
		}
		&__content {
			.icon-button {
				color: var(--base-text-colour);

				&:hover {
					background-color: var(--primary-button-colour);
					.icon {
						color: var(--primary-button-text-colour);
					}
				}

				.icon {
					color: var(--base-text-colour);
				}
			}
		}

		video {
			border-bottom: solid 1px var(--border-colour-1);
		}
	}
}
