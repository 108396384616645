$silver: #ebebeb;
$offsilver: #aaaaaa;
$darksilver: #333333;
$darkdarksilver: #2d2926;
$black: #1f1f1e;
$gold: #bc996e;
$red: #d71a21;

body.emirates {
	--accent-colour-1: #{$darkdarksilver};
	--primary-button-colour: #{$gold};
	--primary-button-hover-colour: #{darken($gold, 15%)};

	--button-colour: #{$gold};
	--button-hover-colour: #{darken($gold, 15%)};
	--accent-colour-2: #{$red};
	--button-2-hover-colour: #{$gold};

	--header-colour: #{$black};
	--header-text-colour: white;
	--header-border-colour: #{$gold};

	--border-colour-1: #{$silver};
	--border-colour-2: #{$silver};

	--heading-colour: #{$black};
	--heading-text-colour: #{$black};
	--heading-border-colour: #{$red};

	--sidebar-colour: #{$darkdarksilver};

	--login-button-colour: #{$silver};
	--login-button-icon-colour: #{$black};
	--login-button-hover-colour: #{$gold};
	--login-button-hover-icon-colour: white;

	--login-background-colour: #{$red};
	--logo-4-width: 57px;
	--login-logo-width: 400px;
	--login-logo-transform: translate(70px, 0px);
	--logo-1: url('/src/themes/emirates/logo.svg');
	--logo-4: url('/src/themes/emirates/logo-clear-square-small.png');
	--logo-5: url('/src/themes/emirates/logo-square-red-padded.svg');

	--logo-5-width: 68px;
	--logo-5-margin: 0px;

	--scrollbar-background-colour: #{transparentize($gold, 0.9)};

	.participant-header-bar__logo {
		margin-right: 10px !important;
		flex-shrink: 0 !important;
		border-radius: 7px !important;
		margin-top: 11px !important;
		width: 51px !important;
		height: 51px !important;
		margin-left: 13px !important;
		background-size: cover !important;
	}
}
