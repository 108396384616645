@use 'sass:math';

// $messsage-spacing: 14px;
$minHeight: 47px;
$avatarCircleSize: $minHeight - 18px;
$circlePadding: math.div($minHeight - $avatarCircleSize, 2);

.message {
	min-height: $minHeight;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background-color: $white;
	border-radius: $section-border-radius;
	border: solid 1px $mediumgrey;
	padding-left: $circlePadding;
	padding-right: $circlePadding;
	overflow: hidden;

	.icon,
	.circle {
		width: $avatarCircleSize;
		height: $avatarCircleSize;
		border-radius: 50%;
		color: $white;
		background-color: var(--header-colour);
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0px;
	}

	.icon.fa-user {
		color: var(--base-text-colour);
		background-color: $mediumgrey;
	}

	&__private-details {
		width: calc(100% + 20px);
		background: var(--accent-colour-1);
		color: white;
		border-radius: 0;
		font-size: 14px;
		padding: 2px 0px 1px 15px;
		margin-top: 0px;
		margin-left: -10px;
		margin-right: -10px;
	}

	&__left {
		padding: 10px 10px 9px 0px;
	}

	&__right {
		padding-top: 3px;
		// display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding-bottom: 5px;
		padding-right: 1px;
		width: 1px;
		flex-grow: 1;
	}

	&__top {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		padding-top: 3px;
		width: 100%;
		margin-bottom: 2px;
	}

	&__from {
		font-size: $base-font-size;
		font-weight: bold;
		margin-right: 0.5rem;
		@include ellipsisTruncate;
	}

	&__name {
		opacity: 0.6;
		font-weight: normal;
		font-size: $small-font-size;
		line-height: $small-font-size;
		margin-left: 0.5rem;
		@include ellipsisTruncate;
	}

	&__at {
		flex-grow: 1;
		flex-shrink: 0;
		opacity: 0.6;
		margin-left: 0.5rem;
		font-size: $small-font-size;
		line-height: $small-font-size;
		text-align: right;
	}

	&__text {
		display: inline;
		font-size: $base-font-size;
		word-break: break-word;
		white-space: pre-wrap;
		margin-top: 5px;
		line-height: 1.3rem;
	}

	&__translate {
		display: inline;
		float: right;
		flex-shrink: 0;
		font-size: $small-font-size;
		line-height: 1.3rem;
		opacity: 0.8;
		text-align: right;
		margin-top: 0px;

		transition: color 200ms;
		color: var(--accent-colour-1) !important;

		cursor: pointer;
		* {
			cursor: pointer;
		}

		&:hover,
		&--active {
			opacity: 1;
		}
	}
}

.message--private {
	border: solid 2px var(--accent-colour-1);
}
