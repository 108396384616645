.voice-message-screen {
	// width: 500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0rem 1rem 2rem 1rem;
	overflow: hidden;

	h2 {
		width: 100%;
		margin: 0;
		color: var(--white);
		text-align: left;

		font-size: 27px !important;
		text-transform: none !important;
		font-weight: 300 !important;
		line-height: 23px !important;
	}

	h3 {
		width: 100%;
		margin: 0;
		color: var(--white) !important;
		text-align: left;
		font-weight: 500 !important;
		line-height: 30px !important;
	}

	.virtual-phone__bg2 {
		opacity: 1;
		animation-play-state: running;
	}

	&--inactive {
		.virtual-phone__bg2 {
			animation-play-state: paused;
		}
	}

	&__top-row {
		height: 60px;

		.voice-message-screen__close-button.icon-button {
			position: absolute;
			top: 0;
			right: 0;
			border-radius: 0;
			background-color: transparent;
			width: 60px;
			height: 60px;
		}
	}

	&__header,
	&__content,
	&__footer {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	&__header {
		// height: 50px;
		padding-top: 5px;
		position: relative;
		justify-content: space-evenly;
		background-color: rgba(255, 255, 255, 0.2);

		margin-left: -30px;
		margin-right: -30px;

		padding-left: 30px;
		padding-right: 30px;
		padding-top: 10px;
		padding-bottom: 2px;

		width: calc(100% + 60px);
		transition: transform 1s $default-easing;
		transform: translateY(-240px);
	}

	&__content {
		flex-grow: 1;
		justify-content: center;
		margin-top: -60px;
		transition: margin 1s $default-easing;
		h3 {
			text-align: center !important;
		}
	}

	.icon-button {
		background-color: rgba(255, 255, 255, 0.2);
		width: 64px;
		height: 64px;
		color: white;
		.icon {
			color: white;
		}
		&__text {
			text-transform: lowercase;
		}
		&:hover {
			background-color: var(--accent-colour-2);
		}
	}

	&__footer {
		height: 25%;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
	}

	&--pending {
		.voice-message-screen__header {
			transform: translateY(0px);
		}
		.voice-message-screen__content {
			margin-top: 0px;
		}
	}

	&__phone-icon {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		position: absolute;
		top: 12px;
		right: 30px;
		.icon {
			font-size: 20px;
		}
	}

	> .icon {
		color: var(--accent-colour-2);
		font-size: 64px;
		border: solid 4px var(--accent-colour-2);
		border-radius: 999px;
		padding: 25px;
		margin: 1rem;
	}

	.audio-player__play-button {
		background-color: var(--green);
	}

	.audio-player__close-button {
		background-color: var(--red);
	}

	.phone-preloader {
		margin-top: 1.2rem;
		margin-bottom: 1.2rem;
		align-items: center;
		&__ring1 {
			border-color: var(--white);
		}
	}

	p {
		text-align: center;
		margin-top: 0.7rem;
		margin-bottom: 0rem;
	}

	.button {
		margin: 1.3rem 5px 0 5px;
	}
}
