.notepad {
	background-color: var(--panel-background-colour);
	border: solid 1px var(--border-colour-1);
	padding: 30px 30px 20px 30px;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	min-height: 200px;

	height: 100%;
	display: flex;
	flex-direction: column;

	> .row:first-child {
		justify-content: flex-end;
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.textarea {
		height: 1px;
		flex-grow: 1;
		margin-top: 10px;
	}

	> .row:first-child {
		width: 100%;
	}
}
