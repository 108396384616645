.app-header-bar {
	display: flex;
	align-content: center;
	justify-content: center;
	line-height: 28px;
	flex-shrink: 0;
	background-color: var(--virtual-phone__bg);
	color: var(--white);
	width: 100%;

	line-height: 30px;
	font-weight: 700;
	letter-spacing: 1px;
	overflow-x: hidden;

	&--zero {
		color: inherit;
		background-color: var(--virtual-phone__bg);
		color: rgba(255, 255, 255, 0.7);
	}
}
