.video-participant-modal {
	.video-call-participant {
		// pointer-events: none;
		width: 640px;
		height: 480px;
	}
	label {
		flex-grow: 1;
	}
	&__stats {
		margin-top: 1rem;
		.row {
			margin-top: 2px;
		}
	}
	.video-call-participant:hover {
		cursor: default;
		* {
			cursor: default;
		}
		.video-call-participant__inner {
			border-color: $mediumgrey !important;
		}
	}
}
