.participant-decision-log {
	background-color: var(--panel-background-colour);
	border: solid 1px var(--border-colour-1);
	border-top-left-radius: 0;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;

	display: flex;
	flex-direction: column;
	height: 100%;

	padding: 7px 24px 0px 24px;

	h3:first-child {
		width: calc(50% + 5px); // 5px = half the column gap in section below
	}

	.decision-log {
		.decision-log__scroller {
			flex-grow: 1;
			margin-bottom: 4px;
			padding-right: 10px;
			margin-right: -10px;
		}
		.decision-log__header {
			justify-content: flex-start;
		}
		.decision-log__advice textarea,
		.decision-log__rationale textarea,
		.decision-log__advice .resizer,
		.decision-log__rationale .resizer,
		.decision-log__box {
			border-radius: 5px;
			font-size: relativePixels(14); // 14px, but scales according to browser font-size setting
			font-style: normal;
			font-weight: 400;
			line-height: calc(1rem + 3px);
			letter-spacing: 0em;
			text-align: left;
			padding: 5px 8px 7px 8px;
		}
		//comment to force rebuild
		.decision-log__inner {
			padding: 0;
		}
		.decision-log__metadata {
			margin-top: -1px;
			margin-bottom: 7px;
			font-size: var(--small-font-size);
			font-style: normal;
			font-weight: 400;
			letter-spacing: 0.05em;
			text-align: right;
			opacity: 1;
		}
		.decision-log__metadata:last-child {
			margin-bottom: 0px;
		}
		.decision-log__auth-and-time {
			opacity: 0.6;
		}
		.decision-log__translate {
			color: var(--primary-button-colour) !important;
			cursor: pointer;
			&:hover {
				color: var(--primary-button-hover-colour) !important;
			}
		}
		.decision-log__delete {
			color: var(--primary-button-colour);
			cursor: pointer;
			&:hover {
				color: var(--primary-button-hover-colour);
			}
		}
	}
}

.participant-decision-log--nolist {
	height: auto;

	> h2:first-child {
		display: none;
	}
	.decision-log {
		display: none;
	}
}
