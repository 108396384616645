.login-button {
	text-align: center;
	margin: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: 400;
	font-size: 20px;
	min-width: 120px;

	cursor: pointer;
	* {
		cursor: pointer;
	}

	&__circle {
		border-radius: 50%;
		width: 80px;
		height: 80px;
		margin-bottom: 1.2rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		background-color: $login-button-background-color-1;
		transition: background-color 0.3s;

		.icon {
			color: $login-button-icon-color;
			height: 36px;
			width: 100%;
			font-size: 36px;
			text-align: center;
		}
		.fa-user-plus {
			transform: translateX(4px);
		}

		cursor: pointer;
	}

	&__text {
		// @include caps;
		font-size: 0.8em;
		font-family: var(--login-font-family);
		color: var(--login-text-colour);
	}

	&:focus {
		outline: none;
	}

	&:focus &__circle,
	&:hover &__circle,
	&:active &__circle {
		background-color: $login-button-hover-background-color;
		border-color: $login-button-hover-background-color;
		transition: background-color 0s;
		.icon {
			color: $login-button-hover-icon-color;
		}
	}
}
