.video-call-participant {
	position: relative;
	padding: 0.2rem 0.2rem;
	margin: 0;
	height: calc((var(--filmstrip-width) - 10px) / 1.5);

	&__inner {
		position: relative;
		// border-radius: 8px;
		border-radius: 5px;
		// border: solid 1px #404040;
		// border: solid 2px $mediumgrey;
		border-style: solid;
		border-width: 2px;
		border-color: var(--border-colour-1);
		// border-color: transparent;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		background-color: black;

		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		transition: box-shadow 100ms, border-color 300ms;
		height: 100%;
	}
	&.video-call-participant--noaudio {
		.video-call-participant__inner {
			// border: solid 1px #404040 !important;
			// border: solid 2px $mediumgrey !important;
			border-color: var(--border-colour-1) !important;
			box-shadow: none !important;
		}
	}

	&__titles {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		position: absolute;
		bottom: 2px;
		left: 0;
		right: 0;
	}

	video,
	.video-element__container {
		width: 100%;
		max-width: 100%;
		// height: $side-call-video-height;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	&__display-name,
	&__subheading,
	&__mute-message {
		width: 100%;
		max-width: 100%;

		text-align: center;
		font-weight: normal;
		font-size: 1rem;
		line-height: 1.4rem;

		padding-top: 2px;
		padding-left: 0.5rem;
		padding-right: 0.5rem;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__subheading,
	&__display-name {
		text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 4px black;
		color: white;
	}

	&__subheading {
		font-size: 0.85rem;
		color: #c4c4c4;
	}

	&__mute-message {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		color: white;
		background-color: $red;
		text-align: center;
		// padding: 0.1rem;
	}

	&:hover {
		.video-call-participant__inner {
			transition: box-shadow 700ms;
			border-color: var(--accent-colour-1) !important;
		}
		cursor: pointer;
		* {
			cursor: pointer;
		}
	}

	&__centre {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__error {
		color: white;
		text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 4px black;
		font-size: 1rem;
		text-align: center;
	}

	&__icons {
		display: flex;
		flex-direction: row;
		text-shadow: 0px 0px 2px black, 0px 0px 4px black;
		.icon {
			margin: 1rem;
			font-size: 2rem;
		}
	}

	&__dot {
		width: 13px;
		height: 13px;
		border-radius: 99px;
		display: inline-block;
		/* margin-right: 7px; */
		transform: translate(-8px, 2px);
	}

	&--novideo {
		video {
			display: none;
		}
	}
	&--dominant {
		.video-call-participant__inner {
			border: solid 2px var(--accent-colour-2);
		}
	}

	&--dead {
		display: none;
	}

	&--with-error {
		// video {
		// 	opacity: 0.4;
		// }
		// .video-call-participant__inner {
		// 	background-size: 0px;
		// }

		.video-call-participant__centre {
			background-color: rgba(0, 0, 0, 0.5);
		}
	}

	&--cameraoff {
		video {
			display: none;
		}
		.video-call-participant__inner {
			background-size: 0px;
		}
	}

	.preloader {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 10px;
	}

	// &--interrupted {
	// 	display: none;
	// }

	// &--inactive {
	// 	video {
	// 		display: none;
	// 	}
	// }
}

.video-call-participant.video-call-participant--fullscreen {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: auto;
	padding: 0;

	video {
		object-fit: contain !important;
	}

	.video-call-participant__inner {
		border: none !important;
		border-radius: 0;
		box-shadow: none;
	}

	.video-call-participant__titles {
		bottom: 78px;
	}
	&:hover {
		cursor: default;
		* {
			cursor: default;
		}
		.video-call-participant__statistics {
			display: none;
		}
	}
}

.video-call-participant.video-call-participant--fullscreen.video-call-participant--screenshare {
	video {
		object-fit: contain !important;
		width: calc(100% - (var(--filmstrip-width) + 17px));
	}
}

.video-call-participant__statistics {
	display: none;
}

.video-call-participant:hover {
	.video-call-participant__statistics {
		display: table;
		position: absolute;
		bottom: 2px;
		left: 0;
		right: 0;
		top: 0;
		width: 100%;
		color: white;
		text-shadow: 0px 0px 2px black, 0px 0px 4px black;
		background-color: rgba(0, 0, 0, 0.3);
		tr {
			td {
				padding: 1px 4px;
				width: 50%;
				color: white;
				font-size: 13px;
				label {
					color: white;
					font-family: $base-font-family;
					font-size: 12px;
					font-weight: 600;
					letter-spacing: 1px;
					text-transform: uppercase;
				}
			}
			td:first-child {
				text-align: right;
			}
			td[colSpan='2'] {
				text-align: center;
			}
		}
	}
}

.video-call__interpreter,
.video-call__filmstrip,
.side-call {
	.video-call-participant:hover {
		.video-call-participant__titles {
			// display: none;
		}
		.video-call-participant__icons {
			display: none;
		}
		.video-call-participant__error {
			display: none;
		}
		.video-call-participant__statistics {
			padding-top: 3px;

			tr {
				td {
					width: auto;
					padding: 0px 4px;
					font-size: 10.5px;
					line-height: 14px;
					label {
						font-size: 9px;
					}
				}
				td:first-child {
					text-align: left;
				}
			}
		}
	}
}
