.textarea {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: 4px;
	margin-bottom: 8px;

	textarea {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		resize: none;
	}

	.textarea__resizer,
	textarea {
		white-space: pre-wrap;
		overflow-wrap: break-word;
		line-height: normal;

		margin: 0;
		padding: 8px 10px;
	}

	.textarea__resizer {
		opacity: 0;
		border: solid 1px transparent;
	}
}

.textarea-with-label {
	position: relative;

	label {
		position: absolute;
		top: 1px;
		left: 0;
		z-index: 1;
		height: calc(var(--base-font-size) + 16px); // 8px = textarea vert padding
		line-height: calc(var(--base-font-size) + 16px);
		padding-left: 10px;
		padding-right: 10px;
		// margin-top: 6px;
		// line-height: 14px;
		// height: 14px + 6px;
		// padding: 0 1rem;
		text-transform: none;
		transition: top 0.1s $default-easing, padding 0.1s $default-easing;

		border-radius: 99px;
		background: none;
		font-family: var(--base-font-family);
		font-weight: normal !important;
		font-size: var(--base-font-size);
		letter-spacing: normal;
	}

	&.textarea-with-label--focus {
		label {
			top: calc(-1 * (14px + 20px));
			// padding: 3px 0.7rem;
			background: white;
		}
	}
}
