.video-call {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// background-color: #1a1a1a;
	background-color: $lightgrey;

	&__container {
		height: 0;
		width: 100%;
		flex-grow: 1;
		display: flex;
		flex-direction: row;
		position: relative;
	}

	&__main {
		position: relative;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		width: 0;
		overflow: hidden;
	}

	&__participants {
		height: 1px;
		flex-grow: 1;
		overflow-y: auto;
	}

	&__tileview {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex-grow: 1;
		overflow: hidden;
		width: auto;
		justify-content: center;
		align-items: center;
		margin-left: 7px;
		margin-right: 7px;
	}

	&__tileview-inner {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	&__filmstrip {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;

		display: flex;
		flex-direction: column;
		align-items: flex-end;

		background-color: rgba(0, 0, 0, 0.5);
		background: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);

		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 15px;
		padding-right: 10px;

		.video-call-participant {
			width: var(--filmstrip-width);
			max-width: var(--filmstrip-width);
		}

		.video-call-participant__display-name,
		.video-call-participant__subheading,
		.video-call-participant__mute-message {
			line-height: 1rem;
		}
	}

	.video-call__mute-message {
		text-align: center;
		background-color: $red;
		line-height: 1.2rem;
		font-size: 1.2rem;
		color: white;
		padding: 0.5rem;
		padding-top: 0.7rem;
	}

	&__interpreter {
		label {
		}
	}

	&__interpreter {
		width: 240px;

		position: absolute;
		left: 10px;
		bottom: 12px;

		z-index: 1;

		// color: white;
		// text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 4px black;
		background-color: rgba(255, 255, 255, 0.7);
		border: solid 1px $grey;
		padding: 0.4rem;
		border-radius: $section-border-radius;

		display: flex;
		flex-direction: column;

		.video-call__interpreter-mute {
			display: flex;
			margin-top: 0.4rem;
			margin-bottom: 0.4rem;

			label {
				font-size: 0.9rem;
				// font-weight: normal;
				line-height: 1.6rem;
				padding-left: 4px;
				flex-grow: 1;
				text-transform: none;
				// color: white;
			}
		}

		.video-call-participant__display-name,
		.video-call-participant__subheading,
		.video-call-participant__mute-message {
			line-height: 1rem;
		}

		.icon-button.icon-button {
			margin-left: auto;
			text-shadow: none;
			height: 29px;
			width: 29px;
			max-width: 29px;
			min-width: 29px;
			font-size: 18px;

			position: absolute;
			z-index: 1;
			right: 15px;
			top: 12px;
			border-width: 1px;
		}

		.icon-button:not(.icon-button--selected, :hover, :active) {
			background-color: #f3f4f7;
			border: solid 1px $darkgrey;
		}
	}

	&.video-call--speaker {
		.video-call__buttons {
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
		}
	}

	&__interpreter-controls {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// color: white;
		margin: 0.5rem 0rem;
		padding: 0.8rem 1.2rem;
		border: solid 1px $grey;
		background-color: rgba(255, 255, 255, 0.7);
		border-radius: $section-border-radius;

		.button {
			margin: 0.5rem;
			button {
				padding-left: 8px;
				padding-right: 8px;
				width: 160px !important;
				max-width: 160px !important;
			}
		}

		.button--secondary {
			button {
				// background: transparent;
				// border: solid white 1px;
			}
		}
	}

	.smalltext {
		font-size: $small-font-size;
	}

	&__buttons {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 10px;
		padding-right: 10px;

		.left {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			width: 33%;
			min-height: 100%;
			button {
				width: auto;
			}
		}

		.middle {
			width: 34%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;
			min-height: 100%;
		}

		.right {
			width: 33%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: flex-end;
		}

		.icon-button:not(.icon-button--selected):not(:hover):not(:active) {
			// border: 1px solid $darkgrey;
		}
	}
}

.video-call--agora {
	&.video-call--speaker {
		// .video-call__main {
		// 	width: calc(100% - (var(--filmstrip-width) + 35px));
		// 	flex-grow: 0;
		// }
		.video-call-participant.video-call-participant--fullscreen {
			right: calc(var(--filmstrip-width) + 35px);
		}
	}
	.video-call__filmstrip {
		overflow-y: scroll;
		background-color: black;
		min-width: calc(var(--filmstrip-width) + 35px);

		.video-call__participants {
			overflow-y: visible;
		}
	}
	.video-call-participant--noaudio .video-call-participant__inner,
	.video-call-participant__inner {
		// border-color: rgba(255, 255, 255, 0.3) !important;
	}
}

.video-call__error {
	margin: auto;
	text-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
}
