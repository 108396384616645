// =============================================================================
// Style > Components > Preloader
// =============================================================================

.preloader-dual-ring {
	display: inline-block;
	margin: auto;
	width: 64px;
	height: 64px;
	position: relative;

	&__ring1,
	&__ring2 {
		position: absolute;
		border-radius: 50%;
		border: 5px solid;
		animation: lds-dual-ring 1000ms linear infinite;
	}

	&__ring1 {
		top: 6px;
		left: 6px;
		width: 52px;
		height: 52px;
		border-color: var(--accent-colour-1) transparent var(--accent-colour-1) transparent;
	}
	&__ring2 {
		top: 16px;
		left: 16px;
		width: 32px;
		height: 32px;
		border-color: var(--accent-colour-2) transparent var(--accent-colour-2) transparent;
		animation-direction: reverse;
	}

	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
