$bubble-heading-border-color: var(--heading-border-colour);
$bubble-heading-background-color: var(--heading-colour);
$bubble-heading-text-color: var(--heading-text-colour);
$bubble-heading-padding: 0rem 3.5rem;
$bubble-heading-height: 42px;

.bubble-heading {
	display: inline-block;

	height: $bubble-heading-height;
	line-height: $bubble-heading-height - 2px;

	margin: 0;
	font-size: var(--heading-font-size);
	color: var(--heading-text-colour);
	font-weight: $heading-font-weight;
	text-align: center;

	max-width: 100%;

	@include ellipsisTruncate;
	overflow: visible;
	transform: translateY(-3px);
	margin-bottom: 3px;

	&::after {
		content: '';
		height: 3px;
		background-color: var(--heading-border-colour);
		width: 80%;
		display: block;
		margin: 0 auto;
		transform: translateY(0px);
	}

	font-family: var(--heading-font-family);
	font-weight: 700;
	margin-bottom: 0px;
	line-height: 1.1rem;
	transform: translateY(0px);
	height: auto;

	&::after {
		display: none;
	}
}

// .bubble-heading,
// .modal__title,
// h2 {
// 	font-family: var(--heading-font-family);
// 	text-transform: uppercase;
// 	font-size: 17px;
// 	line-height: 20px;
// 	font-weight: 700;
// 	letter-spacing: 0px;
// 	&::after {
// 		display: none;
// 	}
// }
