@font-face {
	font-family: Lato;
	src: url('fonts/lato/Lato-Thin.woff2') format('woff2'), url('fonts/lato/Lato-Thin.woff') format('woff');
	font-style: normal;
	font-weight: 200;
	text-rendering: optimizeLegibility;
}
@font-face {
	font-family: Lato;
	src: url('fonts/lato/Lato-Regular.woff2') format('woff2'), url('fonts/lato/Lato-Regular.woff') format('woff');
	font-style: normal;
	font-weight: 400;
	text-rendering: optimizeLegibility;
}
@font-face {
	font-family: Lato;
	src: url('fonts/lato/Lato-Bold.woff2') format('woff2'), url('fonts/lato/Lato-Bold.woff') format('woff');
	font-style: normal;
	font-weight: 700;
	text-rendering: optimizeLegibility;
}
