.audio-player-screen,
.audio-player,
.video-player {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	color: white;

	audio {
		display: none;
	}

	&__content {
		max-width: 100%;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.preloader {
			position: absolute;
			left: 0;
			right: 0;
			top: 40%;
			width: 64px;
		}

		> * {
			width: 100%;
			margin-bottom: 1rem;
			text-align: center;
		}

		h3 {
			// margin-bottom: 4px;
			text-align: center !important;
			margin-bottom: 0px;
			margin-top: 14px;
			color: white !important;
		}

		p {
			margin-top: 0;
			margin-bottom: 2rem;
		}

		.icon-button {
			height: 64px;
			width: 64px;

			background-color: var(--primary-button-colour);
			color: var(--primary-button-text-colour);

			&:hover {
				background-color: var(--primary-button-hover-colour);
			}

			.icon {
				font-size: 2rem;
			}
		}
	}

	&__clock {
		font-weight: 300;
		font-size: 1.8rem;
		margin: 2rem 0 3rem 0;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 64px;
	}

	h2,
	h3 {
		color: white;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
		padding-left: 4px;
		padding-right: 4px;
	}

	.icon-button {
		span {
			color: white;
		}
	}
}
.audio-player-screen {
	.audio-visualiser {
		background-color: #282c44;
	}
}

.audio-player__button-row {
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
}
