.media-openings-modal {
	&__row {
		@include simpleRow();
		background-color: var(--red);
		color: var(--white);
		border: none;
		.icon {
			color: var(--white);
			width: 1rem;
			margin-left: 1rem;
		}
	}
	&__row--opened {
		background-color: $button-disabled-background;
		color: $button-disabled-color;
		.icon {
			color: $button-disabled-color;
		}
	}
	&__name {
		flex-grow: 1;
		font-weight: bold;
	}
	.icon {
		flex-grow: 0;
		flex-shrink: 0;
	}
}
