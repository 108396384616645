.team-badge {
	position: absolute;
	top: -11px;
	right: 0;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;

	width: auto;

	&__circle {
		border-radius: 99px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		// margin-left: 0.7rem;
		margin: 0 0.4rem 0 0.7rem;
		height: 28px;
		width: 28px;

		transition: background-color 500ms ease-out;

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__label {
		font-family: $heading-font-family;
		// font-weight: bold;
		// text-transform: uppercase;
		// letter-spacing: 1px;
		font-size: 15px;

		font-family: $heading-font-family;
		// font-size: $primary-heading-size;
		font-weight: $heading-font-weight;
		color: var(--heading-text-colour);
	}
}
