.icon-test {
	text-align: center;

	.icon {
		width: 64px;
		height: 64px;
		font-size: 32px;
		margin: 1rem;
		border-radius: 10px;
	}
}
