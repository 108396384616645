// $easing: linear;
// $easing: ease-out;
// $easing: cubic-bezier(0.215, 0.61, 0.355, 1); // ease-out-cubic
// $easing: cubic-bezier(0.075, 0.82, 0.165, 1); // ease-out-circular
$easing: cubic-bezier(0.19, 1, 0.22, 1); // ease-out-exponential
$anim-duration: 2s;

// .participant-page {
// 	display: flex;
// 	flex-direction: column;

// 	&__header {
// 		width: 100%;
// 		z-index: 4;
// 	}

// 	&__sidecall {
// 		display: flex;
//     flex-direction: row;
//     position: fixed;
//     padding: 20px;
//     top: 0;
// 		right: 0;
//     width: 220px;
// 		height: 100vh;
//     justify-content: center;

// 		.side-call {
// 			height: 100vh;
// 			padding-bottom: 20px;
// 		}
// 	}
// }

.participant-view {
	display: flex;
	flex-direction: column;
	font-family: 'Open Sans', sans-serif;
	width: 100vw;
	height: 100vh;
	overflow: hidden;

	h1 {
		font-family: var(--heading-font-family);
		// font-size: 21px;
		line-height: 30px;
		font-weight: 700;
		letter-spacing: 0px;
		text-align: left;

		text-transform: uppercase;
		color: var(--base-text-colour);
	}

	.bubble-heading,
	.modal__title,
	h2 {
		font-family: var(--heading-font-family);
		text-transform: uppercase;
		font-size: var(--primary-heading-size);
		line-height: 20px;
		font-weight: 700;
		letter-spacing: 0px;
		&::after {
			display: none;
		}
	}

	h3 {
		font-family: 'Open Sans', sans-serif;
		// overflow: visible;
		font-size: relativePixels(14);
		font-style: normal;
		font-weight: 700;
		line-height: 19px;
		letter-spacing: 0.05em;
		text-align: left;

		text-transform: uppercase;
		color: var(--base-text-colour);
	}

	.participant-header-bar {
		z-index: 2;
		width: 100%;
	}

	> .row {
		margin-top: 12px;
		height: 1px;
		flex-grow: 1;
	}

	.video-call {
		position: static;
		flex-grow: 1;
		width: 100%;
		height: 100%;
	}

	.virtual-phone__container {
		width: 35%;
		min-width: 390px;
		// z-index: 1;
		transition: width $anim-duration $easing;
		flex-shrink: 0;
	}

	.dark-mode-switch {
		width: 35%;
		transition: width $anim-duration $easing;
		position: absolute;
		left: 0;
		bottom: 10px;
		// z-index: 2;
	}

	&__middle {
		position: relative;
		width: 60%;
		min-width: 700px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: width $anim-duration $easing, min-width $anim-duration $easing, opacity $anim-duration $easing;
		flex-shrink: 0;
		opacity: 1;
		margin: 40px 0;

		h2 {
			margin-top: 8px;
			margin-bottom: 5px;
		}
	}

	.participant-decision-log {
		width: 100%;
		z-index: 2;
	}

	.media-viewer {
		width: 100%;
		height: 100%;
	}

	.participant-header-bar,
	.modal,
	.initial-information-modal,
	&__middle {
		h2 {
			color: var(--accent-colour-2);
		}
		h3 {
			color: #333333;
			font-family: $heading-font-family;
		}
		label {
			font-family: $heading-font-family;
			font-weight: 700;
		}
		button {
			font-family: $heading-font-family;
			// line-height: 15px;
			font-weight: 700;
		}
	}

	.tab {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.tab--inactive {
		transform: none;
	}
	.tab--inactive:not(:hover) {
		background-color: var(--panel-background-colour);
		box-shadow: inset 0px -18px 18px -25px rgba(0, 0, 0, 0.2);
	}
}

.participant-view:not(.participant-view--insession) {

	.participant-header-bar {
		z-index: 2;
		width: 100%;
	}

	.participant-view__middle {
		// display: flex;
		// width: 0%;
		// min-width: 0px;
		// transform: translateX(0px);
		opacity: 0;
	}
	/* We need this so that focus doesn't get set to any of the add-decision-log fields before the 
	   decision log is displayed */
	.add-decision-log {
		display: none;
	}
	.virtual-phone__container {
		width: 100%;
	}

	.dark-mode-switch {
		width: 100%;
	}
}

$mediaTransitionDuration: 0.8s;

.participant-view {
	.participant-decision-log__top {
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		> * {
			transition: transform $mediaTransitionDuration $easing, opacity $mediaTransitionDuration $easing;
			transform: translateX(0px);
			opacity: 1;
		}
	}
	.participant-view__media-container {
		height: 0%;
		margin-bottom: 0px;
		.media-viewer {
			height: 100%;
			transition: transform $mediaTransitionDuration $easing, opacity $mediaTransitionDuration $easing;
			transform: translateX(-100px);
			opacity: 0;
		}
	}
}

.participant-view--mediaopen {
	.participant-view__media-container {
		height: 100%;
		margin-bottom: 12px;
		.media-viewer {
			transform: translateX(0px);
			opacity: 1;
		}
	}
	.participant-decision-log {
		height: auto;
		flex-shrink: 0;
	}
	.participant-decision-log__top {
		height: 0px;
		> * {
			transform: translateX(100px);
			opacity: 0;
		}
	}
}

.participant-view--mediaopen:not(.participant-view--sessionactive),
.participant-view--mediaopen.participant-view--expanded {
	.participant-header-bar {
		z-index: 2;
		width: 100%;
		position: relative;
	}

	.participant-view__middle {
		.tab__row,
		.tabs-container,
		.participant-decision-log {
			display: none;
		}
	}

	.media-viewer {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.participant-view__media-container {
		margin-bottom: 0;
	}
}

.participant-view:not(.participant-view--mediaopen) {
	.participant-view__media-container {
		.media-viewer {
			input,
			select,
			textarea,
			button {
				display: none;
			}
		}
	}
}

.participant-view {
	background-color: var(--white);
	transition: background-color 1s $ease-out-exponential;
}
.dark-theme {
	.participant-view {
		background-color: var(--dark-mode-background-colour);
	}
}
