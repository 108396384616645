$easing: cubic-bezier(0.19, 1, 0.22, 1); // ease-out-exponential

@keyframes slideInFromBelow {
	0% {
		transform: translateY(20%);
		opacity: 0;
	}
	30% {
		transform: translateY(20%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.virtual-phone {
	animation: 1.5s $easing 0s 1 slideInFromBelow;
	position: relative;
	width: 352px;
	height: 704px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.home-screen {
		p {
			color: $darkgrey;
			opacity: 0.8;
		}
	}

	// filter: drop-shadow(9px 10px 15px rgba(0, 0, 0, 0.2)) drop-shadow(1px 3px 3px rgba(0, 0, 0, 0.3));
	filter: drop-shadow(9px 10px 15px rgba(0, 0, 0, 0.3)) drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.4));

	.virtual-phone-background {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		path {
			fill: var(--virtual-phone__bg);
		}
	}

	.virtual-phone__bg1,
	.virtual-phone__bg2 {
		position: absolute;
		height: 100%;
		width: 100%;
		border-radius: 20px;
	}

	.virtual-phone__bg1 {
		background-color: $white;
	}

	.virtual-phone__bg2 {
		position: absolute;
		width: 237%;
		height: 110%;
		top: -5%;
		background: linear-gradient(15.4deg, #120084 8%, #09003e 38%, #1c0048 57%, #5c0065 78%, #b3008b 96%);
		transition: all 3s;
		// animation: spin 15s linear infinite;
		animation-name: spin;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-play-state: paused;
		animation-duration: 15s;
		opacity: 0;
	}

	.messages-app {
		transition: transform 0.6s $easing;
		transform: translateY(100%);
		&__overlay {
			transition: opacity 0.6s $easing;
			opacity: 0;
		}
	}

	&--messagesopen {
		.messages-app {
			transform: translateY(0%);
			&__overlay {
				opacity: 1;
			}
		}
	}

	.virtual-phone-apps {
		// position: absolute;
		// bottom: 0;
		transition: transform 0.6s $easing;
		transform: translateY(0%);
	}

	.start-screen,
	.welcome-screen,
	.home-screen,
	.audio-player-screen,
	.video-player,
	.update-screen,
	.voice-message-screen {
		transition: transform 0.6s $easing;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.virtual-phone__bg2 {
		opacity: 0;
	}
	.home-screen {
		top: 108px;
	}
	.welcome-screen {
		transform: translateX(100%);
	}
	.start-screen {
		transform: translateX(100%);
	}
	.audio-player-screen {
		transform: translateX(100%);
	}
	.video-player {
		transform: translateX(100%);
	}
	.update-screen {
		transform: translateX(100%);
	}
	.home-screen {
		transform: translateX(-100%);
	}
	.voice-message-screen {
		transform: translateY(100%);
	}

	.virtual-phone-clock {
		transform: translateY(0px);
	}

	&--welcome {
		.virtual-phone-clock {
			transition-delay: 0s;
			transform: translateY(-100px);
		}
		.welcome-screen {
			transform: translateX(0%);
		}
		.virtual-phone__bg2 {
			opacity: 1;
			animation-play-state: running;
		}
	}

	&--home {
		.home-screen {
			transform: translateX(0%);
		}
	}

	&--start {
		.start-screen {
			transform: translateX(0%);
		}
		.virtual-phone__bg2 {
			opacity: 0;
			animation-play-state: running;
		}
		.virtual-phone-apps {
			transform: translateY(100%);
		}
	}

	&--audioplayer {
		.virtual-phone-clock {
			transition-delay: 0s;
			transform: translateX(-100%);
		}
		.audio-player-screen {
			transform: translateX(0%);
		}
		// .virtual-phone-apps {
		// 	transform: translateX(-100%);
		// }
	}

	&--videoplayer {
		.virtual-phone-clock {
			transition-delay: 0s;
			transform: translateX(-100%);
		}
		.video-player {
			transform: translateX(0%);
		}
		// .virtual-phone-apps {
		// 	transform: translateX(-100%);
		// }
	}

	&--update {
		.virtual-phone-clock {
			transition-delay: 0s;
			transform: translateX(-100%);
		}
		.update-screen {
			transform: translateX(0%);
		}
		// .virtual-phone-apps {
		// 	transform: translateX(-100%);
		// }
	}

	&--voicemessage {
		// .virtual-phone-clock {
		// 	transition-delay: 0s;
		// 	transform: translateX(-100%);
		// }
		.home-screen {
			transform: translateX(0%);
		}
		.voice-message-screen {
			transform: translateY(0%);
		}
	}

	.virtual-phone-frame {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		pointer-events: none;
	}

	.virtual-phone__content {
		// po
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: stretch;
		width: calc(100% - 39px);
		overflow: hidden;
		height: calc(100% - 28px);
		// padding: 7px 8px 5px 8px;
		padding: 0;
		border-radius: 30px;
		margin: 14px 19px;
		background-color: var(--virtual-phone__bg);
		// * {
		// 	z-index: 1;
		// }
	}

	.virtual-phone-clock {
		margin-top: 4px;
		transition: transform 0.6s $easing;
		// transition-delay: 0.3s;
	}

	.notifications {
		margin-top: 15px;
		width: 100%;
		height: 0px;
		flex-grow: 1;
		overflow: auto;
		padding-left: 8px;
		padding-right: 4px;
		margin-right: 4px;
		p {
			padding: 0 15px;
			text-align: center;
		}
	}

	.preloader-dual-ring__ring1 {
		border-color: var(--white) transparent var(--white) transparent;
	}

	*::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: 6px;
	}
	*::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		background-color: transparent;
		border-radius: 6px;
	}
	*::-webkit-scrollbar-thumb {
		background-color: var(--accent-colour-2);
		border: 1px solid var(--accent-colour-2);
		border-radius: 6px;
	}
}

.virtual-phone__container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (max-height: 850px) {
	.virtual-phone {
		zoom: 0.9;
	}
}
@media screen and (max-height: 800px) {
	.virtual-phone {
		zoom: 0.85;
	}
}
@media screen and (max-height: 720px) {
	.virtual-phone {
		zoom: 0.8;
	}
}
@media screen and (max-height: 670px) {
	.virtual-phone {
		zoom: 0.7;
	}
}
@media screen and (max-height: 600px) {
	.virtual-phone {
		zoom: 0.6;
	}
}

@media screen and (min-width: 1670px) and (min-height: 1150px) {
	.virtual-phone {
		zoom: 1.3;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.virtual-phone-frame {
	.virtual-phone-frame__bezel {
		transition: fill 1s $ease-out-exponential;
	}
	.virtual-phone-frame__buttons {
		transition: stroke 1s $ease-out-exponential;
	}
}

.dark-theme {
	.virtual-phone {
		filter: none;
	}
	.virtual-phone-frame {
		.virtual-phone-frame__bezel {
			fill: #0f0f0f;
		}
		.virtual-phone-frame__buttons {
			stroke: #050505;
		}
	}
}

.app-header {
	color: white;
	h2 {
		color: white;
	}
}
