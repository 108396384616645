:root {
	--team-colour-violet: #be74da;
	--team-colour-pink: #f88faf;
	--team-colour-magenta: #ec008c;
	--team-colour-maroon: #7d0000;
	--team-colour-crimson: #950000;
	--team-colour-ruby: #b30027;
	--team-colour-coral: #f87c4e;
	--team-colour-orange: #f87b00;
	--team-colour-amber: #f8bd00;
	--team-colour-gold: #ceaa35;
	--team-colour-olive: #53682e;
	--team-colour-green: #267a26;
	--team-colour-teal: #007d7d;
	--team-colour-cyan: #08c3d9;
	--team-colour-cobalt: #315587;
	--team-colour-blue: #0057ae;
	--team-colour-navy: #001f70;
	--team-colour-indigo: #311277;
	--team-colour-purple: #3a125e;
	--team-colour-charcoal: #333333;
	--team-colour-grey: #777777;
	--team-colour-silver: #cccccc;
}
