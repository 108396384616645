@use 'sass:math';

$button-border-radius: 100px;
$input-border-radius: 6px;
$section-border-radius: 10px;

$vertical-spacing: 1.4rem;

$ease-out-exponential: cubic-bezier(0.19, 1, 0.22, 1);
$default-easing: $ease-out-exponential;

// -------------------------------------------------------------------------------------------------
// Colours
$darkred: #880000; // -------------- Alternate colour when a notification is flashing
$green: #23a12f; // ---------------- VirtualPhone call/play button
$red: #b91818;
$lightred: #c91717; // ------------- Notification hover colour

$off-black: #252525; // ------------ base text color
$darkgrey: #707070; // ------------- text in info sections - contrast ratio of 4.66:1 on $lightlightgrey (#F8F8F8) background
$grey: #c9c5c8; // ----------------- disabled field border
$mediumgrey: #d9d9dd; // ----------- borders, input field borders

$vpBg: #c6c6c6; // ----------------- VirtualPhone background

$phoneColour: #3C4652; // ---------- VirtualPhone 


$disabledgrey: #efefef; // --------- disabled input fields

$lightgrey: #f1f2f4; // ------------ general background colour for facilitator screens
$lightlightgrey: #f8f8f8; // ------- parent containers
$white: white;

$view360cyan: #08c3d9;
$view360magenta: #ff0099;
$view360purple: #1b1464;

$darkmagenta: #cf167f;

// Colours as CSS variables
:root {
	--red: #{$red};
	--green: #{$green};
	--darkred: #{$darkred};
	--lightred: #{$lightred};
	--red: #{$red};
	--off-black: #{$off-black};
	--darkgrey: #{$darkgrey};
	--grey: #{$grey};
	--mediumgrey: #{$mediumgrey};
	--disabledgrey: #{$disabledgrey};
	--lightgrey: #{$lightgrey};
	--lightlightgrey: #{$lightlightgrey};
	--white: #{$white};
	--view360cyan: #{$view360cyan};
	--view360magenta: #{$view360magenta};
	--view360purple: #{$view360purple};
	--darkmagenta: #{$darkmagenta};
}

:root {
	--base-text-colour: #{$off-black};
	--accent-colour-1: #{$view360purple};
	--accent-colour-2: #{$view360magenta};

	--button-colour: #{$view360purple};
	--button-hover-colour: #{lighten($view360purple, 10%)};
	--button-text-colour: white;

	--button-2-colour: #{$view360magenta};
	--button-2-hover-colour: #{lighten($view360magenta, 5%)};
	--button-2-text-colour: white;

	// --primary-button-colour: var(--accent-colour-2);
	--primary-button-colour: var(--darkmagenta);
	--primary-button-hover-colour: var(--button-2-hover-colour);
	--primary-button-text-colour: white;

	--header-colour: #{$view360purple}; // main brand colour
	--header-text-colour: white;
	--header-border-colour: #{$view360magenta};

	--heading-colour: #{$view360purple};
	--heading-border-colour: #{$view360magenta};
	--heading-text-colour: #{$view360purple};

	--background-colour-1: white;
	--background-colour-2: #f3f4f7;

	--panel-background-colour: var(--lightlightgrey);

	--border-colour-1: #e6e6e6;
	--border-colour-2: #e6e6e6;

	--disabled-border-colour: #{$disabledgrey};

	--sidebar-colour: #{darken($view360purple, 5%)};

	--login-background-colour: linear-gradient(225deg, #{$view360magenta} 21%, #{$view360purple} 50%);
	--login-text-colour: black;

	--login-logo-width: 300px;

	--login-button-hover-colour: #{$view360magenta};
	--login-button-hover-icon-colour: white;
	--login-button-colour: #{$view360purple};
	--login-button-icon-colour: white;

	--logo-1: url('/src/web/img/view360-2021-icon-and-text.png');
	--logo-2: url('/src/web/img/view-360-text-2021-white-bg-600.png'); // Login page - top right
	--logo-3: url('/src/web/img/cognitas_logo_2021_3_90.png');
	--logo-4: url('/src/web/img/v360g-icon-light-padded.png'); // Facilitator screen - top left
	--logo-5: url('/src/web/img/v360g-icon-dark.png'); // Participant screen - top left
	--logo-6: url('/src/web/img/v360g-icon-light.png'); // Participant screen - top left - dark mode

	--logo-4-width: 60px;
	--logo-5-width: 46px;
	--logo-5-margin: 10px;

	--scrollbar-background-colour: #d9d9dd;

	--virtual-phone__bg: #1b1f38;
	--dark-mode-background-colour: #191529;
	--input-background-colour: white;
	--input-text-colour: var(--base-text-colour);
	--placeholder-colour: #bbbbbb;

	// --filmstrip-width: 230px;
	--filmstrip-width: 210px;
}

.dark-theme {
	--panel-background-colour: #302d3f;
	--border-colour-1: transparent;
	--input-background-colour: #191529;
	--input-text-colour: #ffffff;
	--base-text-colour: #dadada;
	--scrollbar-background-colour: black;
	--placeholder-colour: #636363;
}

// -------------------------------------------------------------------------------------------------

$base-text-color: var(--base-text-colour);

$paragraph-text-color: $darkgrey;

$login-background-color: var(--login-background-colour);
$login-background-color-top: $white;
$sidebar-background-color: var(--sidebar-colour);
$modal-overlay-background-color: rgba(0, 0, 0, 0.6);

$login-text-color: --login-text-colour;

$login-button-background-color-1: var(--login-button-colour); // idle
$login-button-background-color-2: var(--login-text-colour); // hover
$login-button-background-color-3: $white; // mousedown
$login-button-icon-color: var(--login-button-icon-colour);
$login-button-hover-background-color: var(--login-button-hover-colour);
$login-button-hover-icon-color: var(--login-button-hover-icon-colour);

$header-icon-color: var(--base-text-colour);

$sidebar-button-background-color: transparentize($white, 0.9);
$sidebar-button-hover-background-color: var(--accent-colour-1);
$sidebar-button-selected-color: var(--accent-colour-1);

$config-panel-background-colour: transparent;
$button-disabled-color: $darkgrey;
$button-disabled-background: $disabledgrey;

$header-text-color: $white;
$modal-background-color: $white;
$modal-border-color: $darkgrey;

$border-color: var(--border-colour-1);

$input-border-color: var(--border-colour-1);
$input-disabled-border-colour: var(--disabled-border-colour);
$input-disabled-background: $disabledgrey;
$error-input-border-color: $red;
$active-input-border-color: var(--accent-colour-2);
$hover-input-border-color: var(--accent-colour-1);

$list-item-background-color: transparent;
$list-item-selected-background-color: $white;

$selected-item-background-color: var(--accent-colour-2);
$selected-item-text-color: $white;

$button-primary-background: var(--button-colour);
$button-primary-color: $white;

$button-secondary-color: $white;
$button-secondary-background: $darkgrey; // Only on login screen

$alert-background-color: $red;
$alert-text-color: $white;
