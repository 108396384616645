.start-screen {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	padding: 0 20px;

	p {
		color: rgba(255, 255, 255, 0.9);
	}

	/* Needs higher specificity than just &__titles {} */
	.start-screen__titles {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: stretch;
		margin-top: 20%;

		h2 {
			text-align: center;
			color: var(--view360magenta);
			margin-bottom: 4px;
			font-size: 26px;
			line-height: 26px;
		}

		h3 {
			text-align: center;
			color: $darkgrey;
			font-size: 19px;
			margin-bottom: 60px;
		}

		p {
			text-transform: uppercase;
			letter-spacing: 1px;
			color: $darkgrey;
		}
	}
	&__content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
