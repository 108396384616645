.participant-header-bar {
	display: flex;
	width: 100%;
	height: 68px;

	margin-top: -4px;

	background-color: var(--panel-background-colour);
	transition: background-color 1s $ease-out-exponential;

	* {
		white-space: nowrap;
		overflow: hidden;
	}

	&__logo {
		background-image: var(--logo-5);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;

		height: 100%;
		width: var(--logo-5-width);
		margin-left: var(--logo-5-margin);
		margin-right: 10px;
		flex-shrink: 0;
	}

	&__titles {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	h1 {
		margin-top: 9px;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 1px;
		text-transform: capitalize !important;
	}

	h2 {
		margin: 0;
		font-family: var(--heading-font-family);
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		margin-right: 12px;
		text-transform: none !important;
	}

	&__team-name {
		display: flex;
		align-items: center;
		color: var(--darkgrey);
		font-family: var(--heading-font-family);
		font-weight: 600;
		text-align: left;
		text-transform: none !important;
		line-height: 20px;

		.group-colour-dot {
			transform: translateY(-1px);
			width: 17px;
			height: 17px;
			margin-right: 8px;
			.icon {
				display: none;
			}
		}
	}

	&__translate {
		line-height: 2rem;
		font-size: var(--small-font-size);
		opacity: 0.6;
		margin-top: 9px;
		margin-left: 16px;
	}
	&__translate:hover {
		opacity: 1;
	}

	.button {
		transform: translateY(1px);
	}
}

.dark-theme {
	.participant-header-bar {
		background-color: var(--dark-mode-background-colour);
		.participant-header-bar__logo {
			background-image: var(--logo-6);
		}
		h1 {
			color: #fefefe;
			opacity: 0.9;
		}
		.participant-header-bar__team-name {
			color: #6c6a73;
		}
		.group-colour-dot {
			// border: solid 1px rgba(255, 255, 255, 0.5);
		}
	}
}
