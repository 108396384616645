.status-bar-message {
	text-align: center;
	height: 29px;
	line-height: 29px;
	font-weight: bold;
	background-color: var(--primary-button-colour);
	color: $white;
	padding: 0px;
	margin: 0;

	letter-spacing: 1px;
	text-transform: uppercase;

	@include ellipsisTruncate;
}
