// Custom scrollbar size/colours
*::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: #f5f5f5;
	border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--header-colour);
	border: 1px solid var(--header-colour);
	border-radius: 5px;
}

*::-webkit-scrollbar-track {
	background-color: var(--scrollbar-background-colour);
	border-radius: 5px;
}

* {
	scrollbar-color: var(--header-colour) var(--scrollbar-background-colour);
}
