.participant,
.password {
	@include configPanelCard();

	padding: 0px 16px;

	display: flex;
	align-items: center;

	background-color: $lightlightgrey;
	border: solid 1px white;

	user-select: text;

	min-height: 54px;

	* {
		user-select: text;
	}

	.left,
	.middle {
		margin-right: 1rem;
	}

	.left {
		min-width: 8rem;
		flex-grow: 0;
		flex-shrink: 0;

		h2 {
			letter-spacing: 1px;
			font-variant-numeric: tabular-nums;
			font-size: var(--secondary-font-size);
			font-family: var(--base-font-family);
		}
	}

	.middle {
		flex-grow: 1;

		.input.row {
			margin: 0.2rem 0;
			min-height: 26px;
			input[type='checkbox'] {
				margin-right: 1rem;
			}
			input[type='text'] {
				width: 1px;
				flex-grow: 1;
				min-width: 8rem;
			}
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		text-align: right;
		opacity: 0.6;
		flex-grow: 1;
		overflow: hidden;

		> div {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&--allocated {
		background-color: $white;
		border: solid 1px $border-color;
	}

	.icon-button {
		margin-left: 10px;
		margin-right: -8px;
		flex-shrink: 0;
	}

	.video-call-participant__dot {
		transform: none;
		height: 12px;
		width: 12px;
		margin-left: 5px;
	}
}

.participant {
	background-color: $white;
}
