.tutor-settings {
	@include tutorConfigPanel();

	.section {
		> .row {
			align-items: center;
			justify-content: space-between;
		}
		h2 {
			margin: 0;
			line-height: 2rem;
		}
	}

	.textarea {
		width: 100%;
		min-height: 4rem;
		margin-top: 1.4rem;
	}

	.warningSection {
		.input {
			margin: 0;
			flex-grow: 1;
			margin-bottom: 8px;
		}
		.textarea {
			margin-top: 0rem;
		}
		label {
			padding-right: 0.5rem;
			flex-grow: 1;
			text-align: right;
			margin-bottom: 0;
		}
	}

	.label-paragraph-switch-block {
		padding-top: 1rem;
		padding-bottom: 0.4rem;
		p {
			margin-top: 5px;
			margin-bottom: 0px;
		}
		label {
		}
	}
}
