.configure-video-modal {
	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.input {
			width: 100%;
		}
		label {
			margin-bottom: 4px;
			margin-left: 4px;
		}
	}
}
