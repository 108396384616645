$button-size: 60px;

.sidebar {
	display: flex;
	flex-direction: column;
	background-color: $sidebar-background-color;

	&__top,
	&__bottom {
		display: flex;
		flex-direction: column;
	}

	&__top {
		flex-grow: 1;
	}

	&__label {
		cursor: pointer;
		display: none;
		background-color: white;
		position: absolute;
		top: 0px;
		height: $button-size;
		align-items: center;
		padding-top: 5px;
		padding-left: 20px;
		padding-right: 20px;
		white-space: nowrap;
		font-size: 1.2rem;
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
		opacity: 0;
		display: flex;
		left: -1000px;
		z-index: 1;
		transform: translateX(0px);
		pointer-events: none;
	}

	&__button {
		cursor: pointer;
		position: relative;
		outline: none;
		width: $button-size;
		height: $button-size;
		display: flex;
		align-items: center;
		justify-content: center;

		// Display label on button hover (unless on touch devices)
		.no-touch &:hover .sidebar__label {
			display: flex;
			left: $button-size;
		}

		// For a11y, it must be possible to tab-focus on to the button itself, so we need to apply
		// the hover style in that case
		&:focus .icon-button:not(.icon-button--selected) {
			background-color: $sidebar-button-hover-background-color;
		}
	}

	&:hover .sidebar__label {
		opacity: 1;
		animation: showthis 0.8s;
		@keyframes showthis {
			0% {
				opacity: 0;
				transform: translateX(-20px);
			}
			75% {
				opacity: 0;
				transform: translateX(-20px);
			}
			100% {
				opacity: 1;
				transform: translateX(0px);
			}
		}
	}

	.icon-button {
		font-size: 22px;
		z-index: 2;
		border-radius: 0;
		width: 100%;
		height: 100%;

		.icon {
			color: white;
			fill: white;
		}
	}

	.sidebar__button.return_to_current:not(:hover) .icon {
		color: var(--accent-colour-2);
	}
}
