.pill {
	border-radius: 99px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: rgba(255, 255, 255, 0.95);
	background-color: #555555;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1.5rem;
	padding-left: 2rem;
	padding-right: 2rem;
	font-size: $button-font-size;
	padding-top: 2px;
	transition: background-color 300ms;
}
