.virtual-phone-clock {
	position: absolute;
	top: 1px;
	// left: calc((100% - 185px) / 2);

	padding-left: calc((100% - 185px) / 2);
	padding-right: calc((100% - 185px) / 2);

	width: 100%;
	height: 98px;
	opacity: 0.9;
	font-size: 1rem;

	&__time {
		font-size: 2.1em;
		font-style: normal;
		font-weight: 300;
		letter-spacing: 0px;
		text-align: center;
		position: absolute;
		top: 0.5em;
		right: 0;
		left: 0;
		color: $darkgrey;
	}

	svg {
		overflow: visible;
	}

	&--paused {
		.virtual-phone-clock__time {
			animation: blinker 1.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
		}
	}

	&--nearzero {
		.virtual-phone-clock__time {
			animation: flashred 1s steps(1) infinite;
		}
	}

	&--zero {
		.virtual-phone-clock__time {
			opacity: 0.8;
		}
	}

	@keyframes blinker {
		50% {
			opacity: 0.1;
		}
	}

	@keyframes flashred {
		50% {
			color: var(--red);
		}
	}
}
