@font-face {
	font-family: Rubrik;
	src: url('fonts/rubrik/Rubrik.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: Rubrik;
	src: url('fonts/rubrik/RubrikLight.woff2') format('woff2');
	font-style: normal;
	font-weight: 300;
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: Rubrik;
	src: url('fonts/rubrik/RubrikMedium.woff2') format('woff2');
	font-style: normal;
	font-weight: 500;
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: Rubrik;
	src: url('fonts/rubrik/RubrikBold.woff2') format('woff2');
	font-style: normal;
	font-weight: 700;
	text-rendering: optimizeLegibility;
}
