.case {
	@include configPanelCard();

	display: flex;
	flex-direction: column;
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;

	&__top {
		display: flex;
		flex-direction: row;
	}

	&__title {
		flex: auto;
		font-size: 19px;
	}

	&__left {
		flex-grow: 1;
		h2 {
			margin-top: 0.5rem;
			margin-bottom: 1rem;
		}
	}

	&__right {
		width: 9rem;
	}

	&__sessions {
		font-weight: bold;
	}

	&__sessionslabel {
		font-weight: normal;
	}

	.case__bottom {
		display: flex;
		flex-direction: row;
	}

	.case__label {
		margin-top: 10px;
		color: grey;
		font-size: 90%;
		margin-bottom: 4px;
	}

	.case__bottomleft {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.case__label {
			margin-top: 0px;
			min-width: 50%;
		}
		.case__value {
			min-width: 50%;
		}
	}

	&__buttons {
		display: flex;
		flex: 1;
		flex-direction: row-reverse;
		align-items: center;
	}

	&__bottom {
		margin-top: 16px;
	}
}
