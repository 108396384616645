@use 'sass:math';

$content-padding: $vertical-spacing 1.4rem;
$content-border-radius: $section-border-radius;
$content-border-colour: var(--border-colour-1);
$height: calc(50vh - 9rem);
$max-height: $height;

.media-feed {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;

	background-color: $white;
	border-radius: $content-border-radius;
	border: solid 1px $content-border-colour;
	overflow: hidden;

	.bubble-heading {
		margin-bottom: math.div($vertical-spacing, 2);
	}

	&__content {
		padding: $vertical-spacing 1.4rem;
		width: 100%;
		overflow-y: auto;
		height: 1px;
		flex-grow: 1;
	}

	.media-feed-item:not(:last-child) {
		// margin-bottom: $vertical-spacing;
		margin-bottom: math.div($vertical-spacing, 2);
	}
}
