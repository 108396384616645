$countdown-background-colour: $white;
$countdown-text-colour: var(--base-text-colour);
$countdown-border: solid 1px var(--header-border-colour);
$countdown-border-radius: 99px;
$countdown-font-size: 22px;
$countdown-letter-spacing: 2px;
$countdown-font-weight: 600;
$countdown-padding: 3px 0 0 0;

.countdown {
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: $countdown-border-radius;

	padding-top: 3px;

	font-size: $countdown-font-size;
	font-weight: $countdown-font-weight;
	letter-spacing: $countdown-letter-spacing;

	width: auto;
	height: auto;
	line-height: 24px;

	box-sizing: border-box;

	height: 100%;
	background: var(--primary-button-colour);
	border-radius: 0;
	transition: width 500ms $default-easing;
	width: 150px;
	padding: 0;
	overflow: hidden;

	&--paused {
		animation: countdown__blinker 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
	}

	&--nearzero {
		animation: flashred 1s steps(1) infinite;
	}

	&--zero {
		background-color: transparent;
		width: 0;
		// color: var(--header-border-colour);
	}

	@keyframes countdown__blinker {
		50% {
			background: transparent;
		}
	}

	@keyframes flashred {
		50% {
			color: $white;
			background-color: $red;
			border-color: $red;
		}
	}
}
