.info {
	@include configPanelCard();

	text-align: center;

	font-size: 1.15rem;
	line-height: 1.7rem;

	user-select: none;
	color: $darkgrey;
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;

	margin-bottom: 1rem;

	&.info--warning {
		background-color: var(--primary-button-colour);
		color: white;
	}
}
