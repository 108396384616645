:root {
  --red: #b91818;
  --green: #23a12f;
  --darkred: #800;
  --lightred: #c91717;
  --red: #b91818;
  --off-black: #252525;
  --darkgrey: #707070;
  --grey: #c9c5c8;
  --mediumgrey: #d9d9dd;
  --disabledgrey: #efefef;
  --lightgrey: #f1f2f4;
  --lightlightgrey: #f8f8f8;
  --white: white;
  --view360cyan: #08c3d9;
  --view360magenta: #f09;
  --view360purple: #1b1464;
  --darkmagenta: #cf167f;
  --base-text-colour: #252525;
  --accent-colour-1: #1b1464;
  --accent-colour-2: #f09;
  --button-colour: #1b1464;
  --button-hover-colour: #261d8f;
  --button-text-colour: white;
  --button-2-colour: #f09;
  --button-2-hover-colour: #ff1aa3;
  --button-2-text-colour: white;
  --primary-button-colour: var(--darkmagenta);
  --primary-button-hover-colour: var(--button-2-hover-colour);
  --primary-button-text-colour: white;
  --header-colour: #1b1464;
  --header-text-colour: white;
  --header-border-colour: #f09;
  --heading-colour: #1b1464;
  --heading-border-colour: #f09;
  --heading-text-colour: #1b1464;
  --background-colour-1: white;
  --background-colour-2: #f3f4f7;
  --panel-background-colour: var(--lightlightgrey);
  --border-colour-1: #e6e6e6;
  --border-colour-2: #e6e6e6;
  --disabled-border-colour: #efefef;
  --sidebar-colour: #15104f;
  --login-background-colour: linear-gradient(225deg, #f09 21%, #1b1464 50%);
  --login-text-colour: black;
  --login-logo-width: 300px;
  --login-button-hover-colour: #f09;
  --login-button-hover-icon-colour: white;
  --login-button-colour: #1b1464;
  --login-button-icon-colour: white;
  --logo-1: url("view360-2021-icon-and-text.91f44e95.png");
  --logo-2: url("view-360-text-2021-white-bg-600.ae720b48.png");
  --logo-3: url("cognitas_logo_2021_3_90.0d5a06ee.png");
  --logo-4: url("v360g-icon-light-padded.455cfc28.png");
  --logo-5: url("v360g-icon-dark.347c2ac7.png");
  --logo-6: url("v360g-icon-light.76aa6852.png");
  --logo-4-width: 60px;
  --logo-5-width: 46px;
  --logo-5-margin: 10px;
  --scrollbar-background-colour: #d9d9dd;
  --virtual-phone__bg: #1b1f38;
  --dark-mode-background-colour: #191529;
  --input-background-colour: white;
  --input-text-colour: var(--base-text-colour);
  --placeholder-colour: #bbb;
  --filmstrip-width: 210px;
}

.dark-theme {
  --panel-background-colour: #302d3f;
  --border-colour-1: transparent;
  --input-background-colour: #191529;
  --input-text-colour: #fff;
  --base-text-colour: #dadada;
  --scrollbar-background-colour: black;
  --placeholder-colour: #636363;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Thin.0f4df1a9.woff2") format("woff2"), url("Lato-Thin.c10abe8e.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Regular.6cf1952c.woff2") format("woff2"), url("Lato-Regular.b7f6ccdd.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Bold.3b4ae738.woff2") format("woff2"), url("Lato-Bold.83c28623.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("nunito-v20-latin-regular.16438a5a.woff2") format("woff2"), url("nunito-v20-latin-regular.614dad95.woff") format("woff");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("nunito-v20-latin-300.63e8d555.woff2") format("woff2"), url("nunito-v20-latin-300.505f6f00.woff") format("woff");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("nunito-v20-latin-700.2642b195.woff2") format("woff2"), url("nunito-v20-latin-700.324cebaa.woff") format("woff");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("nunito-v20-latin-600.90fff772.woff2") format("woff2"), url("nunito-v20-latin-600.b747c7c2.woff") format("woff");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  src: local(""), url("nunito-v20-latin-800.ad1e8433.woff2") format("woff2"), url("nunito-v20-latin-800.89433f25.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("open-sans-v27-latin-300.df134ab7.woff2") format("woff2"), url("open-sans-v27-latin-300.73748d00.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("open-sans-v27-latin-regular.b5b03397.woff2") format("woff2"), url("open-sans-v27-latin-regular.a36ae95d.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("open-sans-v27-latin-500.3cb2c901.woff2") format("woff2"), url("open-sans-v27-latin-500.1fb063cd.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("open-sans-v27-latin-700.c5b2a342.woff2") format("woff2"), url("open-sans-v27-latin-700.2ceab623.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("open-sans-v27-latin-600.12dc94da.woff2") format("woff2"), url("open-sans-v27-latin-600.eac8e6d6.woff") format("woff");
}

@font-face {
  font-family: Rubrik;
  src: url("Rubrik.6de29b3f.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: Rubrik;
  src: url("RubrikLight.9513a39a.woff2") format("woff2");
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: Rubrik;
  src: url("RubrikMedium.1890380e.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: Rubrik;
  src: url("RubrikBold.4a2ab561.woff2") format("woff2");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: icomoon;
  src: url("icomoon.4bfb14a0.eot");
  src: url("icomoon.4bfb14a0.eot#iefix") format("embedded-opentype"), url("icomoon.12b7880d.ttf") format("truetype"), url("icomoon.64dba84c.woff") format("woff"), url("icomoon.d404e08c.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.icon-gmail:before {
  content: "";
}

.icon-microsoftedge:before {
  content: "󩀁";
}

.icon-microsoftexcel:before {
  content: "";
}

.icon-microsoftoffice:before {
  content: "";
}

.icon-microsoftoutlook:before {
  content: "";
}

.icon-microsoftpowerpoint:before {
  content: "";
}

.icon-microsoftword:before {
  content: "";
}

.icon-tiktok:before {
  content: "󩁩";
}

.icon-x:before {
  content: "";
}

.icon-zoom:before {
  content: "󩂉";
}

.icon-caret-down:before {
  content: "";
}

.icon-caret-up:before {
  content: "";
}

.icon-chart-line:before {
  content: "";
}

.icon-memo:before {
  content: "";
}

.icon-microsoft-teams:before {
  content: "";
}

.icon-chrome:before {
  content: "";
}

.icon-sun-bright:before {
  content: "";
}

.icon-moon:before {
  content: "";
}

.icon-forward-step:before {
  content: "";
}

.icon-backward-step:before {
  content: "";
}

.icon-video-alt:before {
  content: "";
}

.icon-microphone-alt:before {
  content: "";
}

.icon-volume:before {
  content: "";
}

.icon-map-marker:before {
  content: "";
}

.icon-camera-alt:before {
  content: "";
}

.icon-voicemail:before {
  content: "";
}

.icon-phone-arrow-up-right:before {
  content: "";
}

.icon-phone-xmark:before {
  content: "";
}

.icon-phone-missed:before {
  content: "";
}

.icon-message-dots:before {
  content: "";
}

.icon-message-exclamation:before {
  content: "";
}

.icon-message:before {
  content: "";
}

.icon-chevrons-up:before {
  content: "";
}

.icon-chevrons-down:before {
  content: "";
}

.icon-arrows-minimize:before {
  content: "";
}

.icon-arrows-maximize:before {
  content: "";
}

.icon-pause:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-circle-info-alt:before {
  content: "";
}

.icon-chevron-left-alt:before {
  content: "";
}

.icon-chevron-left:before {
  content: "";
}

.icon-envelope-alt:before {
  content: "";
}

.icon-phone-alt:before {
  content: "";
}

.icon-whatsapp1:before {
  content: "";
}

.icon-file-lines:before {
  content: "";
}

.icon-file-pdf:before {
  content: "";
}

.icon-file-spreadsheet:before {
  content: "";
}

.icon-file-word:before {
  content: "";
}

.icon-chevrons-right:before {
  content: "";
}

.icon-circle-info:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-paper-plane-top:before {
  content: "";
}

.icon-clock-rotate-left:before {
  content: "";
}

.icon-xmark:before {
  content: "";
}

.icon-check-alt:before {
  content: "";
}

.icon-arrow-right-from-bracket:before {
  content: "";
}

.icon-bars:before {
  content: "";
}

.icon-speaker-view-light:before {
  content: "";
}

.icon-arrow-circle-left:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-at:before {
  content: "";
}

.icon-briefcase:before {
  content: "";
}

.icon-camera:before {
  content: "";
}

.icon-chalkboard-teacher:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-circle-arrow-right:before {
  content: "";
}

.icon-clipboard-list:before {
  content: "";
}

.icon-cog:before {
  content: "";
}

.icon-comments-alt:before {
  content: "";
}

.icon-copy:before {
  content: "";
}

.icon-desktop:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-envelope:before {
  content: "";
}

.icon-facebook-f:before {
  content: "";
}

.icon-file-alt:before {
  content: "";
}

.icon-film:before {
  content: "";
}

.icon-globe:before {
  content: "";
}

.icon-grid:before {
  content: "";
}

.icon-grid-2:before {
  content: "";
}

.icon-grid-3:before {
  content: "";
}

.icon-history:before {
  content: "";
}

.icon-images:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-key:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-map-marker-alt:before {
  content: "";
}

.icon-microphone:before {
  content: "";
}

.icon-microphone-slash:before {
  content: "";
}

.icon-newspaper:before {
  content: "";
}

.icon-paper-plane:before {
  content: "";
}

.icon-pen:before {
  content: "";
}

.icon-pen-to-square:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-power-off:before {
  content: "";
}

.icon-skype:before {
  content: "";
}

.icon-speaker-view:before {
  content: "";
}

.icon-table-cells-large:before {
  content: "";
}

.icon-times:before {
  content: "";
}

.icon-trash-alt:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-user-plus:before {
  content: "";
}

.icon-users:before {
  content: "";
}

.icon-viber:before {
  content: "";
}

.icon-video:before {
  content: "";
}

.icon-video-slash:before {
  content: "";
}

.icon-volume-slash:before {
  content: "";
}

.icon-volume-up:before {
  content: "";
}

.icon-whatsapp:before {
  content: "";
}

.icon-youtube:before {
  content: "";
}

:root {
  --base-font-size: 1rem;
  --base-font-family: Lato, Calibri, sans-serif;
  --base-line-height: 1.0625rem;
  --base-font-weight: 500;
  --heading-font-size: 1.5625rem;
  --heading-font-family: Nunito, Roboto, sans-serif;
  --heading-font-weight: 700;
  --login-font-family: Rubrik, sans-serif;
  --secondary-font-size: 1.25rem;
  --primary-heading-size: 1.125rem;
  --subheading-font-size: 1rem;
  --button-font-size: .8125rem;
  --small-font-size: .8125rem;
}

html {
  color: var(--base-text-colour);
  font-family: Lato, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.0625rem;
}

h1 {
  font-size: var(--secondary-font-size);
}

h2 {
  color: var(--heading-text-colour);
  font-family: Nunito, Roboto, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
}

h3 {
  color: var(--base-text-colour);
  margin: 8px 0;
  font-family: Nunito, Roboto, sans-serif;
  font-size: 1rem;
}

p {
  color: #707070;
  font-size: 1rem;
  line-height: 1.4875rem;
}

label {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--base-text-colour);
  padding-top: 1px;
  padding-left: 3px;
  font-family: Lato, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 600;
}

:root {
  --team-colour-violet: #be74da;
  --team-colour-pink: #f88faf;
  --team-colour-magenta: #ec008c;
  --team-colour-maroon: #7d0000;
  --team-colour-crimson: #950000;
  --team-colour-ruby: #b30027;
  --team-colour-coral: #f87c4e;
  --team-colour-orange: #f87b00;
  --team-colour-amber: #f8bd00;
  --team-colour-gold: #ceaa35;
  --team-colour-olive: #53682e;
  --team-colour-green: #267a26;
  --team-colour-teal: #007d7d;
  --team-colour-cyan: #08c3d9;
  --team-colour-cobalt: #315587;
  --team-colour-blue: #0057ae;
  --team-colour-navy: #001f70;
  --team-colour-indigo: #311277;
  --team-colour-purple: #3a125e;
  --team-colour-charcoal: #333;
  --team-colour-grey: #777;
  --team-colour-silver: #ccc;
}

.animation__slideInUp-enter {
  opacity: 0;
  transform: translateY(100px);
}

.animation__slideInUp-enter-active {
  opacity: 1;
  transition: opacity .3s cubic-bezier(.165, .84, .44, 1), transform .3s cubic-bezier(.165, .84, .44, 1);
  transform: translateY(0);
}

.animation__slideInUp-enter-done, .animation__slideInUp-exit {
  opacity: 1;
  transform: translateY(0);
}

.animation__slideInUp-exit-active {
  opacity: 0;
  transition: opacity .3s cubic-bezier(.165, .84, .44, 1), transform .3s cubic-bezier(.165, .84, .44, 1);
  transform: translateY(100px);
}

.animation__slideInUp-exit-done {
  opacity: 0;
  transform: translateY(100px);
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-40%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes flashNotificationRed {
  0% {
    background-color: var(--red);
    color: var(--white);
  }

  50% {
    background-color: var(--darkred);
    color: var(--white);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(40%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

::-webkit-scrollbar {
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--header-colour);
  border: 1px solid var(--header-colour);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-background-colour);
  border-radius: 5px;
}

* {
  scrollbar-color: var(--header-colour) var(--scrollbar-background-colour);
}

body {
  color: var(--base-text-colour);
  margin: 0;
  padding: 0;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  cursor: default;
  scrollbar-width: thin;
}

.row {
  flex-direction: row;
  display: flex;
}

.spacer {
  flex-grow: 1;
  width: 1px;
}

body.hill {
  --accent-colour-1: #a79368;
  --button-colour: #483764;
  --button-hover-colour: #6b5295;
  --accent-colour-2: #a79368;
  --button-2-hover-colour: #9b875a;
  --header-colour: #0a123e;
  --header-text-colour: white;
  --header-border-colour: #a79368;
  --border-colour-2: #a79368;
  --heading-colour: #0a123e;
  --heading-text-colour: #0a123e;
  --heading-border-colour: #a79368;
  --sidebar-colour: #05091f;
  --login-button-colour: #a79368;
  --login-button-icon-colour: white;
  --login-button-hover-colour: #302543;
  --login-logo-width: 300px;
  --login-background-colour: #0a123e;
  --logo-4-width: 275px;
  --logo-1: url("hill_associates_logo_6.8ba07d63.png");
  --logo-3: url("hill_associates_logo_3.1a3e9c9c.png");
  --logo-4: url("hill_associates_logo_5.2d693d31.png");
  --scrollbar-background-colour: rgba(48, 37, 67, .1);
}

body.axiom {
  --accent-colour-1: #bdd63d;
  --button-colour: #3d3857;
  --button-hover-colour: #573dd6;
  --accent-colour-2: #573dd6;
  --button-2-hover-colour: #745fdd;
  --header-colour: #2e2c30;
  --header-text-colour: white;
  --border-colour-2: #a9c038;
  --heading-colour: #bdd63d;
  --heading-border-colour: #a9c038;
  --heading-text-colour: #2d2932;
  --header-border-colour: #a9c038;
  --sidebar-colour: #2d2932;
  --login-background-colour: #bdd63d;
  --login-button-colour: #3d3857;
  --login-button-icon-colour: white;
  --login-button-hover-colour: #573dd6;
  --login-button-hover-icon-colour: white;
  --logo-1: url("axiom-logo.7bd883b4.jpg");
  --logo-3: url("axiom-small.d82e61b2.jpg");
  --logo-4: url("axiom-logo-2.f14ca935.jpg");
  --scrollbar-background-colour: rgba(189, 214, 61, .25);
}

body.mazda {
  --accent-colour-1: #ebebeb;
  --button-colour: #007dc3;
  --button-hover-colour: #44bcff;
  --accent-colour-2: #444;
  --button-2-hover-colour: #007dc3;
  --header-colour: #202020;
  --header-text-colour: white;
  --header-border-colour: #007dc3;
  --border-colour-2: #ebebeb;
  --heading-colour: #202020;
  --heading-text-colour: #202020;
  --heading-border-colour: #ebebeb;
  --sidebar-colour: #444;
  --login-button-colour: #ebebeb;
  --login-button-icon-colour: #202020;
  --login-button-hover-colour: #007dc3;
  --login-button-hover-icon-colour: white;
  --login-background-colour: #202020;
  --logo-4-width: 275px;
  --logo-1: url("mazda-logo.3717a1cc.png");
  --logo-3: url("Mazda-100th-Anniversary-Logo-1000x713.4f38ff7c.png");
  --logo-4: url("mazda-emblem-2.639db91e.png");
  --scrollbar-background-colour: rgba(0, 125, 195, .1);
}

body.convergent, body.convergant {
  --accent-colour-1: #a46fad;
  --button-colour: #7f80b8;
  --button-hover-colour: #7f80b8;
  --accent-colour-2: #be587a;
  --button-2-hover-colour: #be587a;
  --header-colour: #2e2c30;
  --header-border-colour: #7f80b8;
  --header-text-colour: white;
  --border-colour-2: #a46fad;
  --heading-colour: #a46fad;
  --heading-border-colour: #a46fad;
  --sidebar-colour: #2d2932;
  --login-button-colour: #be587a;
  --login-button-icon-colour: white;
  --logo-3: url("convergent-logo.fabfb75b.png");
  --logo-4: url("convergent-header-logo.64ca46db.png");
  --scrollbar-background-colour: rgba(164, 111, 173, .19);
}

body.networkrail {
  --accent-colour-1: #f07e26;
  --header-border-colour: #f07e26;
  --button-colour: #004c72;
  --button-hover-colour: #f07e26;
  --accent-colour-2: #f07e26;
  --button-2-hover-colour: #004c72;
  --header-colour: #0c1a2f;
  --header-text-colour: white;
  --border-colour-2: #f07e26;
  --heading-text-colour: #0c1a2f;
  --heading-border-colour: #f07e26;
  --sidebar-colour: #0c1a2f;
  --login-button-colour: #004c72;
  --login-button-icon-colour: white;
  --login-button-hover-colour: #f07e26;
  --login-button-hover-icon-colour: white;
  --login-background-colour: #004c72;
  --logo-1: url("network_logo_reverse.e228044c.png");
  --logo-3: url("network_logo_320.436eeb0f.png");
  --logo-4: url("network_logo_320_reverse.ef25f343.png");
  --scrollbar-background-colour: rgba(240, 126, 38, .1);
}

body.hmrc {
  --accent-colour-1: #008786;
  --button-colour: #3a4e5f;
  --button-hover-colour: #004e92;
  --accent-colour-2: #1d70b8;
  --button-2-hover-colour: #08f;
  --header-colour: #2e2c30;
  --header-text-colour: white;
  --header-border-colour: #1d70b8;
  --border-colour-2: #008786;
  --heading-colour: #008786;
  --heading-border-colour: #008786;
  --sidebar-colour: #2d2932;
  --login-background-colour: #008786;
  --login-button-colour: #262828;
  --login-button-hover-colour: #1d70b8;
  --login-button-icon-colour: white;
  --logo-1: url("hmrc-white.99c02ca0.png");
  --logo-3: url("hmrc-alt-small.fcca07cd.png");
  --logo-4: url("hmrc-alt-white-small.59756fe1.png");
  --scrollbar-background-colour: rgba(0, 135, 134, .19);
}

body.alixpartners {
  --accent-colour-1: #ebebeb;
  --primary-button-colour: #428227;
  --primary-button-hover-colour: #60bd39;
  --button-colour: #428227;
  --button-hover-colour: #60bd39;
  --accent-colour-2: #428227;
  --button-2-hover-colour: #428227;
  --header-colour: #202020;
  --header-text-colour: white;
  --header-border-colour: #428227;
  --border-colour-2: #ebebeb;
  --heading-colour: #202020;
  --heading-text-colour: #202020;
  --heading-border-colour: #ebebeb;
  --sidebar-colour: #282828;
  --login-button-colour: #ebebeb;
  --login-button-icon-colour: #202020;
  --login-button-hover-colour: #428227;
  --login-button-hover-icon-colour: white;
  --login-background-colour: #202020;
  --logo-4-width: 170px;
  --login-logo-width: 400px;
  --login-logo-transform: translate(70px, 0px);
  --logo-1: url("fulllogo.0a1fd4cb.png");
  --logo-3: url("AP_Wordmark_Black.0809b521.svg");
  --logo-4: url("AP_Wordmark_White.83f0a809.svg");
  --scrollbar-background-colour: rgba(66, 130, 39, .1);
}

body.emirates {
  --accent-colour-1: #2d2926;
  --primary-button-colour: #bc996e;
  --primary-button-hover-colour: #977346;
  --button-colour: #bc996e;
  --button-hover-colour: #977346;
  --accent-colour-2: #d71a21;
  --button-2-hover-colour: #bc996e;
  --header-colour: #1f1f1e;
  --header-text-colour: white;
  --header-border-colour: #bc996e;
  --border-colour-1: #ebebeb;
  --border-colour-2: #ebebeb;
  --heading-colour: #1f1f1e;
  --heading-text-colour: #1f1f1e;
  --heading-border-colour: #d71a21;
  --sidebar-colour: #2d2926;
  --login-button-colour: #ebebeb;
  --login-button-icon-colour: #1f1f1e;
  --login-button-hover-colour: #bc996e;
  --login-button-hover-icon-colour: white;
  --login-background-colour: #d71a21;
  --logo-4-width: 57px;
  --login-logo-width: 400px;
  --login-logo-transform: translate(70px, 0px);
  --logo-1: url("logo.d30fb1bb.svg");
  --logo-4: url("logo-clear-square-small.64bfbe64.png");
  --logo-5: url("logo-square-red-padded.cd11cbe8.svg");
  --logo-5-width: 68px;
  --logo-5-margin: 0px;
  --scrollbar-background-colour: rgba(188, 153, 110, .1);
}

body.emirates .participant-header-bar__logo {
  background-size: cover !important;
  border-radius: 7px !important;
  flex-shrink: 0 !important;
  width: 51px !important;
  height: 51px !important;
  margin-top: 11px !important;
  margin-left: 13px !important;
  margin-right: 10px !important;
}

body.garda {
  --accent-colour-1: #f72717;
  --header-border-colour: #f72717;
  --button-colour: #747779;
  --button-hover-colour: #f72717;
  --accent-colour-2: #f72717;
  --button-2-hover-colour: #747779;
  --header-colour: #000;
  --header-text-colour: white;
  --border-colour-2: #f72717;
  --heading-text-colour: #747779;
  --heading-border-colour: #f72717;
  --sidebar-colour: #747779;
  --login-button-colour: #747779;
  --login-button-icon-colour: white;
  --login-button-hover-colour: #f72717;
  --login-button-hover-icon-colour: white;
  --login-background-colour: #000;
  --logo-4-width: 57px;
  --logo-1: url("crest-white.acd4c9fa.png");
  --logo-3: url("Gardaworld-Logo.cd0b3f63.png");
  --logo-4: url("logo-small.397138c9.jpg");
  --logo-5: url("logo-small.397138c9.jpg");
  --scrollbar-background-colour: rgba(247, 39, 23, .1);
  --primary-button-colour: var(--accent-colour-1);
  --primary-button-hover-colour: #747779;
}

body.n324 {
  --accent-colour-1: #ebebeb;
  --button-colour: #dfb749;
  --button-hover-colour: #f6ca51;
  --accent-colour-2: #1f1f1f;
  --button-2-hover-colour: #dfb749;
  --header-colour: #1f1f1f;
  --header-text-colour: white;
  --header-border-colour: #dfb749;
  --border-colour-2: #ebebeb;
  --heading-colour: #1f1f1f;
  --heading-text-colour: #1f1f1f;
  --heading-border-colour: #ebebeb;
  --sidebar-colour: #4b4a4a;
  --login-button-colour: #ebebeb;
  --login-button-icon-colour: #1f1f1f;
  --login-button-hover-colour: #dfb749;
  --login-button-hover-icon-colour: white;
  --login-background-colour: #1f1f1f;
  --logo-4-width: 60px;
  --logo-1: url("logo-white.3c5472ca.png");
  --logo-3: url("logo.ba08a019.png");
  --logo-4: url("logo-star.59fc3ead.png");
  --scrollbar-background-colour: rgba(223, 183, 73, .1);
  --primary-button-colour: black;
  --primary-button-hover-colour: #747779;
}

body.tsoc {
  --accent-colour-1: #1de9b6;
  --header-border-colour: #1de9b6;
  --button-colour: #006853;
  --button-hover-colour: #1de9b6;
  --accent-colour-2: #006853;
  --button-2-hover-colour: #006853;
  --header-colour: #006853;
  --header-text-colour: white;
  --border-colour-2: #1de9b6;
  --heading-text-colour: #006853;
  --heading-border-colour: #1de9b6;
  --sidebar-colour: #232323;
  --login-button-colour: #006853;
  --login-button-icon-colour: white;
  --login-button-hover-colour: #1de9b6;
  --login-button-hover-icon-colour: white;
  --login-background-colour: #006853;
  --logo-1: url("Department_for_Transport_reversed_withline.782a6c06.svg");
  --logo-4: url("dft-widelogo-reverse.91d8c117.svg");
  --logo-5: url("dft-widelogo.1a8a6eff.svg");
  --logo-5-width: 175px;
  --logo-4-width: 175px;
  --scrollbar-background-colour: rgba(240, 126, 38, .1);
  --primary-button-colour: #15b542;
}

body.ncosh {
  --accent-colour-1: #102544;
  --header-border-colour: #102544;
  --button-colour: #084459;
  --button-hover-colour: #1b8485;
  --accent-colour-2: #084459;
  --button-2-hover-colour: #33b77f;
  --header-colour: #1b8485;
  --header-text-colour: white;
  --border-colour-2: #1b8485;
  --heading-text-colour: #102544;
  --heading-border-colour: #1b8485;
  --sidebar-colour: #1b8485;
  --login-logo-width: 400px;
  --login-button-colour: #102544;
  --login-button-icon-colour: white;
  --login-button-hover-colour: #ffa513;
  --login-button-hover-icon-colour: white;
  --login-background-colour: #084459;
  --logo-1: url("ncosh_logo_white.4a04a417.svg");
  --logo-3: url("ncosh_english_logo.c889dee9.svg");
  --logo-4: url("ncosh_emblem_white.44fafe08.svg");
  --logo-5: url("ncosh_emblem_sm.f5234e06.svg");
  --logo-5-width: 35px;
  --scrollbar-background-colour: rgba(247, 143, 49, .1);
  --primary-button-colour: #f78f31;
  --primary-button-hover-colour: #ffa513;
}

body.ncosh .status-bar__logo {
  height: 70% !important;
}

body.uh {
  --base-font-family: "Arial", sans-serif;
  --login-font-family: "Arial", sans-serif;
  --heading-font-family: "Arial", sans-serif;
  --accent-colour-1: #ebebeb;
  --header-border-colour: $ blue;
  --button-colour: #000;
  --button-hover-colour: #9a46cf;
  --accent-colour-2: #000;
  --button-2-hover-colour: #9a46cf;
  --header-colour: #000;
  --header-text-colour: white;
  --header-border-colour: #00b9e4;
  --border-colour-2: #ebebeb;
  --heading-colour: #000;
  --heading-text-colour: #000;
  --heading-border-colour: #ebebeb;
  --primary-button-colour: #9a46cf;
  --login-logo-width: 490px;
  --sidebar-colour: #000;
  --login-button-colour: #ebebeb;
  --login-button-icon-colour: #000;
  --button-colour: #000;
  --button-hover-colour: #9a46cf;
  --login-button-hover-colour: #9a46cf;
  --login-button-hover-icon-colour: white;
  --login-background-colour: #9a46cf;
  --logo-4-width: 60px;
  --logo-1: url("uh_bg_purple.88069788.svg");
  --logo-3: url("uh_symbol_white.7ec55b71.svg");
  --logo-4: url("uh_symbol_white.7ec55b71.svg");
  --logo-5: url("uh_symbol_black.50757b10.svg");
  --scrollbar-background-colour: rgba(154, 70, 207, .1);
  --primary-button-colour: black;
  --primary-button-hover-colour: #9a46cf;
  --panel-background-colour: white;
}

body.uh .login h1 {
  color: #9a46cf;
  font-weight: 800;
}

body.uh .login h2 {
  color: #000;
  font-weight: 800;
}

body.uh .status-bar-message, body.uh .previous-session-message {
  background-color: #f53f5b;
}

body.uh .session .msg {
  background-color: #00b9e4;
}

.session {
  border: solid 1px var(--border-colour-1);
  background-color: #fff;
  border-radius: 10px;
  margin: 0 0 .466667rem;
  padding: 16px 20px;
}

.session .msg {
  background: var(--primary-button-colour);
  color: var(--primary-button-text-colour);
  text-align: center;
  border-top-right-radius: 10px;
  margin: -17px -21px 4px;
  padding: 6px 16px;
  font-size: 16px;
  line-height: 20px;
}

.session .msg.inRed {
  color: #fff;
  background: #d71a21;
}

.session label {
  margin: .8rem 0;
  display: inline-block;
}

.session .top {
  flex-direction: row;
  margin-bottom: 8px;
  display: flex;
}

.session .top .left {
  flex-grow: 1;
  margin-right: 20px;
}

.session .top .textarea {
  min-height: 72px;
}

.session .textarea {
  resize: vertical;
  flex-grow: 1;
  width: 100%;
  margin: 4px 0;
  padding: 6px 10px;
}

.session .session__duration {
  position: relative;
}

.session .session__duration > span:first-child {
  font-weight: bold;
}

.session .session__duration .input {
  width: 50px;
  margin: 0 10px;
  display: inline-block;
}

.session .session__duration-warning {
  text-align: right;
  white-space: nowrap;
  color: #d71a21;
  margin-top: 2px;
  margin-right: 1rem;
  font-size: .8rem;
}

.session .row {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.session .row.centred {
  justify-content: center;
}

.session .row-spacer {
  flex-grow: 1;
}

.session .session__index {
  background-color: rgba(60, 60, 60, .1);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding-top: 2px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.session__buttons {
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: 128px;
  display: flex;
}

.session input, .session select {
  border: solid 1px var(--border-colour-1);
  border-radius: 6px;
  outline: none;
  max-width: 100%;
  margin: 2px 6px;
  padding: 4px 6px;
  font-family: inherit;
  font-size: 1rem;
}

.session input {
  width: 64px;
}

input, textarea {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

select {
  background-color: var(--input-background-colour);
  cursor: pointer;
}

textarea {
  border: solid 1px var(--border-colour-1);
  border-radius: 6px;
  outline: none;
  max-width: 100%;
  padding: 8px;
  font-family: inherit;
  font-size: 1em;
}

input[type="text"] {
  border: solid 1px var(--border-colour-1);
  background-color: var(--input-background-colour);
  border-radius: 6px;
  outline: none;
  max-width: 100%;
  padding: 4px 6px;
  font-family: inherit;
  font-size: 1em;
  transition: all .3s cubic-bezier(.19, 1, .22, 1);
}

input[type="number"] {
  border: solid 1px var(--border-colour-1);
  background-color: var(--input-background-colour);
  border-radius: 6px;
  outline: none;
  max-width: 100%;
  padding: 4px 6px;
  font-family: inherit;
  font-size: 1em;
  transition: all .3s cubic-bezier(.19, 1, .22, 1);
}

.input {
  flex-direction: column;
  margin: 16px 0;
  display: flex;
  position: relative;
}

.input input, .input textarea, .input select, .input .flag-select {
  border: solid 1px var(--border-colour-1);
  background-color: var(--input-background-colour);
  color: var(--input-text-colour);
  border-radius: 6px;
  outline: none;
  max-width: 100%;
  padding: 6px 10px;
  font-family: inherit;
  font-size: 1em;
  transition: all .3s cubic-bezier(.19, 1, .22, 1);
}

input:active, input:focus, textarea:active, textarea:focus, select:active, select:focus {
  border-color: var(--accent-colour-2);
}

input:not(:disabled):not(:focus):hover, textarea:not(:disabled):not(:focus):hover, select:not(:disabled):not(:focus):hover {
  border-color: var(--accent-colour-1);
}

.input.row {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.input.row label {
  margin-right: 1rem;
}

textarea:not(:disabled) {
  cursor: text !important;
}

input[type="number"]:not(:disabled) {
  cursor: text !important;
}

input[type="text"]:not(:disabled) {
  cursor: text !important;
}

.input--disabled textarea {
  border-color: var(--disabled-border-colour);
}

.input--disabled input {
  color: #989898;
  background-color: #efefef;
  border-color: rgba(0, 0, 0, 0);
}

select:disabled, textarea:disabled {
  opacity: 1;
  border-color: var(--disabled-border-colour);
  cursor: default;
  background-color: #efefef;
}

select:not(:valid):not(:disabled), input:not(:valid):not(:disabled), .input--error input, .input--error textarea, .input--error select {
  border-color: #b91818;
}

::-webkit-input-placeholder {
  color: var(--placeholder-colour);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

::-ms-input-placeholder {
  color: var(--placeholder-colour);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

::placeholder {
  color: var(--placeholder-colour);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

[placeholder] {
  text-overflow: ellipsis;
}

.input .input__eye {
  cursor: pointer;
  fill: rgba(128, 128, 128, .2);
  background-color: rgba(0, 0, 0, 0);
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 12px;
}

.input .input__eye * {
  cursor: pointer;
}

.input .input__eye svg {
  width: 100%;
  height: 100%;
}

.input input[type="password"] {
  padding-right: 40px;
}

.input .input__caps-msg {
  color: rgba(255, 255, 255, .9);
  text-align: center;
  width: 100%;
  height: 16px;
  font-size: 1rem;
  position: absolute;
  bottom: -24px;
}

.lang-ar input, .lang-ar select, .lang-ar textarea {
  direction: rtl;
}

.status-bar-message, .previous-session-message {
  text-align: center;
  background-color: var(--primary-button-colour);
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 29px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  line-height: 29px;
  overflow: hidden;
}

.previous-session-message {
  background-color: var(--accent-colour-2);
  color: #fff;
  text-align: center;
  height: 29px;
  padding: 0;
  font-weight: bold;
  line-height: 29px;
  display: flex;
}

.previous-session-message .icon-button {
  font-size: 17px;
  width: 29px !important;
  height: 29px !important;
}

.previous-session-message .icon-button .icon, .previous-session-message .icon-button .icon-file-outline, .previous-session-message .icon-button .icon-file {
  color: #fff;
}

.previous-session-message .button {
  flex-shrink: 0;
  width: auto;
  max-height: 100%;
}

.previous-session-message .button button {
  flex-shrink: 0;
  height: 100%;
  max-height: 100%;
  padding-top: 4px;
  padding-bottom: 0;
  line-height: normal;
  margin: 0 !important;
}

.previous-session-message .button button:hover {
  transition: background-color;
}

.previous-session-message .button button:active {
  background: var(--button-colour);
}

.previous-session-message > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  width: 1px;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow: hidden;
}

.in-side-call .initial-information-modal {
  right: var(--filmstrip-width);
}

.initial-information-modal {
  z-index: 2;
  opacity: 1;
  background-color: rgba(0, 0, 0, .6);
  flex-direction: column;
  animation: .3s ease-out fadeInAnim;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.initial-information-modal .icon-button {
  border-radius: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.initial-information-modal .icon-button .icon, .initial-information-modal .icon-button .icon-file-outline, .initial-information-modal .icon-button .icon-file {
  color: #fff;
  fill: #fff;
}

.initial-information-modal__main {
  color: rgba(255, 255, 255, .86);
  z-index: 100;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 1px;
  display: flex;
}

.initial-information-modal__main .bubble-heading {
  color: #fff;
  margin-bottom: 2rem;
}

.initial-information-modal__main > * {
  margin-bottom: 2rem;
}

.initial-information-modal__content {
  border: solid 1px var(--accent-colour-1);
  background-color: var(--panel-background-colour);
  color: var(--base-text-colour);
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 66vw;
  min-width: 600px;
  max-width: 1000px;
  min-height: 70px;
  padding: 1.5rem 2rem 2rem;
  line-height: 1.6rem;
  display: flex;
  overflow: auto;
}

.initial-information-modal__content h2 {
  margin-top: 0;
  margin-bottom: 20px !important;
}

.initial-information-modal__button-row {
  border-top: solid 1px var(--border-colour-1);
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 6rem);
  height: 50px;
  margin: 2rem -3rem -1rem;
  padding-top: 15px;
  padding-left: 3rem;
  padding-right: 2.5rem;
}

.initial-information-modal__translate {
  color: var(--primary-button-colour);
  cursor: pointer;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  display: flex;
}

.initial-information-modal__translate:hover {
  color: var(--primary-button-hover-colour);
}

@keyframes fadeInAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.select-interpreter-modal .button {
  width: 100%;
}

.content-loading-wrapper {
  flex-direction: column;
  align-items: stretch;
  min-height: 200px;
  display: flex;
  overflow: hidden;
}

.decision-maker-dropdown {
  align-items: center;
  display: flex;
}

.decision-maker-dropdown .input {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1rem;
}

.video-participant-modal .video-call-participant {
  width: 640px;
  height: 480px;
}

.video-participant-modal label {
  flex-grow: 1;
}

.video-participant-modal__stats {
  margin-top: 1rem;
}

.video-participant-modal__stats .row {
  margin-top: 2px;
}

.video-participant-modal .video-call-participant:hover, .video-participant-modal .video-call-participant:hover * {
  cursor: default;
}

.video-participant-modal .video-call-participant:hover .video-call-participant__inner {
  border-color: #d9d9dd !important;
}

.full-screen-participant {
  background-color: #000;
  flex-direction: column;
  transition: border-color .7s, box-shadow .7s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.full-screen-participant video {
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.full-screen-participant__display-name {
  text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 4px #000;
  color: #fff;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  padding-top: 2px;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 4rem;
  position: absolute;
  bottom: 64px;
  left: 0;
  right: 0;
  overflow: hidden;
}

.full-screen-participant__icons {
  text-shadow: 0 0 2px #000, 0 0 4px #000;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.full-screen-participant__icons .icon, .full-screen-participant__icons .icon-file-outline, .full-screen-participant__icons .icon-file {
  margin: 1rem;
  font-size: 3rem;
}

.full-screen-participant--novideo video {
  display: none;
}

.full-screen-participant--screenshare video {
  object-fit: contain;
  width: calc(100% - (var(--filmstrip-width)  + 17px));
}

.full-screen-participant--talking-1 {
  border-color: var(--accent-colour-2);
  box-shadow: 0px 0px 0px 1px var(--accent-colour-2);
}

.full-screen-participant--talking-2 {
  box-shadow: 0px 0px 2px 1px var(--accent-colour-2);
}

.full-screen-participant--talking-3 {
  box-shadow: 0px 0px 3px 2px var(--accent-colour-2);
}

.full-screen-participant__error {
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video-call-participant {
  height: calc((var(--filmstrip-width)  - 10px) / 1.5);
  margin: 0;
  padding: .2rem;
  position: relative;
}

.video-call-participant__inner {
  border-style: solid;
  border-width: 2px;
  border-color: var(--border-colour-1);
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  flex-direction: column;
  height: 100%;
  transition: box-shadow .1s, border-color .3s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.video-call-participant.video-call-participant--noaudio .video-call-participant__inner {
  border-color: var(--border-colour-1) !important;
  box-shadow: none !important;
}

.video-call-participant__titles {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
}

.video-call-participant video, .video-call-participant .video-element__container {
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.video-call-participant__display-name, .video-call-participant__subheading, .video-call-participant__mute-message {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  padding-top: 2px;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.4rem;
  overflow: hidden;
}

.video-call-participant__subheading, .video-call-participant__display-name {
  text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 4px #000;
  color: #fff;
}

.video-call-participant__subheading {
  color: #c4c4c4;
  font-size: .85rem;
}

.video-call-participant__mute-message {
  color: #fff;
  text-align: center;
  background-color: #d71a21;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.video-call-participant:hover {
  cursor: pointer;
}

.video-call-participant:hover .video-call-participant__inner {
  transition: box-shadow .7s;
  border-color: var(--accent-colour-1) !important;
}

.video-call-participant:hover * {
  cursor: pointer;
}

.video-call-participant__centre {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video-call-participant__error {
  color: #fff;
  text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 4px #000;
  text-align: center;
  font-size: 1rem;
}

.video-call-participant__icons {
  text-shadow: 0 0 2px #000, 0 0 4px #000;
  flex-direction: row;
  display: flex;
}

.video-call-participant__icons .icon, .video-call-participant__icons .icon-file-outline, .video-call-participant__icons .icon-file {
  margin: 1rem;
  font-size: 2rem;
}

.video-call-participant__dot {
  border-radius: 99px;
  width: 13px;
  height: 13px;
  display: inline-block;
  transform: translate(-8px, 2px);
}

.video-call-participant--novideo video {
  display: none;
}

.video-call-participant--dominant .video-call-participant__inner {
  border: solid 2px var(--accent-colour-2);
}

.video-call-participant--dead {
  display: none;
}

.video-call-participant--with-error .video-call-participant__centre {
  background-color: rgba(0, 0, 0, .5);
}

.video-call-participant--cameraoff video {
  display: none;
}

.video-call-participant--cameraoff .video-call-participant__inner {
  background-size: 0;
}

.video-call-participant .preloader {
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 0;
  right: 0;
}

.video-call-participant.video-call-participant--fullscreen {
  height: auto;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video-call-participant.video-call-participant--fullscreen video {
  object-fit: contain !important;
}

.video-call-participant.video-call-participant--fullscreen .video-call-participant__inner {
  box-shadow: none;
  border-radius: 0;
  border: none !important;
}

.video-call-participant.video-call-participant--fullscreen .video-call-participant__titles {
  bottom: 78px;
}

.video-call-participant.video-call-participant--fullscreen:hover, .video-call-participant.video-call-participant--fullscreen:hover * {
  cursor: default;
}

.video-call-participant.video-call-participant--fullscreen:hover .video-call-participant__statistics {
  display: none;
}

.video-call-participant.video-call-participant--fullscreen.video-call-participant--screenshare video {
  width: calc(100% - (var(--filmstrip-width)  + 17px));
  object-fit: contain !important;
}

.video-call-participant__statistics {
  display: none;
}

.video-call-participant:hover .video-call-participant__statistics {
  color: #fff;
  text-shadow: 0 0 2px #000, 0 0 4px #000;
  background-color: rgba(0, 0, 0, .3);
  width: 100%;
  display: table;
  position: absolute;
  top: 0;
  bottom: 2px;
  left: 0;
  right: 0;
}

.video-call-participant:hover .video-call-participant__statistics tr td {
  color: #fff;
  width: 50%;
  padding: 1px 4px;
  font-size: 13px;
}

.video-call-participant:hover .video-call-participant__statistics tr td label {
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: Lato, Calibri, sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.video-call-participant:hover .video-call-participant__statistics tr td:first-child {
  text-align: right;
}

.video-call-participant:hover .video-call-participant__statistics tr td[colSpan="2"] {
  text-align: center;
}

.video-call__interpreter .video-call-participant:hover .video-call-participant__icons, .video-call__filmstrip .video-call-participant:hover .video-call-participant__icons, .side-call .video-call-participant:hover .video-call-participant__icons, .video-call__interpreter .video-call-participant:hover .video-call-participant__error, .video-call__filmstrip .video-call-participant:hover .video-call-participant__error, .side-call .video-call-participant:hover .video-call-participant__error {
  display: none;
}

.video-call__interpreter .video-call-participant:hover .video-call-participant__statistics, .video-call__filmstrip .video-call-participant:hover .video-call-participant__statistics, .side-call .video-call-participant:hover .video-call-participant__statistics {
  padding-top: 3px;
}

.video-call__interpreter .video-call-participant:hover .video-call-participant__statistics tr td, .video-call__filmstrip .video-call-participant:hover .video-call-participant__statistics tr td, .side-call .video-call-participant:hover .video-call-participant__statistics tr td {
  width: auto;
  padding: 0 4px;
  font-size: 10.5px;
  line-height: 14px;
}

.video-call__interpreter .video-call-participant:hover .video-call-participant__statistics tr td label, .video-call__filmstrip .video-call-participant:hover .video-call-participant__statistics tr td label, .side-call .video-call-participant:hover .video-call-participant__statistics tr td label {
  font-size: 9px;
}

.video-call__interpreter .video-call-participant:hover .video-call-participant__statistics tr td:first-child, .video-call__filmstrip .video-call-participant:hover .video-call-participant__statistics tr td:first-child, .side-call .video-call-participant:hover .video-call-participant__statistics tr td:first-child {
  text-align: left;
}

.configure-video-modal__content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.configure-video-modal__content .input {
  width: 100%;
}

.configure-video-modal__content label {
  margin-bottom: 4px;
  margin-left: 4px;
}

.modal-edit-media .edit-media-modal {
  width: 500px;
}

.modal-edit-media .edit-media-modal .edit-media-modal__enabled-row {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.modal-edit-media .edit-media-modal .edit-media-modal__enabled-row label {
  flex-grow: 1;
}

.modal-edit-media .edit-media-modal .input label {
  margin: 5px 0;
}

.modal-edit-media .edit-media-modal .input:first-child {
  margin-top: 0;
}

.modal-edit-media .edit-media-modal p {
  margin-top: -.5rem;
  padding-left: 3px;
}

.modal-edit-media .edit-media-modal .modal-footer {
  justify-content: center;
  margin-top: 2rem;
  display: flex;
}

.modal-edit-media .edit-media-modal .modal-footer .button {
  margin-left: 1rem;
}

.media-openings-modal__row {
  border: solid 3px var(--base-text-colour);
  color: var(--white);
  background-color: #fff;
  background-color: var(--red);
  border: none;
  border-radius: 10px;
  width: 100%;
  margin: 4px;
  padding: 8px 16px;
  display: flex;
}

.media-openings-modal__row .name {
  text-align: left;
  text-transform: capitalize;
  font-weight: bold;
}

.media-openings-modal__row .icon, .media-openings-modal__row .icon-file-outline, .media-openings-modal__row .icon-file {
  text-align: left;
  color: var(--white);
  flex-grow: 1;
  width: 1rem;
  height: auto;
  margin-left: 1rem;
  position: relative;
  top: -1px;
}

.media-openings-modal__row--opened {
  color: #707070;
  background-color: #efefef;
}

.media-openings-modal__row--opened .icon, .media-openings-modal__row--opened .icon-file-outline, .media-openings-modal__row--opened .icon-file {
  color: #707070;
}

.media-openings-modal__name {
  flex-grow: 1;
  font-weight: bold;
}

.media-openings-modal .icon, .media-openings-modal .icon-file-outline, .media-openings-modal .icon-file {
  flex-grow: 0;
  flex-shrink: 0;
}

.group-release-modal {
  text-align: center;
  width: 400px;
}

.group-release-modal__group {
  border: solid 3px var(--base-text-colour);
  color: var(--base-text-colour);
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  margin: 4px 0;
  padding: 8px 16px;
  display: flex;
}

.group-release-modal__group .name {
  text-align: left;
  text-transform: capitalize;
  font-weight: bold;
}

.group-release-modal__group .icon, .group-release-modal__group .icon-file-outline, .group-release-modal__group .icon-file {
  text-align: left;
  flex-grow: 1;
  height: auto;
  margin-left: 20px;
  position: relative;
  top: -1px;
}

.group-release-modal__group * {
  cursor: pointer;
}

.group-release-modal__group--released {
  color: #585858;
  background-color: #ddd;
  border-color: #ddd;
}

.group-release-modal__group--released .icon, .group-release-modal__group--released .icon-file-outline, .group-release-modal__group--released .icon-file {
  color: #585858;
}

.group-release-modal__sentat {
  opacity: .8;
  font-size: .88em;
}

.prev-sessions-modal {
  flex-direction: column;
  align-items: stretch;
  min-width: 500px;
  min-height: 90px;
  max-height: 500px;
  padding: 0 .5rem;
  display: flex;
  overflow: auto;
}

.modal.modal-prevsessions {
  background-color: var(--lightlightgrey);
}

.initial-information {
  z-index: 1;
  border: solid 1px var(--border-colour-1);
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  min-height: 6rem;
  padding: 0 0 .5rem;
  transition: box-shadow .3s;
  display: flex;
  overflow: hidden;
}

.initial-information__title {
  z-index: 1;
  display: inline-block;
  margin-bottom: -19px !important;
}

.initial-information p {
  white-space: pre-wrap;
  margin: 0;
}

.initial-information__text-wrapper {
  width: 100%;
  max-height: 25vh;
  margin-top: 1rem;
  margin-bottom: .5rem;
  padding: .2rem 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.initial-information__translate {
  opacity: .8;
  cursor: pointer;
  flex-shrink: 0;
  margin-top: .1rem;
  margin-bottom: .1rem;
  font-size: .8125rem;
  line-height: .8125rem;
  transition: color .2s;
  color: var(--accent-colour-2) !important;
}

.initial-information__translate * {
  cursor: pointer;
}

.initial-information__translate:hover, .initial-information__translate--active {
  opacity: 1;
}

.initial-information--with-shadow {
  border-bottom: 1px solid rgba(0, 0, 0, .17);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .06), 0 6px 6px rgba(0, 0, 0, .1);
}

.modal.modal-media-upload {
  width: 66%;
  padding-bottom: 16px;
}

.modal.modal-media-upload .modal__content {
  flex-direction: column;
}

.modal.modal-media-upload .error {
  margin-top: 1rem;
}

.modal.modal-media-upload .react-fine-uploader-file-input-container {
  background: var(--button-colour);
  color: #fff;
  border: none;
  width: 150px;
}

.modal.modal-media-upload .react-fine-uploader-gallery-files {
  padding-top: 30px;
  padding-left: 20px;
}

.modal.modal-media-upload .react-fine-uploader-gallery-file {
  height: auto;
}

.modal.modal-media-upload .fine-uploader-dropzone-container input, .modal.modal-media-upload .fine-uploader-dropzone-container input * {
  cursor: pointer;
}

.modal.modal-media-upload .row {
  justify-content: flex-end;
  margin-top: .5rem;
  display: flex;
}

.phone-number-input {
  flex-grow: 1;
  width: 1px;
}

.phone-number-input .iti {
  max-width: 100%;
}

.video-call-buttons {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 6px;
  display: flex;
}

.video-call-buttons .icon-button {
  border-radius: 5px;
  width: 48px;
  min-width: 48px;
  height: 48px;
  margin: 0 6px 5px;
  padding: 7px;
}

.video-call-buttons .icon-button .badge {
  border: none;
  bottom: -4px;
  right: -4px;
}

.video-call-buttons .icon-button {
  border: 1px solid rgba(0, 0, 0, 0);
}

.video-call-buttons .icon-button:not(.icon-button--selected):not(:hover):not(:active) {
  background-color: #fff;
  border: 1px solid #d9d9dd;
}

.video-call-buttons .video-call-buttons__hang-up.icon-button:not(.icon-button--selected):not(:hover):not(:active) {
  background-color: #d71a21;
  border-color: #d71a21;
  transition: background-color;
}

.video-call-buttons .video-call-buttons__hang-up.icon-button:not(.icon-button--selected):not(:hover):not(:active) .icon, .video-call-buttons .video-call-buttons__hang-up.icon-button:not(.icon-button--selected):not(:hover):not(:active) .icon-file-outline, .video-call-buttons .video-call-buttons__hang-up.icon-button:not(.icon-button--selected):not(:hover):not(:active) .icon-file {
  color: #fff;
}

.tutor-view .video-call-buttons .icon-button {
  border-radius: 99px;
}

.waiting-room-modal {
  z-index: 1;
  opacity: 1;
  background-color: rgba(0, 0, 0, .6);
  justify-content: center;
  align-items: center;
  animation: .4s cubic-bezier(.075, .82, .165, 1) fadeInAnim;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.waiting-room-modal__main {
  opacity: 1;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  width: 580px;
  min-width: 0;
  max-width: 580px;
  padding: 0;
  animation: 1.3s cubic-bezier(.075, .82, .165, 1) slideInAnimation;
  overflow: hidden;
  transform: translateY(0);
}

.waiting-room-modal__content {
  padding: 1rem 2rem;
}

.waiting-room-modal__content p {
  font-size: large;
}

.waiting-room-modal__content .message__translate {
  margin: 1.8rem 0 0;
}

.waiting-room-modal__header {
  background-color: var(--header-colour);
  border-bottom: solid 3px var(--header-border-colour);
  color: var(--header-text-colour);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  display: flex;
}

.waiting-room-modal__logo {
  background-image: var(--logo-4);
  width: var(--logo-4-width);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 2rem;
}

body.waiting-room-active .group-sidebar__bottom .sidebar-button {
  z-index: 1000;
  color: #fff;
}

@keyframes slideInAnimation {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  50% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.language-selector {
  max-width: 100%;
}

.language-selector__option {
  justify-content: space-between;
  align-items: center;
  padding: .4rem 1rem;
  font-size: 1.1rem;
  display: flex;
}

.language-selector__option--focused, .language-selector__option:hover {
  background-color: var(--accent-colour-1);
  color: #fff;
}

.language-selector__option span {
  margin: 0 !important;
}

.language-selector__option span:nth-child(2) {
  opacity: .5;
}

.language-selector__control .language-selector__placeholder {
  color: #bbb;
}

.language-selector__input input {
  transition: none;
}

.language-selector__indicators span {
  margin: 5px 0 !important;
}

.language-selector__single-value {
  line-height: 1.5rem;
}

.media-phone-modal__main {
  flex-direction: column;
  align-items: center;
  width: 500px;
  display: flex;
}

.media-phone-modal__main > .icon, .media-phone-modal__main > .icon-file-outline, .media-phone-modal__main > .icon-file {
  color: var(--accent-colour-2);
  border: solid 4px var(--accent-colour-2);
  border-radius: 999px;
  margin: 1rem;
  padding: 25px;
  font-size: 64px;
}

.media-phone-modal__main > .phone-preloader {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.media-phone-modal__main h2 {
  margin-bottom: 2rem;
}

.media-phone-modal__main p {
  text-align: center;
  margin-top: 0;
  margin-bottom: .7rem;
}

.media-phone-modal__main .button {
  margin: 1.3rem 5px 0;
}

.modal-media-for-phone {
  width: 500px;
  min-width: 500px;
  max-width: 100%;
}

.modal-media-for-phone .modal__header {
  height: 32px;
  position: relative;
}

.modal-media-for-phone .modal__header .modal__title {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.modal-media-for-phone .modal__header .icon-button {
  position: absolute;
  top: 0;
  right: 0;
}

.group-colour-dot {
  border-radius: 99px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  transition: background-color .5s ease-out;
  display: flex;
}

.group-colour-dot .icon, .group-colour-dot .icon-file-outline, .group-colour-dot .icon-file {
  color: #fff;
  text-align: center;
  width: 14px;
  height: 14px;
  font-size: 14px;
  display: block;
}

.media-feed-item {
  color: var(--white);
  cursor: pointer;
  border-radius: 99px;
  outline: none;
  min-width: 260px;
  height: 3rem;
  display: flex;
}

.media-feed-item * {
  cursor: pointer;
}

.media-feed-item__title {
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 5px 0 0;
  padding-right: 1rem;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  overflow: hidden;
}

.media-feed-item__details {
  background-color: var(--red);
  color: var(--white);
  border-radius: 99px;
  flex-grow: 1;
  width: 1px;
  padding-left: 1rem;
  padding-right: .2rem;
  display: flex;
  position: relative;
}

.media-feed-item__details .left {
  flex-direction: column;
  flex-grow: 1;
  width: 1px;
  display: flex;
  position: relative;
}

.media-feed-item__details .right {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-left: -.3rem;
  display: flex;
}

.media-feed-item__details .right .icon-button {
  border-radius: 999px;
  width: calc(3rem - 10px);
  height: calc(3rem - 10px);
  margin-right: 1px;
}

.media-feed-item__details .right .icon-button:not(:hover) {
  background-color: rgba(255, 255, 255, .2);
}

.media-feed-item__details .right .icon-button .icon, .media-feed-item__details .right .icon-button .icon-file-outline, .media-feed-item__details .right .icon-button .icon-file {
  color: #fff;
  fill: #fff;
  font-size: calc(3rem - 28px);
}

.media-feed-item__time, .media-feed-item__opentime {
  opacity: .6;
  text-align: left;
  margin-top: 1px;
  padding-right: .4rem;
  font-size: .8125rem;
}

.media-feed-item__opentime {
  position: absolute;
  bottom: 3px;
  right: 5px;
}

.media-feed-item__icon-container {
  background-color: #94a3b3;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  margin-right: .5rem;
  display: flex;
}

.media-feed-item__icon-container .icon, .media-feed-item__icon-container .icon-file-outline, .media-feed-item__icon-container .icon-file {
  color: #fff;
  text-align: center;
  width: auto;
  height: 23px;
  font-size: 23px;
  display: block;
}

.media-feed-item--recent .media-feed-item__details, .media-feed-item--recent .media-feed-item__details .icon, .media-feed-item--recent .media-feed-item__details .icon-file-outline, .media-feed-item--recent .media-feed-item__details .icon-file {
  animation: 1s 20 flashRed;
}

.media-feed-item--seen .media-feed-item__details {
  color: #fff;
  fill: #fff;
  background-color: #94a3b3;
}

.media-feed-item--seen .media-feed-item__details .icon, .media-feed-item--seen .media-feed-item__details .icon-file-outline, .media-feed-item--seen .media-feed-item__details .icon-file {
  color: #fff;
  fill: #fff;
}

@keyframes flashRed {
  0% {
    color: var(--base-text-colour);
    fill: var(--base-text-colour);
    background-color: #fff;
  }

  49% {
    color: var(--base-text-colour);
    fill: var(--base-text-colour);
    background-color: #fff;
  }

  50% {
    color: #fff;
    fill: #fff;
    background-color: #d71a21;
  }

  100% {
    color: #fff;
    fill: #fff;
    background-color: #d71a21;
  }
}

.phone-preloader {
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  position: relative;
}

.phone-preloader .icon, .phone-preloader .icon-file-outline, .phone-preloader .icon-file {
  color: var(--accent-colour-2);
  font-size: 48px;
}

.phone-preloader__ring1, .phone-preloader__ring2 {
  border: 3px solid;
  border-radius: 50%;
  animation: 1s linear infinite lds-dual-ring;
  position: absolute;
}

.phone-preloader__ring1 {
  border-color: var(--white) transparent var(--white) transparent;
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
}

.phone-preloader__ring2 {
  border-color: var(--accent-colour-2) transparent var(--accent-colour-2) transparent;
  width: 86px;
  height: 86px;
  animation-direction: reverse;
  top: 7px;
  left: 7px;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.phone-preloader.phone-preloader--idle .phone-preloader__ring1, .phone-preloader.phone-preloader--idle .phone-preloader__ring2 {
  animation: none;
}

.phone-preloader.phone-preloader--idle .phone-preloader__ring1 {
  border-color: var(--white);
}

.phone-preloader.phone-preloader--idle .phone-preloader__ring2 {
  border-color: var(--accent-colour-2);
}

.chat {
  border-right: 1px solid var(--border-colour-1);
  flex-direction: column;
  align-items: center;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  padding: 1rem 0;
  display: flex;
}

.chat__scroller {
  flex-grow: 1;
  height: 1px;
  margin: 1rem 0;
  padding: 0 .6rem;
  overflow-y: auto;
}

.chat .message {
  margin-bottom: 8px;
}

.chat__scroller, .chat .message-entry {
  width: 100%;
  padding: 0 .6rem;
}

.chat__recipient-row {
  flex-direction: row;
  align-items: center;
  width: 100%;
  display: flex;
}

.chat__recipient-row label {
  margin-left: 8px;
}

.chat__recipient-row .input {
  flex-grow: 1;
  width: 1px;
  margin: 6px 10px 6px 6px;
}

.chat .message-entry__wrapper {
  border-radius: 6px;
}

.bubble-heading {
  line-height: 40px;
  font-size: var(--heading-font-size);
  color: var(--heading-text-colour);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  font-family: var(--heading-font-family);
  max-width: 100%;
  height: auto;
  margin: 0;
  font-weight: 700;
  line-height: 1.1rem;
  display: inline-block;
  overflow: visible;
  transform: translateY(0);
}

.bubble-heading:after {
  content: "";
  background-color: var(--heading-border-colour);
  width: 80%;
  height: 3px;
  margin: 0 auto;
  display: none;
  transform: translateY(0);
}

.decision-maker {
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  max-width: 100%;
  margin: auto;
  padding-left: 10px;
  display: flex;
}

.decision-maker label {
  white-space: nowrap;
}

.decision-maker h3 {
  min-width: 160px;
  max-width: 160px;
}

.decision-maker div:nth-child(2) {
  flex-grow: 1;
  max-width: 300px;
}

.decision-maker .input, .decision-maker__readonly {
  flex: 0 400px;
  margin: 0 0 0 .5rem;
}

.decision-maker .input input, .decision-maker__readonly {
  border: solid 1px var(--disabled-border-colour);
  padding: .5rem 1rem;
}

.decision-maker__readonly {
  cursor: normal;
  background-color: #efefef;
  outline: none;
  line-height: normal;
}

.decision-maker .button {
  margin-left: .5rem;
}

.decision-maker .input input:focus {
  border: solid 1px var(--accent-colour-1);
}

.decision-maker .input--error input {
  border-color: #b91818;
}

.messages-panel {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.messages-panel .messages-panel__message-list-container {
  border: solid 1px var(--border-colour-1);
  background-color: #ebebeb;
  border-radius: 10px;
  flex-direction: column;
  flex-grow: 1;
  height: 1px;
  display: flex;
  overflow: hidden;
}

.messages-panel .message-list {
  flex-grow: 1;
  width: 100%;
  height: 1px;
  padding: 1rem;
  overflow: auto;
}

.messages-panel .message {
  margin-bottom: .6rem;
}

.messages-panel .message:last-child {
  margin-bottom: 0;
}

.messages-panel .message-entry {
  margin-top: .8rem;
}

.messages-panel--alert .messages-panel__message-list-container {
  animation: 1s step-end infinite messages-panel__flashred;
}

@keyframes messages-panel__flashred {
  50% {
    z-index: 1;
    border-width: 1px;
    border-color: #d71a21;
    box-shadow: 0 0 0 1px #d71a21;
  }
}

.new-case {
  width: 600px;
}

.new-case__overlay {
  background-color: rgba(255, 255, 255, .6);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.welcome-screen {
  color: #fff;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  font-size: 18px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.welcome-screen__content {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: calc(100% - 80px);
  display: flex;
}

.welcome-screen__time {
  margin-top: 15px;
  font-size: 55px;
  font-style: normal;
  font-weight: 300;
  line-height: 75px;
}

.welcome-screen__date {
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
}

.welcome-screen__logo {
  background: var(--logo-1);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 80%;
  height: 23%;
  margin: 40px 10px;
}

.welcome-screen__message {
  white-space: pre-line;
  flex-grow: 1;
  max-height: 55%;
  margin-bottom: 4px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  overflow-x: hidden;
  overflow-y: auto;
}

.welcome-screen__translate {
  color: var(--primary-button-colour);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
}

.welcome-screen__translate:hover {
  color: var(--primary-button-hover-colour);
}

.welcome-screen .virtual-phone-apps {
  flex-shrink: 0;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.group-sidebar {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  min-width: 90px;
  max-width: 90px;
  padding: 1rem .2rem;
  display: flex;
}

.group-sidebar__top, .group-sidebar__bottom {
  flex-direction: column;
  display: flex;
}

.group-sidebar__top {
  flex-grow: 1;
}

.group-sidebar .team-badge {
  margin-bottom: 2rem;
}

.group-sidebar .team-badge__label {
  justify-content: center;
  width: 1rem;
  display: flex;
}

.group-sidebar .team-badge__circle {
  width: 54px;
  height: 54px;
}

.group-sidebar .team-badge__label, .group-sidebar .sidebar-button__label {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-top: .4rem;
  font-size: .7rem;
}

.group-sidebar .sidebar-button {
  outline: none;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.group-sidebar .sidebar-button .icon-button {
  background-color: var(--accent-colour-1);
  border-radius: 99px;
  width: 54px;
  height: 54px;
}

.group-sidebar .sidebar-button .icon-button .icon, .group-sidebar .sidebar-button .icon-button .icon-file-outline, .group-sidebar .sidebar-button .icon-button .icon-file {
  color: #fff;
}

.group-sidebar .sidebar-button:hover, .group-sidebar .sidebar-button:focus {
  cursor: pointer;
  outline: none;
}

.group-sidebar .sidebar-button:hover *, .group-sidebar .sidebar-button:focus * {
  cursor: pointer;
}

.group-sidebar .sidebar-button:hover .icon-button, .group-sidebar .sidebar-button:focus .icon-button {
  background-color: var(--accent-colour-2);
}

.group-sidebar .sidebar-button:hover .icon-button .icon, .group-sidebar .sidebar-button:hover .icon-button .icon-file-outline, .group-sidebar .sidebar-button:hover .icon-button .icon-file, .group-sidebar .sidebar-button:focus .icon-button .icon, .group-sidebar .sidebar-button:focus .icon-button .icon-file-outline, .group-sidebar .sidebar-button:focus .icon-button .icon-file {
  color: #fff;
}

.message-entry {
  flex-direction: row;
  display: flex;
  position: relative;
}

.message-entry label {
  text-transform: none;
  background: none;
  border-radius: 99px;
  height: 20px;
  margin-top: calc(1.15rem - 7px);
  padding: 0 1rem;
  line-height: 14px;
  transition: top .1s cubic-bezier(.19, 1, .22, 1), padding .1s cubic-bezier(.19, 1, .22, 1);
  position: absolute;
  top: 1px;
  left: 0;
}

.message-entry.message-entry--focus label {
  background: #fff;
  padding: 3px .7rem;
  top: -34px;
}

.message-entry__wrapper {
  border: solid 1px var(--border-colour-1);
  border-radius: 10px;
  flex-grow: 1;
  overflow: hidden;
}

.message-entry textarea {
  resize: none;
  border: none;
  align-items: center;
  width: 100%;
  height: 2.3rem;
  max-height: 7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: calc(1.0625rem + 2px);
  display: flex;
}

.message-entry .button {
  margin-left: .5rem;
}

.message-entry .button button {
  text-align: center;
  height: 2.3rem;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.message-entry .button .button__text {
  transform: translateY(1px);
}

.message-entry--disabled .message-entry__wrapper {
  border: solid 1px var(--disabled-border-colour);
}

.message-entry:not(.message-entry--disabled) .message-entry__wrapper:hover {
  border-color: var(--accent-colour-1);
}

.message-entry:not(.message-entry--disabled) .message-entry__wrapper:focus-within {
  border-color: var(--accent-colour-1);
}

@keyframes slideInFromBelow {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  30% {
    opacity: 0;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.virtual-phone {
  -webkit-filter: drop-shadow(9px 10px 15px rgba(0, 0, 0, .3)) drop-shadow(1px 3px 2px rgba(0, 0, 0, .4));
  filter: drop-shadow(9px 10px 15px rgba(0, 0, 0, .3)) drop-shadow(1px 3px 2px rgba(0, 0, 0, .4));
  flex-direction: column;
  align-items: center;
  width: 352px;
  height: 704px;
  animation: 1.5s cubic-bezier(.19, 1, .22, 1) slideInFromBelow;
  display: flex;
  position: relative;
}

.virtual-phone .home-screen p {
  color: #4b4a4a;
  opacity: .8;
}

.virtual-phone .virtual-phone-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.virtual-phone .virtual-phone-background path {
  fill: var(--virtual-phone__bg);
}

.virtual-phone .virtual-phone__bg1, .virtual-phone .virtual-phone__bg2 {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
}

.virtual-phone .virtual-phone__bg1 {
  background-color: #fff;
}

.virtual-phone .virtual-phone__bg2 {
  opacity: 0;
  background: linear-gradient(15.4deg, #120084 8%, #09003e 38%, #1c0048 57%, #5c0065 78%, #b3008b 96%);
  width: 237%;
  height: 110%;
  transition: all 3s;
  animation-name: spin;
  animation-duration: 15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: paused;
  position: absolute;
  top: -5%;
}

.virtual-phone .messages-app {
  transition: transform .6s cubic-bezier(.19, 1, .22, 1);
  transform: translateY(100%);
}

.virtual-phone .messages-app__overlay {
  opacity: 0;
  transition: opacity .6s cubic-bezier(.19, 1, .22, 1);
}

.virtual-phone--messagesopen .messages-app {
  transform: translateY(0%);
}

.virtual-phone--messagesopen .messages-app__overlay {
  opacity: 1;
}

.virtual-phone .virtual-phone-apps {
  transition: transform .6s cubic-bezier(.19, 1, .22, 1);
  transform: translateY(0%);
}

.virtual-phone .start-screen, .virtual-phone .welcome-screen, .virtual-phone .home-screen, .virtual-phone .audio-player-screen, .virtual-phone .video-player, .virtual-phone .update-screen, .virtual-phone .voice-message-screen {
  transition: transform .6s cubic-bezier(.19, 1, .22, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.virtual-phone .virtual-phone__bg2 {
  opacity: 0;
}

.virtual-phone .home-screen {
  top: 108px;
}

.virtual-phone .welcome-screen, .virtual-phone .start-screen, .virtual-phone .audio-player-screen, .virtual-phone .video-player, .virtual-phone .update-screen {
  transform: translateX(100%);
}

.virtual-phone .home-screen {
  transform: translateX(-100%);
}

.virtual-phone .voice-message-screen {
  transform: translateY(100%);
}

.virtual-phone .virtual-phone-clock {
  transform: translateY(0);
}

.virtual-phone--welcome .virtual-phone-clock {
  transition-delay: 0s;
  transform: translateY(-100px);
}

.virtual-phone--welcome .welcome-screen {
  transform: translateX(0%);
}

.virtual-phone--welcome .virtual-phone__bg2 {
  opacity: 1;
  animation-play-state: running;
}

.virtual-phone--home .home-screen, .virtual-phone--start .start-screen {
  transform: translateX(0%);
}

.virtual-phone--start .virtual-phone__bg2 {
  opacity: 0;
  animation-play-state: running;
}

.virtual-phone--start .virtual-phone-apps {
  transform: translateY(100%);
}

.virtual-phone--audioplayer .virtual-phone-clock {
  transition-delay: 0s;
  transform: translateX(-100%);
}

.virtual-phone--audioplayer .audio-player-screen {
  transform: translateX(0%);
}

.virtual-phone--videoplayer .virtual-phone-clock {
  transition-delay: 0s;
  transform: translateX(-100%);
}

.virtual-phone--videoplayer .video-player {
  transform: translateX(0%);
}

.virtual-phone--update .virtual-phone-clock {
  transition-delay: 0s;
  transform: translateX(-100%);
}

.virtual-phone--update .update-screen, .virtual-phone--voicemessage .home-screen {
  transform: translateX(0%);
}

.virtual-phone--voicemessage .voice-message-screen {
  transform: translateY(0%);
}

.virtual-phone .virtual-phone-frame {
  z-index: 2;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.virtual-phone .virtual-phone__content {
  background-color: var(--virtual-phone__bg);
  border-radius: 30px;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: calc(100% - 39px);
  height: calc(100% - 28px);
  margin: 14px 19px;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.virtual-phone .virtual-phone-clock {
  margin-top: 4px;
  transition: transform .6s cubic-bezier(.19, 1, .22, 1);
}

.virtual-phone .notifications {
  flex-grow: 1;
  width: 100%;
  height: 0;
  margin-top: 15px;
  margin-right: 4px;
  padding-left: 8px;
  padding-right: 4px;
  overflow: auto;
}

.virtual-phone .notifications p {
  text-align: center;
  padding: 0 15px;
}

.virtual-phone .preloader-dual-ring__ring1 {
  border-color: var(--white) transparent var(--white) transparent;
}

.virtual-phone ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 6px;
}

.virtual-phone ::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 6px;
  width: 6px;
  height: 6px;
}

.virtual-phone ::-webkit-scrollbar-thumb {
  background-color: var(--accent-colour-2);
  border: 1px solid var(--accent-colour-2);
  border-radius: 6px;
}

.virtual-phone__container {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (max-height: 850px) {
  .virtual-phone {
    zoom: .9;
  }
}

@media screen and (max-height: 800px) {
  .virtual-phone {
    zoom: .85;
  }
}

@media screen and (max-height: 720px) {
  .virtual-phone {
    zoom: .8;
  }
}

@media screen and (max-height: 670px) {
  .virtual-phone {
    zoom: .7;
  }
}

@media screen and (max-height: 600px) {
  .virtual-phone {
    zoom: .6;
  }
}

@media screen and (min-width: 1670px) and (min-height: 1150px) {
  .virtual-phone {
    zoom: 1.3;
  }
}

.virtual-phone-frame .virtual-phone-frame__bezel {
  transition: fill 1s cubic-bezier(.19, 1, .22, 1);
}

.virtual-phone-frame .virtual-phone-frame__buttons {
  transition: stroke 1s cubic-bezier(.19, 1, .22, 1);
}

.dark-theme .virtual-phone {
  -webkit-filter: none;
  filter: none;
}

.dark-theme .virtual-phone-frame .virtual-phone-frame__bezel {
  fill: #0f0f0f;
}

.dark-theme .virtual-phone-frame .virtual-phone-frame__buttons {
  stroke: #050505;
}

.app-header, .app-header h2 {
  color: #fff;
}

.decision-log {
  flex-direction: column;
  flex-grow: 1;
  height: 0;
  display: flex;
}

.decision-log__header {
  justify-content: space-around;
  display: flex;
}

.decision-log__scroller {
  border: 10px solid #f8f8f8;
  flex-grow: 1;
  height: 0;
  overflow: auto;
}

.decision-log__inner {
  grid-column-gap: 10px;
  grid-row-gap: 2px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  padding: 8px 0;
  display: grid;
}

.decision-log__inner h3 {
  margin-bottom: 0;
}

.decision-log__inner > * {
  flex-shrink: 0;
  display: flex;
}

.decision-log__inner > :last-child {
  margin-bottom: 16px;
}

.decision-log p {
  text-align: center;
  width: 260px;
  margin: 15px auto;
}

.decision-log p:first-child {
  margin-top: 10%;
}

.decision-log__metadata {
  opacity: .6;
  flex-direction: row;
  grid-column: 1 / span 2;
  align-items: center;
  margin-top: -3px;
  margin-bottom: .7rem;
  padding-left: 4px;
  padding-right: 4px;
  font-size: .8125rem;
  display: flex;
}

.decision-log__author {
  margin-left: .5rem;
  margin-right: 1rem;
  padding: 0;
}

.decision-log__scroller {
  flex-shrink: 1;
  padding: 0;
}

.decision-log__advice, .decision-log__rationale {
  flex-direction: row;
  align-items: stretch;
  min-height: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.decision-log__advice textarea, .decision-log__rationale textarea {
  resize: none;
  cursor: text;
  border-radius: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.decision-log__advice textarea:disabled, .decision-log__rationale textarea:disabled {
  cursor: default;
}

.decision-log__advice textarea:focus, .decision-log__rationale textarea:focus {
  border: solid 1px var(--accent-colour-1);
}

.decision-log__advice textarea, .decision-log__advice .resizer, .decision-log__rationale textarea, .decision-log__rationale .resizer {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  line-height: normal;
  overflow: hidden;
}

.decision-log__advice .resizer, .decision-log__rationale .resizer {
  opacity: 0;
  border: 1px solid rgba(0, 0, 0, 0);
}

.decision-log__box {
  border: solid 1px var(--border-colour-1);
  white-space: pre-wrap;
  word-break: break-word;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  background-color: #fff;
  border-radius: 10px;
  flex-grow: 1;
  width: 0;
  padding: 8px 12px;
}

.decision-log__translate {
  letter-spacing: normal;
  text-shadow: none;
  cursor: pointer;
  transition: opacity .2s;
  color: var(--accent-colour-2) !important;
}

.decision-log__translate:hover, .decision-log__translate--active {
  opacity: 1;
}

.decision-log__translate * {
  cursor: pointer;
}

.decision-log__auth-and-time {
  text-align: left;
  flex-grow: 1;
}

.decision-log__metadata {
  padding: 0 8px;
}

.decision-log__separator {
  margin: 0 .5em;
}

.message-list {
  flex-grow: 1;
}

.login-button {
  text-align: center;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  min-width: 120px;
  margin: 16px;
  font-size: 20px;
  font-weight: 400;
  display: flex;
}

.login-button * {
  cursor: pointer;
}

.login-button__circle {
  background-color: var(--login-button-colour);
  cursor: pointer;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-bottom: 1.2rem;
  transition: background-color .3s;
  display: flex;
}

.login-button__circle .icon, .login-button__circle .icon-file-outline, .login-button__circle .icon-file {
  color: var(--login-button-icon-colour);
  text-align: center;
  width: 100%;
  height: 36px;
  font-size: 36px;
}

.login-button__circle .fa-user-plus {
  transform: translateX(4px);
}

.login-button__text {
  font-size: .8em;
  font-family: var(--login-font-family);
  color: var(--login-text-colour);
}

.login-button:focus {
  outline: none;
}

.login-button:focus .login-button__circle, .login-button:hover .login-button__circle, .login-button:active .login-button__circle {
  background-color: var(--login-button-hover-colour);
  border-color: var(--login-button-hover-colour);
  transition: background-color;
}

.login-button:focus .login-button__circle .icon, .login-button:focus .login-button__circle .icon-file-outline, .login-button:focus .login-button__circle .icon-file, .login-button:hover .login-button__circle .icon, .login-button:hover .login-button__circle .icon-file-outline, .login-button:hover .login-button__circle .icon-file, .login-button:active .login-button__circle .icon, .login-button:active .login-button__circle .icon-file-outline, .login-button:active .login-button__circle .icon-file {
  color: var(--login-button-hover-icon-colour);
}

.media-viewer {
  z-index: 2;
  background: var(--panel-background-colour);
  border: solid 1px var(--border-colour-1);
  border-radius: 10px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-width: 100%;
  margin-bottom: 1rem;
  padding: 12px 18px 8px;
  display: flex;
  overflow: hidden;
}

.media-viewer h2 {
  flex-shrink: 0;
  margin-bottom: 12px !important;
}

.media-viewer__footer {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.media-viewer__media-container {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1px;
  margin-bottom: 8px;
  display: flex;
}

.media-viewer__media-container p {
  white-space: pre-wrap;
  border: solid 1px var(--border-colour-1);
  background-color: #fff;
  border-radius: 10px;
  width: 90%;
  max-width: 840px;
  max-height: 100%;
  padding: 14px 24px;
  overflow: auto;
}

.media-viewer__media-container .toolbar {
  display: none !important;
}

.media-viewer img {
  object-fit: contain;
  cursor: zoom-in;
  max-width: 100%;
  max-height: 100%;
}

.media-viewer video {
  object-fit: contain;
  cursor: zoom-in;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.media-viewer .button {
  margin: 0 8px;
}

.media-viewer .pdf-iframe, .media-viewer .pdf-viewer {
  border-top: solid 1px var(--border-colour-1);
  border-bottom: solid 1px var(--border-colour-1);
  max-height: 100%;
  margin-right: -8px;
}

.media-viewer .pdf-iframe ::-webkit-scrollbar-track {
  border-radius: 0;
}

.media-viewer .pdf-viewer ::-webkit-scrollbar-track {
  border-radius: 0;
}

.media-viewer .pdf-iframe .react-pdf__Document, .media-viewer .pdf-viewer .react-pdf__Document {
  max-width: 100%;
}

.media-viewer .pdf-iframe .react-pdf__Page, .media-viewer .pdf-viewer .react-pdf__Page {
  border: solid 1px var(--border-colour-1);
  margin-bottom: 16px;
  margin-right: 0;
}

.media-viewer .pdf-iframe .react-pdf__Page:first-child, .media-viewer .pdf-viewer .react-pdf__Page:first-child {
  border-top: none;
}

.media-viewer .pdf-iframe .react-pdf__Page:last-child, .media-viewer .pdf-viewer .react-pdf__Page:last-child {
  border-bottom: none;
}

.media-viewer .pdf-iframe .react-pdf__Page__textContent, .media-viewer .pdf-iframe .react-pdf__Page__annotations, .media-viewer .pdf-viewer .react-pdf__Page__textContent, .media-viewer .pdf-viewer .react-pdf__Page__annotations {
  display: none;
}

.media-viewer .pdf-iframe .react-pdf__Page__canvas, .media-viewer .pdf-viewer .react-pdf__Page__canvas {
  max-width: 100%;
  width: auto !important;
  height: auto !important;
}

.media-viewer img, .media-viewer video {
  border: solid 1px var(--border-colour-1);
}

.prev-session {
  flex-direction: column;
  display: flex;
}

.prev-session .right {
  text-align: right;
  flex-direction: column;
  display: flex;
}

.prev-session .right .button {
  margin: 2px;
}

.prev-session .right .icon-button {
  margin-left: 10px;
  margin-right: -8px;
}

.prev-session h2 {
  margin: 4px 0 8px;
}

.prev-session__time {
  color: var(--darkgrey);
}

.prev-session.prev-session--selected {
  background-color: var(--accent-colour-2);
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.prev-session.prev-session--selected h2, .prev-session.prev-session--selected .icon, .prev-session.prev-session--selected .icon-file-outline, .prev-session.prev-session--selected .icon-file {
  color: #fff;
}

.prev-session.prev-session {
  padding-left: 16px;
}

.scheduleRow {
  border: solid 1px var(--border-colour-1);
  cursor: move;
  background-color: #ebebeb;
  border-radius: 10px;
  flex-direction: column;
  padding: 4px 12px 4px 32px;
  transition: border-color .3s;
  display: flex;
}

.scheduleRow * {
  cursor: move;
}

.scheduleRow select {
  cursor: pointer;
}

.scheduleRow .row:first-child {
  margin-left: -32px;
}

.scheduleRow .row:first-child input, .scheduleRow .row:first-child select {
  height: 33px;
}

.scheduleRow:hover {
  border-color: var(--accent-colour-1);
  transition: none;
}

.scheduleRow__index {
  color: var(--base-text-colour);
  text-align: center;
  opacity: .5;
  width: 32px;
  overflow: visible;
}

.scheduleRow .scheduleRow__message {
  opacity: .5;
  flex-grow: 1;
}

.scheduleRow .media_dropdown {
  flex-grow: 0;
  flex-shrink: 1;
  width: 21rem;
}

.scheduleRow .icon-button {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  margin: 0;
  padding: 0;
}

.scheduleRow .button {
  margin-left: 6px;
}

.scheduleRow .label {
  margin-right: .5rem;
}

.scheduleRow input {
  margin-left: .5rem;
  margin-right: .5rem;
}

.scheduleRow select {
  margin-left: 0;
  margin-right: .5rem;
}

.scheduleRow .textarea {
  margin-bottom: 6px;
}

.scheduleRow textarea {
  resize: none;
}

.schedule-row-wrapper {
  margin-bottom: 12px;
}

.wait-message {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  min-width: 500px;
  max-width: 80%;
  height: auto;
  padding: .5rem;
  box-shadow: 0 25px 25px -15px rgba(0, 0, 0, .25);
}

.wait-message__inner {
  pointer-events: all;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wait-message__inner p {
  white-space: pre-wrap;
  flex-grow: 1;
  padding: 0 1rem;
}

.wait-message__inner p, .wait-message__inner h3 {
  margin: 1.2rem 0;
  text-align: center !important;
}

.wait-message__inner p:first-of-type {
  margin-top: 0;
}

.wait-message__inner .bubble-heading {
  margin-top: .4rem;
  margin-bottom: 1.2rem;
}

.wait-message-overlay {
  pointer-events: none;
  z-index: 5;
  background-color: rgba(0, 0, 0, .4);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.participant-view .wait-message {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.participant-view .wait-message .bubble-heading {
  color: var(--accent-colour-2);
  justify-content: center;
  align-items: center;
  display: flex;
}

.participant-view .wait-message p {
  margin: 0 0 2.1rem;
}

.participant-view .wait-message h3 {
  text-align: center;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  margin: auto auto 1.3rem;
  font-size: 2rem;
  display: flex;
}

.in-side-call .update-modal {
  right: var(--filmstrip-width);
}

.update-modal {
  z-index: 2;
  opacity: 1;
  background-color: rgba(0, 0, 0, .8);
  flex-direction: column;
  animation: .3s ease-out fadeInAnim;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.update-modal .icon-button {
  border-radius: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.update-modal .icon-button .icon, .update-modal .icon-button .icon-file-outline, .update-modal .icon-button .icon-file {
  color: #fff;
  fill: #fff;
}

.update-modal__main {
  color: rgba(255, 255, 255, .86);
  z-index: 100;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  height: 1px;
  padding-top: 20vh;
  display: flex;
}

.update-modal__main .bubble-heading {
  color: #fff;
}

.update-modal__content {
  border: solid 1px var(--accent-colour-1);
  color: var(--base-text-colour);
  word-break: break-word;
  white-space: pre-wrap;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  min-height: 70px;
  margin-top: 2rem;
  padding: 1.5rem 3rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: flex;
}

.groups-list {
  border: solid 1px var(--border-colour-1);
  color: var(--base-text-colour);
  border-radius: 10px;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: stretch;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: bold;
  display: flex;
}

.groups-list__group {
  text-transform: capitalize;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 999px;
  align-items: center;
  margin: 0 0 .5rem;
  padding: 0;
  transition: background-color .2s, border-color .2s;
  display: flex;
  overflow: hidden;
}

.groups-list__group * {
  cursor: pointer;
}

.groups-list__group span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.groups-list__group:hover {
  background-color: #fff;
}

.groups-list__group--selected, .groups-list__group--selected:hover {
  border: solid 1px var(--accent-colour-1);
  background-color: #fff;
}

.groups-list .dot {
  border-radius: 50%;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin: 5px .8rem 5px 5px;
}

.groups-list .none-found {
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
}

.groups-list .badge {
  float: right;
  background-color: #d71a21;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-left: 1rem;
}

.icon-button {
  text-align: center;
  cursor: pointer;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 4px;
  font-size: 24px;
  font-weight: 200;
  display: flex;
  position: relative;
}

.icon-button * {
  cursor: pointer;
}

.icon-button--disabled {
  opacity: .3;
  pointer-events: none;
}

.icon-button .icon, .icon-button .icon-file-outline, .icon-button .icon-file {
  color: var(--base-text-colour);
  fill: var(--base-text-colour);
  background-color: rgba(0, 0, 0, 0) !important;
}

.icon-button__text {
  text-transform: uppercase;
  opacity: .9;
  white-space: nowrap;
  text-align: center;
  font-size: 13px;
  line-height: 13px;
  position: absolute;
  bottom: -20px;
  overflow: visible;
  font-family: var(--base-font-family) !important;
}

.icon-button:hover {
  background-color: var(--button-hover-colour);
  transition: none;
}

.icon-button:hover .icon, .icon-button:hover .icon-file-outline, .icon-button:hover .icon-file {
  background-color: var(--button-hover-colour);
  color: var(--button-text-colour);
  transition: none;
}

.icon-button:active, .icon-button--selected, .icon-button--selected:hover {
  background-color: var(--accent-colour-2);
}

.icon-button:active .icon, .icon-button:active .icon-file-outline, .icon-button:active .icon-file, .icon-button--selected .icon, .icon-button--selected .icon-file-outline, .icon-button--selected .icon-file, .icon-button--selected:hover .icon {
  background-color: var(--accent-colour-2);
  color: var(--button-text-colour);
  fill: var(--base-text-colour);
}

.icon-button .badge {
  border: solid 2px var(--sidebar-colour);
  background-color: #d71a21;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 6px;
  right: 6px;
}

.icon-select {
  width: 72px;
}

.icon-select .icon, .icon-select .icon-file-outline, .icon-select .icon-file {
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  font-size: 18px;
  line-height: 35px;
  display: flex;
}

.icon-select .icon:before, .icon-select .icon-file-outline:before, .icon-select .icon-file:before {
  transform: translate(.5px);
}

.icon-select .icon-select, .icon-select .icon-select____control {
  min-height: 45px;
}

.icon-select .icon-select____control {
  border: solid 1px var(--border-colour-1);
}

.icon-select .icon-select__selected-value {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-select .icon-select__option {
  align-items: center;
  padding-top: .1rem;
  padding-bottom: .1rem;
  padding-left: .5rem;
  display: flex;
  font-family: Lato, Calibri, sans-serif !important;
}

.icon-select .icon-select__option .icon, .icon-select .icon-select__option .icon-file-outline, .icon-select .icon-select__option .icon-file {
  margin-right: .5rem;
}

.icon-select .icon-select____menu {
  width: 15rem;
}

.icon-select .icon-select____indicator {
  padding: 8px 4px 8px 0;
}

.icon-select .icon-select____indicator-separator {
  display: none;
}

.icon-select .icon-select____value-container {
  flex-wrap: nowrap;
  padding-left: 5px;
}

.icon-select .icon-select____control--is-focused, .icon-select .icon-select____control:hover {
  border-color: var(--accent-colour-1);
  box-shadow: 0 0 0 1px var(--accent-colour-1);
}

.media-modal {
  z-index: 3;
  opacity: 1;
  background-color: rgba(0, 0, 0, .8);
  flex-direction: column;
  animation: .3s ease-out fadeInAnim;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.media-modal .icon-button {
  border-radius: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.media-modal .icon-button .icon, .media-modal .icon-button .icon-file-outline, .media-modal .icon-button .icon-file {
  color: #fff;
  fill: #fff;
}

.media-modal__main {
  color: rgba(255, 255, 255, .86);
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 1px;
  display: flex;
}

.media-modal__main img {
  object-fit: contain;
}

.media-modal__main audio {
  zoom: 1.2;
  width: 600px;
  max-width: 100%;
}

.media-modal__main img, .media-modal__main video, .media-modal__main .pdf-iframe {
  object-position: center;
  max-width: calc(100vw - 100px);
  height: 100vh;
  max-height: calc(100vh - 186px);
}

.media-modal__bottom {
  text-align: center;
  flex-direction: row;
  flex-shrink: 0;
  width: 100%;
  height: 50px;
  display: flex;
}

.media-modal__bottom h2 {
  color: #fff;
  flex-grow: 1;
  padding-left: 16px;
}

.in-side-call .media-modal {
  right: var(--filmstrip-width);
}

.participant.participant {
  padding-top: .5rem;
  padding-bottom: .5rem;
  overflow: hidden;
}

.participant.participant h2 {
  line-height: 32px;
}

.participant.participant p {
  line-height: 20px;
  font-size: var(--small-font-size);
  text-transform: uppercase;
  color: rgba(0, 0, 0, .5);
}

.participant.participant p .icon, .participant.participant p .icon-file-outline, .participant.participant p .icon-file {
  color: rgba(0, 0, 0, .5);
  margin-left: .5rem;
  margin-right: .5rem;
}

.participant.participant h2, .participant.participant p {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  overflow: hidden;
}

.participant.participant .col {
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible;
}

.participant.participant .col p {
  text-align: center;
  bottom: -18px;
  left: 0;
  right: 0;
}

.participant.participant .dot {
  border-radius: 99px;
  width: 24px;
  height: 24px;
  transition: background-color .5s ease-out;
}

.participant.participant .left {
  flex-grow: 1;
  width: 1px;
}

.participant.participant .right {
  opacity: 1;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  width: auto;
}

.participant.participant .right > * {
  margin: 0 0 0 1rem;
}

.participant.participant .right .dot {
  margin-left: 0;
}

.participant.participant .right .pill {
  min-width: 8.5rem;
  margin-top: 5px;
  margin-bottom: 4px;
}

.home-screen {
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.none-found {
  color: rgba(0, 0, 0, .5);
  font-size: var(--primary-heading-size);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 58px;
  margin: 6px 0;
  padding: 0 16px;
  font-weight: 400;
  display: flex;
}

.media-item, .prev-session {
  border: solid 1px var(--border-colour-1);
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  margin: 0 0 .466667rem;
  padding: 8px 16px 8px 10px;
  display: flex;
}

.media-item *, .prev-session * {
  cursor: pointer;
}

.media-item__icon {
  background-color: green;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin-right: 1rem;
  font-size: 18px;
  display: flex;
}

.media-item__middle {
  flex-direction: column;
  flex-grow: 1;
  width: 1px;
  display: flex;
}

.media-item__name {
  word-break: break-all;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 1rem;
  line-height: 24px;
  overflow: hidden;
}

.media-item__name .icon, .media-item__name .icon-file-outline, .media-item__name .icon-file {
  color: var(--heading-text-colour);
  margin-left: .5rem;
  display: inline-block;
}

.media-item .uploaded, .prev-session .uploaded {
  opacity: .5;
  flex-grow: 1;
  margin-top: 4px;
  font-size: .8125rem;
}

.media-item--unseen {
  background-color: var(--header-colour);
  color: #fff;
}

.media-item .icon-select, .prev-session .icon-select {
  margin-right: 1rem;
}

.media-item .icon-select .icon-select____control, .prev-session .icon-select .icon-select____control {
  border-radius: 10px;
}

.media-item .icon-button, .prev-session .icon-button {
  margin-left: 10px;
  margin-right: -8px;
}

.pdf-viewer {
  box-sizing: content-box;
  justify-content: center;
  max-height: 83vh;
  display: flex;
  position: relative;
  overflow: auto;
}

.pdf-viewer .react-pdf__Page {
  border-bottom: 2px solid gray;
}

.pdf-viewer .react-pdf__Page:last-child {
  border-bottom: none;
}

.pdf-viewer--loading {
  overflow: hidden;
}

.pdf-iframe {
  border: solid 1px var(--border-colour-1);
  width: 100%;
  height: 100%;
}

.media-feed {
  border: solid 1px var(--border-colour-1);
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  padding: 0;
  display: flex;
  overflow: hidden;
}

.media-feed .bubble-heading {
  margin-bottom: .7rem;
}

.media-feed__content {
  flex-grow: 1;
  width: 100%;
  height: 1px;
  padding: 1.4rem;
  overflow-y: auto;
}

.media-feed .media-feed-item:not(:last-child) {
  margin-bottom: .7rem;
}

.status-bar {
  z-index: 500;
  color: var(--header-text-colour);
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding: 0;
  display: flex;
  position: relative;
}

.status-bar__top {
  background-color: var(--header-colour);
  border-bottom: solid 3px var(--header-border-colour);
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 4rem;
  display: flex;
}

.status-bar__stats {
  text-align: right;
  margin-right: 1.5rem;
  font-size: .9rem;
  transform: translateY(1px);
}

.status-bar__stat-label {
  opacity: .5;
  margin-right: .5rem;
}

.status-bar .icon-button {
  border-radius: 0;
  width: 4rem;
  height: calc(4rem - 3px);
}

.status-bar .icon-button .icon, .status-bar .icon-button .icon-file-outline, .status-bar .icon-button .icon-file {
  color: #fff;
}

.status-bar__logo {
  width: var(--logo-4-width);
  background-image: var(--logo-4);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  height: 100%;
  padding-right: 20px;
}

.status-bar__session-title {
  color: var(--header-border-colour);
  font-weight: 500;
}

.status-bar__session-title, .status-bar__case-title {
  font-size: 1.1rem;
  line-height: 1.5rem;
  overflow: visible;
}

.status-bar__case-title {
  font-weight: bold;
  font-family: var(--heading-font-family);
  flex-grow: 1;
  padding-left: 1rem;
  transform: translateY(2px);
}

.status-bar__translate {
  opacity: .6;
  font-size: 12px;
  line-height: 1.3rem;
}

.status-bar__translate:hover {
  opacity: 1;
}

.status-bar .status-bar-message, .status-bar .previous-session-message {
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: -3px;
}

.status-bar .countdown {
  margin: 0;
}

.video-call {
  background-color: #ebebeb;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video-call__container {
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  height: 0;
  display: flex;
  position: relative;
}

.video-call__main {
  flex-direction: column;
  flex-grow: 1;
  width: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.video-call__participants {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

.video-call__tileview {
  flex-flow: wrap;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: auto;
  margin-left: 7px;
  margin-right: 7px;
  display: flex;
  overflow: hidden;
}

.video-call__tileview-inner {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.video-call__filmstrip {
  background: linear-gradient(to left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 10px 8px 15px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.video-call__filmstrip .video-call-participant {
  width: var(--filmstrip-width);
  max-width: var(--filmstrip-width);
}

.video-call__filmstrip .video-call-participant__display-name, .video-call__filmstrip .video-call-participant__subheading, .video-call__filmstrip .video-call-participant__mute-message {
  line-height: 1rem;
}

.video-call .video-call__mute-message {
  text-align: center;
  color: #fff;
  background-color: #d71a21;
  padding: .7rem .5rem .5rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.video-call__interpreter {
  z-index: 1;
  background-color: rgba(255, 255, 255, .7);
  border: 1px solid #232323;
  border-radius: 10px;
  flex-direction: column;
  width: 240px;
  padding: .4rem;
  display: flex;
  position: absolute;
  bottom: 12px;
  left: 10px;
}

.video-call__interpreter .video-call__interpreter-mute {
  margin-top: .4rem;
  margin-bottom: .4rem;
  display: flex;
}

.video-call__interpreter .video-call__interpreter-mute label {
  text-transform: none;
  flex-grow: 1;
  padding-left: 4px;
  font-size: .9rem;
  line-height: 1.6rem;
}

.video-call__interpreter .video-call-participant__display-name, .video-call__interpreter .video-call-participant__subheading, .video-call__interpreter .video-call-participant__mute-message {
  line-height: 1rem;
}

.video-call__interpreter .icon-button.icon-button {
  text-shadow: none;
  z-index: 1;
  border-width: 1px;
  width: 29px;
  min-width: 29px;
  max-width: 29px;
  height: 29px;
  margin-left: auto;
  font-size: 18px;
  position: absolute;
  top: 12px;
  right: 15px;
}

.video-call__interpreter .icon-button:not(:-webkit-any(.icon-button--selected, :hover, :active)) {
  background-color: #f3f4f7;
  border: 1px solid #4b4a4a;
}

.video-call__interpreter .icon-button:not(:-moz-any(.icon-button--selected, :hover, :active)) {
  background-color: #f3f4f7;
  border: 1px solid #4b4a4a;
}

.video-call__interpreter .icon-button:not(:is(.icon-button--selected, :hover, :active)) {
  background-color: #f3f4f7;
  border: 1px solid #4b4a4a;
}

.video-call.video-call--speaker .video-call__buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.video-call__interpreter-controls {
  background-color: rgba(255, 255, 255, .7);
  border: 1px solid #232323;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: .5rem 0;
  padding: .8rem 1.2rem;
  display: flex;
}

.video-call__interpreter-controls .button {
  margin: .5rem;
}

.video-call__interpreter-controls .button button {
  padding-left: 8px;
  padding-right: 8px;
  width: 160px !important;
  max-width: 160px !important;
}

.video-call .smalltext {
  font-size: .8125rem;
}

.video-call__buttons {
  justify-content: center;
  align-items: flex-end;
  padding: 8px 10px;
  display: flex;
}

.video-call__buttons .left {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 33%;
  min-height: 100%;
  display: flex;
}

.video-call__buttons .left button {
  width: auto;
}

.video-call__buttons .middle {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 34%;
  min-height: 100%;
  display: flex;
}

.video-call__buttons .right {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 33%;
  display: flex;
}

.video-call--agora.video-call--speaker .video-call-participant.video-call-participant--fullscreen {
  right: calc(var(--filmstrip-width)  + 35px);
}

.video-call--agora .video-call__filmstrip {
  min-width: calc(var(--filmstrip-width)  + 35px);
  background-color: #000;
  overflow-y: scroll;
}

.video-call--agora .video-call__filmstrip .video-call__participants {
  overflow-y: visible;
}

.video-call__error {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  display: flex;
}

.side-call {
  max-width: var(--filmstrip-width);
  min-width: var(--filmstrip-width);
  width: var(--filmstrip-width);
  border-left: solid 1px var(--border-colour-1);
  background-color: var(--panel-background-colour);
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
}

.side-call__message {
  justify-content: center;
  align-items: center;
  height: 2rem;
  margin: -2px -4px 0;
  font-weight: bold;
  display: flex;
}

.side-call__message .dot {
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  transform: translate(0, -1px);
}

.side-call__participants {
  flex-grow: 1;
  height: 1px;
  padding-top: 70px;
  overflow: auto;
}

.side-call .video-call-participant {
  cursor: pointer;
}

.side-call .video-call-participant .video-call-participant__inner {
  border-color: #d9d9dd;
}

.side-call .video-call-participant * {
  cursor: pointer;
}

#side-call {
  max-width: var(--filmstrip-width);
  min-width: var(--filmstrip-width);
  width: var(--filmstrip-width);
  border-left: solid 1px var(--border-colour-1);
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  display: flex;
}

#side-call__content {
  flex-grow: 1;
  height: 1px;
}

#main-call {
  flex-direction: column;
  display: flex;
}

.tutor-view #main-call {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.group-view #main-call, #main-call__content {
  flex-grow: 1;
  height: 100%;
}

.main-call__mute-message {
  text-align: center;
  color: #fff;
  background-color: #d71a21;
  padding: .7rem .5rem .5rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.team-badge {
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: auto;
  display: flex;
  position: absolute;
  top: -11px;
  right: 0;
}

.team-badge__circle {
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin: 0 .4rem 0 .7rem;
  font-size: 15px;
  transition: background-color .5s ease-out;
  display: flex;
}

.team-badge__circle .icon, .team-badge__circle .icon-file-outline, .team-badge__circle .icon-file {
  justify-content: center;
  align-items: center;
  display: flex;
}

.team-badge__label {
  color: var(--heading-text-colour);
  font-family: Nunito, Roboto, sans-serif;
  font-size: 15px;
  font-weight: 700;
}

.countdown {
  letter-spacing: 2px;
  box-sizing: border-box;
  background: var(--primary-button-colour);
  border-radius: 0;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100%;
  padding: 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  transition: width .5s cubic-bezier(.19, 1, .22, 1);
  display: flex;
  overflow: hidden;
}

.countdown--paused {
  animation: 1.5s cubic-bezier(.455, .03, .515, .955) infinite countdown__blinker;
}

.countdown--nearzero {
  animation: 1s step-end infinite flashred;
}

.countdown--zero {
  background-color: rgba(0, 0, 0, 0);
  width: 0;
}

@keyframes countdown__blinker {
  50% {
    background: none;
  }
}

@keyframes flashred {
  50% {
    color: #fff;
    background-color: #d71a21;
    border-color: #d71a21;
  }
}

.preloader-dual-ring {
  width: 64px;
  height: 64px;
  margin: auto;
  display: inline-block;
  position: relative;
}

.preloader-dual-ring__ring1, .preloader-dual-ring__ring2 {
  border: 5px solid;
  border-radius: 50%;
  animation: 1s linear infinite lds-dual-ring;
  position: absolute;
}

.preloader-dual-ring__ring1 {
  border-color: var(--accent-colour-1) transparent var(--accent-colour-1) transparent;
  width: 52px;
  height: 52px;
  top: 6px;
  left: 6px;
}

.preloader-dual-ring__ring2 {
  border-color: var(--accent-colour-2) transparent var(--accent-colour-2) transparent;
  width: 32px;
  height: 32px;
  animation-direction: reverse;
  top: 16px;
  left: 16px;
}

.participant, .password {
  border: solid 1px var(--border-colour-1);
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  background-color: #f8f8f8;
  border: 1px solid #fff;
  border-radius: 10px;
  align-items: center;
  min-height: 54px;
  margin: 0 0 .466667rem;
  padding: 0 16px;
  display: flex;
}

.participant *, .password * {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.participant .left, .participant .middle, .password .left, .password .middle {
  margin-right: 1rem;
}

.participant .left, .password .left {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 8rem;
}

.participant .left h2, .password .left h2 {
  letter-spacing: 1px;
  font-variant-numeric: tabular-nums;
  font-size: var(--secondary-font-size);
  font-family: var(--base-font-family);
}

.participant .middle, .password .middle {
  flex-grow: 1;
}

.participant .middle .input.row, .password .middle .input.row {
  min-height: 26px;
  margin: .2rem 0;
}

.participant .middle .input.row input[type="checkbox"] {
  margin-right: 1rem;
}

.password .middle .input.row input[type="checkbox"] {
  margin-right: 1rem;
}

.participant .middle .input.row input[type="text"] {
  flex-grow: 1;
  width: 1px;
  min-width: 8rem;
}

.password .middle .input.row input[type="text"] {
  flex-grow: 1;
  width: 1px;
  min-width: 8rem;
}

.participant .right, .password .right {
  text-align: right;
  opacity: .6;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  display: flex;
  overflow: hidden;
}

.participant .right > div, .password .right > div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.participant--allocated, .password--allocated {
  border: solid 1px var(--border-colour-1);
  background-color: #fff;
}

.participant .icon-button, .password .icon-button {
  flex-shrink: 0;
  margin-left: 10px;
  margin-right: -8px;
}

.participant .video-call-participant__dot, .password .video-call-participant__dot {
  width: 12px;
  height: 12px;
  margin-left: 5px;
  transform: none;
}

.participant {
  background-color: #fff;
}

.textarea {
  flex-direction: column;
  margin-top: 4px;
  margin-bottom: 8px;
  display: flex;
  position: relative;
}

.textarea textarea {
  resize: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.textarea .textarea__resizer, .textarea textarea {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin: 0;
  padding: 8px 10px;
  line-height: normal;
}

.textarea .textarea__resizer {
  opacity: 0;
  border: 1px solid rgba(0, 0, 0, 0);
}

.textarea-with-label {
  position: relative;
}

.textarea-with-label label {
  z-index: 1;
  height: calc(var(--base-font-size)  + 16px);
  line-height: calc(var(--base-font-size)  + 16px);
  text-transform: none;
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  letter-spacing: normal;
  background: none;
  border-radius: 99px;
  padding-left: 10px;
  padding-right: 10px;
  transition: top .1s cubic-bezier(.19, 1, .22, 1), padding .1s cubic-bezier(.19, 1, .22, 1);
  position: absolute;
  top: 1px;
  left: 0;
  font-weight: normal !important;
}

.textarea-with-label.textarea-with-label--focus label {
  background: #fff;
  top: -34px;
}

.message {
  background-color: #fff;
  border: 1px solid #d9d9dd;
  border-radius: 10px;
  flex-flow: wrap;
  min-height: 47px;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  overflow: hidden;
}

.message .icon, .message .icon-file-outline, .message .icon-file, .message .circle {
  color: #fff;
  background-color: var(--header-colour);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  margin: 0;
  display: flex;
}

.message .icon.fa-user, .message .fa-user.icon-file-outline, .message .fa-user.icon-file {
  color: var(--base-text-colour);
  background-color: #d9d9dd;
}

.message__private-details {
  background: var(--accent-colour-1);
  color: #fff;
  border-radius: 0;
  width: calc(100% + 20px);
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
  padding: 2px 0 1px 15px;
  font-size: 14px;
}

.message__left {
  padding: 10px 10px 9px 0;
}

.message__right {
  flex-direction: column;
  flex-grow: 1;
  width: 1px;
  padding-top: 3px;
  padding-bottom: 5px;
  padding-right: 1px;
}

.message__top {
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  margin-bottom: 2px;
  padding-top: 3px;
  display: flex;
}

.message__from {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: .5rem;
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
}

.message__name {
  opacity: .6;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: .5rem;
  font-size: .8125rem;
  font-weight: normal;
  line-height: .8125rem;
  overflow: hidden;
}

.message__at {
  opacity: .6;
  text-align: right;
  flex-grow: 1;
  flex-shrink: 0;
  margin-left: .5rem;
  font-size: .8125rem;
  line-height: .8125rem;
}

.message__text {
  word-break: break-word;
  white-space: pre-wrap;
  margin-top: 5px;
  font-size: 1rem;
  line-height: 1.3rem;
  display: inline;
}

.message__translate {
  float: right;
  opacity: .8;
  text-align: right;
  cursor: pointer;
  flex-shrink: 0;
  margin-top: 0;
  font-size: .8125rem;
  line-height: 1.3rem;
  transition: color .2s;
  display: inline;
  color: var(--accent-colour-1) !important;
}

.message__translate * {
  cursor: pointer;
}

.message__translate:hover, .message__translate--active {
  opacity: 1;
}

.message--private {
  border: solid 2px var(--accent-colour-1);
}

.notepad {
  background-color: var(--panel-background-colour);
  border: solid 1px var(--border-colour-1);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: column;
  height: 100%;
  min-height: 200px;
  padding: 30px 30px 20px;
  display: flex;
}

.notepad > .row:first-child {
  justify-content: flex-end;
}

.notepad__buttons {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.notepad .textarea {
  flex-grow: 1;
  height: 1px;
  margin-top: 10px;
}

.notepad > .row:first-child {
  width: 100%;
}

.section {
  border: solid 1px var(--border-colour-1);
  background-color: #fff;
  border-radius: 10px;
  margin: 0 0 1rem;
  padding: 16px 20px 12px;
  transition: background-color .5s, color .5s;
  position: relative;
}

.section > h2 {
  margin-top: .6rem;
  margin-bottom: 1.6rem;
}

.section > p {
  padding-left: 2px;
}

.section .button {
  width: auto;
  margin-left: 1rem;
}

.section label {
  margin-bottom: .5rem;
}

.section h2 {
  transition: color .5s;
}

.section--disabled {
  opacity: .5;
  pointer-events: none;
}

.section--disabled select, .section--disabled textarea, .section--disabled input {
  color: #4b4a4a;
  background-color: rgba(0, 0, 0, 0);
}

.section--disabled button, .section--disabled .pill {
  opacity: .8;
}

.sidebar {
  background-color: var(--sidebar-colour);
  flex-direction: column;
  display: flex;
}

.sidebar__top, .sidebar__bottom {
  flex-direction: column;
  display: flex;
}

.sidebar__top {
  flex-grow: 1;
}

.sidebar__label {
  cursor: pointer;
  white-space: nowrap;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
  background-color: #fff;
  align-items: center;
  height: 60px;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.2rem;
  display: flex;
  position: absolute;
  top: 0;
  left: -1000px;
  transform: translateX(0);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .1);
}

.sidebar__button {
  cursor: pointer;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  display: flex;
  position: relative;
}

.no-touch .sidebar__button:hover .sidebar__label {
  display: flex;
  left: 60px;
}

.sidebar__button:focus .icon-button:not(.icon-button--selected) {
  background-color: var(--accent-colour-2);
}

.sidebar:hover .sidebar__label {
  opacity: 1;
  animation: .8s showthis;
}

@keyframes showthis {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  75% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.sidebar .icon-button {
  z-index: 2;
  border-radius: 0;
  width: 100%;
  height: 100%;
  font-size: 22px;
}

.sidebar .icon-button .icon, .sidebar .icon-button .icon-file-outline, .sidebar .icon-button .icon-file {
  color: #fff;
  fill: #fff;
}

.sidebar .sidebar__button.return_to_current:not(:hover) .icon, .sidebar .sidebar__button.return_to_current:not(:hover) .icon-file-outline, .sidebar .sidebar__button.return_to_current:not(:hover) .icon-file {
  color: var(--accent-colour-2);
}

.alert__button, .button button {
  font-family: var(--base-font-family);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: var(--button-font-size);
  border-radius: var(--button-border-radius);
  color: var(--button-text-colour);
  background: var(--button-colour);
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 200px;
  width: 100%;
  margin: 4px 0;
  padding: 10px 24px 7px;
  font-weight: 800;
  line-height: 1.1rem;
  transition: background-color .5s, color .5s;
  display: flex;
  position: relative;
}

.alert__button *, .button button * {
  cursor: pointer;
}

.alert__button:hover:not(:disabled), .button button:hover:not(:disabled), .alert__button:focus:not(:disabled), .button button:focus:not(:disabled) {
  background: var(--button-hover-colour);
  transition: none;
}

.alert__button .button__text, .button button .button__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  overflow: hidden;
}

.button .icon, .button .icon-file-outline, .button .icon-file {
  opacity: .6;
  flex-shrink: 0;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 4px;
  display: block;
  position: relative;
  left: -3px;
  transform: translateY(1px);
}

.button:active:not(.button--disabled) button {
  box-shadow: none;
  transform: translateY(2px);
}

.button--secondary button {
  color: #fff;
  background: #707070;
}

.button--secondary button:hover:not(:disabled), .button--secondary button:focus:not(:disabled) {
  background: #707070;
}

.button--primary button {
  color: var(--primary-button-text-colour);
  background: var(--primary-button-colour);
}

.button--primary button:hover:not(:disabled), .button--primary button:focus:not(:disabled) {
  background: var(--primary-button-hover-colour);
}

.section--disabled .button, .button--disabled, .button--disabled:active {
  cursor: default;
}

.section--disabled .button button, .button--disabled button, .button--disabled:active button {
  cursor: default;
  color: #707070;
  background: #efefef;
  border-color: #707070;
}

.section--disabled .button button:hover, .button--disabled button:hover, .button--disabled:active button:hover {
  background: #efefef;
}

.section--disabled .button .icon, .section--disabled .button .icon-file-outline, .section--disabled .button .icon-file, .button--disabled .icon, .button--disabled .icon-file-outline, .button--disabled .icon-file, .button--disabled:active .icon {
  color: #707070;
}

.section--disabled .button *, .button--disabled *, .button--disabled:active * {
  cursor: default !important;
}

button::-moz-focus-inner {
  border: 0;
}

.participant-view .button button {
  border-radius: 5px;
  padding-left: 18px;
  padding-right: 18px;
}

input[type="range"] {
  -webkit-appearance: none;
  background: none;
  width: 100%;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  background: #fff;
  border-radius: 99px;
  width: 12px;
  height: 12px;
  margin-top: -4px;
}

input[type="range"]:active::-webkit-slider-thumb {
  background: var(--accent-colour-2);
}

input[type="range"]::-moz-range-thumb {
  cursor: pointer;
  background: #fff;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}

input[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  background: var(--accent-colour-1);
  border: .2px solid #010101;
  border-radius: 99px;
  width: 100%;
  height: 6px;
}

input[type="range"]::-moz-range-track {
  cursor: pointer;
  background: var(--accent-colour-1);
  border: .2px solid #010101;
  border-radius: 99px;
  width: 100%;
  height: 6px;
}

input[type="range"]::-webkit-slider-fill {
  background-color: orange;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.react-switch svg {
  opacity: .25;
}

.alert__title {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.alert__button {
  width: 10rem !important;
  margin: .4rem 1rem !important;
}

.alert__button--cancel {
  background-color: #696969 !important;
}

.alert__button--cancel:hover {
  background-color: #888 !important;
}

.alert__title.alert__title {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.alert__toast-icon, body .swal2-popup .alert__toast-title, body .swal2-popup .alert__toast-content {
  color: #fff;
}

body .swal2-popup .alert__toast-icon.alert__toast-icon {
  color: rgba(255, 255, 255, .8);
  border-color: rgba(255, 255, 255, .4);
}

body .swal2-popup.swal2-toast.alert__toast {
  box-shadow: none;
  background-color: #d71a21;
  padding: .7rem 1rem;
}

body .swal2-popup .swal2-icon.swal2-warning {
  border-color: var(--accent-colour-2);
  color: var(--accent-colour-2);
}

body .swal2-popup.swal2-modal {
  border-radius: 10px;
}

body .swal2-popup .swal2-content {
  opacity: .7;
  margin-top: .7rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

body .swal2-popup.swal2-toast {
  margin-bottom: 1rem;
  padding: .2rem 1rem;
}

body .swal2-popup.swal2-toast .swal2-content {
  margin-top: 0;
}

body .swal2-popup.swal2-toast .alert__button {
  width: auto !important;
  margin-right: 0 !important;
}

body .swal2-popup.swal2-toast .swal2-icon.swal2-info .swal2-icon-content {
  transform: translate(0, 2px);
}

body .swal2-popup.swal2-toast .swal2-icon {
  zoom: .75;
}

body .swal2-container.swal2-bottom {
  overflow: hidden !important;
}

body .swal2-container.swal2-backdrop-show, body .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, .6);
}

@keyframes alert__show {
  0% {
    transform: translateY(-10em);
  }

  33% {
    transform: translateY(0);
  }

  66% {
    transform: translateY(.3125em);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes alert__hide {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10em);
  }
}

.alert__show {
  animation: .3s cubic-bezier(.215, .61, .355, 1) alert__show;
}

.alert__hide {
  animation: .5s cubic-bezier(.215, .61, .355, 1) alert__hide;
}

@keyframes alert__show-bottom {
  0% {
    transform: translateY(10em);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes alert__hide-bottom {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10em);
  }
}

.alert__show-bottom {
  animation: .3s cubic-bezier(.215, .61, .355, 1) alert__show-bottom;
}

.alert__hide-bottom {
  animation: .3s cubic-bezier(.215, .61, .355, 1) alert__hide-bottom;
}

.error {
  color: #fff;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #d71a21;
  border-radius: 10px;
  max-width: 100%;
  padding: 10px 50px;
  font-size: 17px;
  overflow: hidden;
}

.modal {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  flex-direction: column;
  min-width: 400px;
  max-width: 99%;
  max-height: 99%;
  padding: 12px 16px;
  display: flex;
  position: relative;
  box-shadow: 0 25px 25px -15px rgba(0, 0, 0, .3);
}

.modal img {
  object-fit: contain;
  object-position: center;
  max-width: 100%;
  max-height: calc(100vh - 100px);
}

.modal audio {
  min-width: 400px;
}

.modal video {
  object-position: center;
  max-width: 100%;
  max-height: calc(100vh - 100px);
}

.modal__content {
  max-height: 87vh;
  display: flex;
}

.modal__content > * {
  width: 100%;
}

.modal__content .preloader {
  margin-top: 24px;
  margin-bottom: 24px;
}

.modal__header {
  background-color: var(--panel-background-colour);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex-direction: row;
  flex-shrink: 0;
  margin: -12px -16px 12px;
  padding: 7px 7px 5px 21px;
  display: flex;
}

.modal__header .icon-button {
  width: 32px;
  height: 32px;
  margin: 0 0 0 24px;
  padding: 6px;
}

.modal__header .icon-button .icon, .modal__header .icon-button .icon-file-outline, .modal__header .icon-button .icon-file {
  color: #000;
  font-size: 18px;
}

.modal__header .icon-button:hover .icon, .modal__header .icon-button:hover .icon-file-outline, .modal__header .icon-button:hover .icon-file {
  color: #fff;
}

.modal__title {
  flex-grow: 1;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  display: flex;
}

.modal__container {
  z-index: 5;
  opacity: 1;
  background-color: rgba(0, 0, 0, .6);
  justify-content: center;
  align-items: center;
  animation: .4s cubic-bezier(.075, .82, .165, 1) fadeInAnim;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-enter {
  opacity: .01;
}

.modal-enter .modal {
  top: 2000px;
}

.modal-enter.modal-enter-active {
  opacity: 1;
  transition: opacity .3s ease-in;
}

.modal-enter.modal-enter-active .modal {
  transition: top .3s ease-out;
  top: 0;
}

.modal-leave {
  opacity: 1;
}

.modal-leave .modal {
  top: 0;
}

.modal-leave.modal-leave-active {
  opacity: .01;
  transition: opacity .3s ease-in;
}

.modal-leave.modal-leave-active .modal {
  transition: top .3s ease-in;
  top: 2000px;
}

.media-modal {
  flex-grow: 1;
  justify-content: center;
  display: flex;
}

.case {
  border: solid 1px var(--border-colour-1);
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  margin: 0 0 .466667rem;
  padding: .8rem 20px;
  display: flex;
}

.case__top {
  flex-direction: row;
  display: flex;
}

.case__title {
  flex: auto;
  font-size: 19px;
}

.case__left {
  flex-grow: 1;
}

.case__left h2 {
  margin-top: .5rem;
  margin-bottom: 1rem;
}

.case__right {
  width: 9rem;
}

.case__sessions {
  font-weight: bold;
}

.case__sessionslabel {
  font-weight: normal;
}

.case .case__bottom {
  flex-direction: row;
  display: flex;
}

.case .case__label {
  color: gray;
  margin-top: 10px;
  margin-bottom: 4px;
  font-size: 90%;
}

.case .case__bottomleft {
  flex-flow: wrap;
  display: flex;
}

.case .case__bottomleft .case__label {
  min-width: 50%;
  margin-top: 0;
}

.case .case__bottomleft .case__value {
  min-width: 50%;
}

.case__buttons {
  flex-direction: row-reverse;
  flex: 1;
  align-items: center;
  display: flex;
}

.case__bottom {
  margin-top: 16px;
}

.icon, .icon.fa-toggle-off, .fa-toggle-off.icon-file, .fa-toggle-off.icon-file-outline, .icon-file-outline, .icon-file {
  color: #fff;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.icon svg, .icon.fa-toggle-off svg, .fa-toggle-off.icon-file svg, .fa-toggle-off.icon-file-outline svg, .icon-file-outline svg, .icon-file svg {
  width: 100%;
  height: 100%;
}

.icon.fa-toggle-off, .fa-toggle-off.icon-file, .fa-toggle-off.icon-file-outline {
  transform: rotate(180deg);
}

.info {
  border: solid 1px var(--border-colour-1);
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #4b4a4a;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 0 1rem;
  padding: .6rem 20px;
  font-size: 1.15rem;
  line-height: 1.7rem;
}

.info.info--warning {
  background-color: var(--primary-button-colour);
  color: #fff;
}

.pill {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, .95);
  background-color: #555;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: .8125rem;
  line-height: 1.5rem;
  transition: background-color .3s;
  display: flex;
}

.tab__row {
  display: flex;
  transform: translateY(1px);
}

.tab {
  border: solid 1px var(--border-colour-1);
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: .6rem;
  padding: 2px 2rem 0;
  transition: border-color .3s, background-color .3s;
}

.tab * {
  cursor: pointer;
}

.tab h3 {
  margin: 0;
  font-family: Lato, Calibri, sans-serif;
  line-height: 2rem !important;
}

.tab--inactive {
  background-color: #fff;
  border-bottom: none;
  transform: translateY(-1px);
}

.tab--inactive:hover {
  border-top-color: var(--button-colour);
  border-right-color: var(--button-colour);
  border-left-color: var(--button-colour);
  background-color: var(--button-colour);
  transition: none;
}

.tab--inactive:hover h3 {
  color: #fff;
}

.tab--active {
  border: solid 1px var(--primary-button-colour);
  background-color: var(--primary-button-colour);
}

.tab--active h3 {
  color: #fff !important;
}

.tab__content--active {
  border-top-left-radius: 0;
}

.tab__content--inactive {
  display: none;
}

.tabs-container {
  flex-grow: 1;
}

.icon-test {
  text-align: center;
}

.icon-test .icon, .icon-test .icon-file, .icon-test .icon-file-outline {
  border-radius: 10px;
  width: 64px;
  height: 64px;
  margin: 1rem;
  font-size: 32px;
}

.tutor-linked-access-passwords {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  display: flex;
}

.tutor-linked-access-passwords__top {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
}

.tutor-linked-access-passwords__content {
  min-height: 72px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;
}

.initial-information-page {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  display: flex;
}

.initial-information-page__top {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
}

.initial-information-page__content {
  min-height: 72px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;
}

.initial-information-page .initial-information__text-wrapper {
  max-height: none;
}

.tutor-participants {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  display: flex;
}

.tutor-participants__top {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
}

.tutor-participants__content {
  min-height: 72px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;
}

.tutor-participants__bottom {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tutor-prev-sessions {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  display: flex;
}

.tutor-prev-sessions__top {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
}

.tutor-prev-sessions__content {
  min-height: 72px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;
}

.tutor-prev-sessions .prev-session {
  flex-direction: row;
}

.tutor-prev-sessions .prev-session .left {
  flex-grow: 1;
}

.tutor-prev-sessions .prev-session .right {
  flex-flow: wrap;
  justify-content: flex-end;
  max-width: 50%;
}

.tutor-prev-sessions .prev-session .right .button button {
  margin: 0;
}

.tutor-passwords {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  display: flex;
}

.tutor-passwords__top {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
}

.tutor-passwords__content {
  min-height: 72px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;
}

.tutor-videoconf {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  display: flex;
}

.tutor-videoconf__top {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
}

.tutor-videoconf__content {
  min-height: 72px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;
}

.tutor-videoconf .section .pill, .tutor-videoconf .section .switch {
  top: 1.2rem;
  right: 1.5rem;
  position: absolute !important;
}

.tutor-videoconf .section .pill {
  top: 1.3rem;
}

.tutor-videoconf .section .row {
  justify-content: flex-end;
}

.tutor-videoconf__pill--online {
  background-color: var(--team-colour-green);
}

.tutor-videoconf__pill--starting, .tutor-videoconf__pill--stopping {
  background-color: var(--team-colour-orange);
}

.tutor-videoconf__pill--enabled {
  background-color: var(--team-colour-green);
}

.tutor-sessions {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  display: flex;
}

.tutor-sessions__top {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
}

.tutor-sessions__content {
  min-height: 72px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;
}

.main-content-message {
  white-space: pre-wrap;
  text-align: center;
  background-color: #efefef;
  border-radius: 10px;
  margin: 32px;
  padding: 16px 32px;
  line-height: 24px;
}

.tutor-settings {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  display: flex;
}

.tutor-settings__top {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
}

.tutor-settings__content {
  min-height: 72px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;
}

.tutor-settings .section > .row {
  justify-content: space-between;
  align-items: center;
}

.tutor-settings .section h2 {
  margin: 0;
  line-height: 2rem;
}

.tutor-settings .textarea {
  width: 100%;
  min-height: 4rem;
  margin-top: 1.4rem;
}

.tutor-settings .warningSection .input {
  flex-grow: 1;
  margin: 0 0 8px;
}

.tutor-settings .warningSection .textarea {
  margin-top: 0;
}

.tutor-settings .warningSection label {
  text-align: right;
  flex-grow: 1;
  margin-bottom: 0;
  padding-right: .5rem;
}

.tutor-settings .label-paragraph-switch-block {
  padding-top: 1rem;
  padding-bottom: .4rem;
}

.tutor-settings .label-paragraph-switch-block p {
  margin-top: 5px;
  margin-bottom: 0;
}

.tutor-groups {
  flex-direction: row;
  width: 100%;
  padding: .1rem .3rem 1rem;
  display: flex;
}

.tutor-groups .messages-panel__message-list-container, .tutor-groups .groups-list, .tutor-groups .decision-log__scroller, .tutor-groups .media-feed {
  background: #f8f8f8;
}

.in-call .tutor-groups {
  background-color: rgba(0, 0, 0, 0);
}

.in-call .tutor-groups .messages-panel__message-list-container, .in-call .tutor-groups .groups-list, .in-call .tutor-groups .decision-log__scroller, .in-call .tutor-groups .media-feed {
  background: rgba(255, 255, 255, .8);
}

.tutor-groups__col {
  flex-direction: column;
  align-items: center;
  padding-left: .5rem;
  padding-right: .5rem;
  display: flex;
}

.tutor-groups__col:first-child {
  flex-grow: 1;
  flex-shrink: 0;
  width: 220px;
  min-width: 220px;
  max-width: 220px;
}

.tutor-groups__col:nth-child(2) {
  flex-grow: 1;
  min-width: 200px;
}

.tutor-groups__col:nth-child(3) {
  flex-grow: 1;
  width: 250px;
  max-width: 350px;
}

.tutor-groups__col:nth-child(4) {
  flex-grow: 1;
  width: 250px;
  min-width: 250px;
  max-width: 350px;
}

.tutor-groups .groups-list, .tutor-groups .decision-log, .tutor-groups .media-feed {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.tutor-groups .bubble-heading {
  z-index: 1;
  height: 42px;
  margin-bottom: 0;
  padding: 3px 0 0;
  font-size: 18px;
  line-height: 38px;
  transform: translateY(0);
}

.tutor-groups .groups-list, .tutor-groups .decision-log__scroller, .tutor-groups .messages-panel__message-list-container, .tutor-groups .media-feed__content {
  padding-top: 1.15rem;
}

.tutor-groups .groups-list, .tutor-groups .decision-log__scroller, .tutor-groups .media-feed__content, .tutor-groups .message-list {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tutor-groups .decision-log {
  flex-grow: 1;
}

.tutor-groups .decision-log .decision-log__inner {
  padding: 0;
}

.tutor-groups .decision-log__scroller {
  border: solid 1px var(--border-colour-1);
  border-radius: 10px;
  padding-bottom: 1rem;
}

.tutor-groups .media-feed {
  border: solid 1px var(--border-colour-1);
  height: 100%;
  max-height: 100%;
}

.tutor-groups .media-item, .tutor-groups .prev-session, .tutor-groups .media-item *, .tutor-groups .prev-session * {
  cursor: pointer;
}

.tutor-groups .messages-panel {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.tutor-groups .messages-panel .messages-panel__message-list-container {
  border: solid 1px var(--border-colour-1);
  padding: 0;
}

.tutor-groups .messages-panel .message-list {
  padding: 1.15rem 1rem 1rem;
}

.login-group__main {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: auto;
  display: flex;
}

.login-group__main span {
  text-align: center;
  margin-bottom: 20px;
}

.tutor-cases {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  display: flex;
}

.tutor-cases__top {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
}

.tutor-cases__content {
  min-height: 72px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;
}

.tutor-media {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-width: 600px;
  max-width: 800px;
  display: flex;
}

.tutor-media__top {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
}

.tutor-media__content {
  flex-grow: 1;
  min-height: 72px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;
}

.tutor-view {
  background-color: #ebebeb;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tutor-view .sidebar {
  z-index: 3;
}

.tutor-view .side-call {
  background-color: #fff;
}

.tutor-view__main {
  flex-direction: row;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.tutor-view__content, .tutor-view__content-overlay, .tutor-view__content-wrapper {
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.tutor-view__content {
  position: relative;
  overflow: auto;
}

.tutor-view__content > :first-child {
  z-index: 2;
}

.tutor-view__content-overlay {
  z-index: 1;
  background-color: rgba(235, 235, 235, .8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tutor-view__casedetails {
  flex-direction: row;
  flex-shrink: 0;
  display: flex;
  overflow: hidden;
}

.tutor-view__casedetails .session-clock {
  flex-grow: 1;
}

.tutor-view .button button {
  font-weight: bold;
}

.login {
  background: #e3e6e8;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.login h1 {
  font-family: var(--login-font-family);
  color: #f09;
  margin: 2rem 0;
  font-size: 3.5rem;
  font-weight: 500;
}

.login h2 {
  font-family: var(--login-font-family);
  color: #1b1464;
  margin: 0 0 3rem;
  font-size: 1.3rem;
  font-weight: normal;
}

.login span.icon, .login span.icon-file, .login span.icon-file-outline {
  margin: 0;
}

.login select:focus, .login input:focus {
  border-color: var(--login-button-colour);
}

.login__inner {
  background: var(--login-background-colour);
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.login__white-bit {
  width: 66%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.login__main-logo {
  width: var(--login-logo-width);
  background-image: var(--logo-1);
  transform: var(--login-logo-transform);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  display: flex;
}

.login__left {
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: flex-end;
  width: 33.33%;
  height: 100%;
  display: flex;
}

.login__right {
  flex-direction: column;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  width: 320px;
  margin-left: 33.33%;
  padding-top: 165px;
  display: flex;
  position: relative;
}

.login__buttons {
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  display: flex;
}

.login__main {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: flex;
}

.login__content {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 420px;
  font-size: 1.3rem;
  display: flex;
  position: relative;
}

.login__tutor {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  display: flex;
}

.login__tutor .login__main span.label {
  margin-bottom: 0;
}

.login__input-row {
  align-items: center;
  display: flex;
}

.login__input-row .input {
  flex-grow: 1;
  margin: 8px;
}

.login__input-row .icon, .login__input-row .icon-file, .login__input-row .icon-file-outline {
  opacity: .5;
  fill: var(--login-text-colour);
  color: var(--login-text-colour);
  width: 20px;
  height: 20px;
  margin-right: 16px;
  position: absolute;
  left: -25px;
}

.login__error-space {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 58px;
  display: flex;
  overflow: visible;
}

.login__error-space .error {
  min-width: calc(100% - 14px);
  max-width: none;
  position: absolute;
}

.login__otherbuttons {
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  font-size: 18px;
  display: flex;
}

.login__otherbuttons .button {
  flex-grow: 1;
  width: 50%;
  margin: 4px;
}

.login__otherbuttons .button.button--secondary button:not(:hover):not(:focus) {
  background-color: #888;
}

.login__overlay {
  background-color: rgba(255, 255, 255, .8);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.login__version {
  text-transform: uppercase;
  opacity: .7;
  letter-spacing: 1px;
  font-size: .85rem;
  position: absolute;
  bottom: 7px;
  right: 14px;
}

.login .language-selector {
  max-width: 100%;
}

.login .language-selector__option--focused, .login .language-selector__option:hover {
  background-color: var(--login-button-colour);
  color: #fff;
}

.login .login__content {
  opacity: 1;
  transition: opacity .3s;
}

.login--busy .login__content {
  opacity: .2;
  cursor: wait;
  pointer-events: none;
}

.login__fadeIn-enter {
  opacity: 0;
}

.login__fadeIn-enter-active {
  opacity: 1;
  transition: opacity .3s cubic-bezier(.165, .84, .44, 1);
}

.login__fadeIn-enter-done, .login__fadeIn-exit {
  opacity: 1;
}

.login__fadeIn-exit-active {
  opacity: 0;
  transition: opacity .3s cubic-bezier(.165, .84, .44, 1);
}

.login__fadeIn-exit-done {
  opacity: 0;
}

.login__slideinfromright-enter {
  opacity: 0;
  transform: translateX(200px);
}

.login__slideinfromright-enter-active {
  opacity: 1;
  transition: opacity .3s cubic-bezier(.165, .84, .44, 1), transform .3s cubic-bezier(.165, .84, .44, 1);
  transform: translateX(0);
}

.login__slideinfromright-enter-done, .login__slideinfromright-exit {
  opacity: 1;
  transform: translateX(0);
}

.login__slideinfromright-exit-active {
  opacity: 0;
  transition: opacity .3s cubic-bezier(.165, .84, .44, 1), transform .3s cubic-bezier(.165, .84, .44, 1);
  transform: translateX(200px);
}

.login__slideinfromright-exit-done {
  opacity: 0;
  transform: translateX(200px);
}

.login__slideinfromright-enter, .login__slideinfromright-enter-active, .login__slideinfromright-enter-done, .login__slideinfromright-exit, .login__slideinfromright-exit-active, .login__slideinfromright-exit-done, .login__slideinfromright-appear, .login__slideinfromright-appear-active, .login__slideinfromright-appear-done {
  width: 100%;
  position: absolute;
}

.login__slideinfromleft-enter {
  opacity: 0;
  transform: translateX(-200px);
}

.login__slideinfromleft-enter-active {
  opacity: 1;
  transition: opacity .3s cubic-bezier(.165, .84, .44, 1), transform .3s cubic-bezier(.165, .84, .44, 1);
  transform: translateX(0);
}

.login__slideinfromleft-enter-done, .login__slideinfromleft-exit {
  opacity: 1;
  transform: translateX(0);
}

.login__slideinfromleft-exit-active {
  opacity: 0;
  transition: opacity .3s cubic-bezier(.165, .84, .44, 1), transform .3s cubic-bezier(.165, .84, .44, 1);
  transform: translateX(-200px);
}

.login__slideinfromleft-exit-done {
  opacity: 0;
  transform: translateX(-200px);
}

.login__slideinfromleft-enter, .login__slideinfromleft-enter-active, .login__slideinfromleft-enter-done, .login__slideinfromleft-exit, .login__slideinfromleft-exit-active, .login__slideinfromleft-exit-done, .login__slideinfromleft-appear, .login__slideinfromleft-appear-active, .login__slideinfromleft-appear-done {
  width: 100%;
  height: 100%;
  position: absolute;
}

.app {
  flex-direction: row;
  align-items: stretch;
  margin: 0 auto;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.participant-decision-log {
  background-color: var(--panel-background-colour);
  border: solid 1px var(--border-colour-1);
  border-radius: 0 10px 10px;
  flex-direction: column;
  height: 100%;
  padding: 7px 24px 0;
  display: flex;
}

.participant-decision-log h3:first-child {
  width: calc(50% + 5px);
}

.participant-decision-log .decision-log .decision-log__scroller {
  flex-grow: 1;
  margin-bottom: 4px;
  margin-right: -10px;
  padding-right: 10px;
}

.participant-decision-log .decision-log .decision-log__header {
  justify-content: flex-start;
}

.participant-decision-log .decision-log .decision-log__advice textarea, .participant-decision-log .decision-log .decision-log__rationale textarea, .participant-decision-log .decision-log .decision-log__advice .resizer, .participant-decision-log .decision-log .decision-log__rationale .resizer, .participant-decision-log .decision-log .decision-log__box {
  letter-spacing: 0;
  text-align: left;
  border-radius: 5px;
  padding: 5px 8px 7px;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: calc(1rem + 3px);
}

.participant-decision-log .decision-log .decision-log__inner {
  padding: 0;
}

.participant-decision-log .decision-log .decision-log__metadata {
  font-size: var(--small-font-size);
  letter-spacing: .05em;
  text-align: right;
  opacity: 1;
  margin-top: -1px;
  margin-bottom: 7px;
  font-style: normal;
  font-weight: 400;
}

.participant-decision-log .decision-log .decision-log__metadata:last-child {
  margin-bottom: 0;
}

.participant-decision-log .decision-log .decision-log__auth-and-time {
  opacity: .6;
}

.participant-decision-log .decision-log .decision-log__translate {
  cursor: pointer;
  color: var(--primary-button-colour) !important;
}

.participant-decision-log .decision-log .decision-log__translate:hover {
  color: var(--primary-button-hover-colour) !important;
}

.participant-decision-log .decision-log .decision-log__delete {
  color: var(--primary-button-colour);
  cursor: pointer;
}

.participant-decision-log .decision-log .decision-log__delete:hover {
  color: var(--primary-button-hover-colour);
}

.participant-decision-log--nolist {
  height: auto;
}

.participant-decision-log--nolist > h2:first-child, .participant-decision-log--nolist .decision-log {
  display: none;
}

.participant-top-buttons {
  align-items: center;
  display: flex;
}

.participant-top-buttons .button {
  margin-right: 8px;
}

.participant-header-bar {
  background-color: var(--panel-background-colour);
  width: 100%;
  height: 68px;
  margin-top: -4px;
  transition: background-color 1s cubic-bezier(.19, 1, .22, 1);
  display: flex;
}

.participant-header-bar * {
  white-space: nowrap;
  overflow: hidden;
}

.participant-header-bar__logo {
  background-image: var(--logo-5);
  width: var(--logo-5-width);
  margin-left: var(--logo-5-margin);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  height: 100%;
  margin-right: 10px;
}

.participant-header-bar__titles {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  display: flex;
}

.participant-header-bar h1 {
  margin: 9px 0 1px;
  text-transform: capitalize !important;
}

.participant-header-bar h2 {
  font-family: var(--heading-font-family);
  letter-spacing: 0;
  text-align: left;
  margin: 0 12px 0 0;
  font-weight: 700;
  line-height: 20px;
  text-transform: none !important;
}

.participant-header-bar__team-name {
  color: var(--darkgrey);
  font-family: var(--heading-font-family);
  text-align: left;
  align-items: center;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  text-transform: none !important;
}

.participant-header-bar__team-name .group-colour-dot {
  width: 17px;
  height: 17px;
  margin-right: 8px;
  transform: translateY(-1px);
}

.participant-header-bar__team-name .group-colour-dot .icon, .participant-header-bar__team-name .group-colour-dot .icon-file, .participant-header-bar__team-name .group-colour-dot .icon-file-outline {
  display: none;
}

.participant-header-bar__translate {
  line-height: 2rem;
  font-size: var(--small-font-size);
  opacity: .6;
  margin-top: 9px;
  margin-left: 16px;
}

.participant-header-bar__translate:hover {
  opacity: 1;
}

.participant-header-bar .button {
  transform: translateY(1px);
}

.dark-theme .participant-header-bar {
  background-color: var(--dark-mode-background-colour);
}

.dark-theme .participant-header-bar .participant-header-bar__logo {
  background-image: var(--logo-6);
}

.dark-theme .participant-header-bar h1 {
  color: #fefefe;
  opacity: .9;
}

.dark-theme .participant-header-bar .participant-header-bar__team-name {
  color: #6c6a73;
}

.virtual-phone-clock {
  opacity: .9;
  width: 100%;
  height: 98px;
  padding-left: calc(50% - 92.5px);
  padding-right: calc(50% - 92.5px);
  font-size: 1rem;
  position: absolute;
  top: 1px;
}

.virtual-phone-clock__time {
  letter-spacing: 0;
  text-align: center;
  color: #4b4a4a;
  font-size: 2.1em;
  font-style: normal;
  font-weight: 300;
  position: absolute;
  top: .5em;
  left: 0;
  right: 0;
}

.virtual-phone-clock svg {
  overflow: visible;
}

.virtual-phone-clock--paused .virtual-phone-clock__time {
  animation: 1.3s cubic-bezier(.455, .03, .515, .955) infinite blinker;
}

.virtual-phone-clock--nearzero .virtual-phone-clock__time {
  animation: 1s step-end infinite flashred;
}

.virtual-phone-clock--zero .virtual-phone-clock__time {
  opacity: .8;
}

@keyframes blinker {
  50% {
    opacity: .1;
  }
}

@keyframes flashred {
  50% {
    color: var(--red);
  }
}

.virtual-phone-apps {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #000;
  justify-content: center;
  width: 100%;
  padding: 10px 35px 14px;
  display: grid;
}

.virtual-phone-apps__app-container {
  width: 50px;
  padding-top: 50px;
  position: relative;
}

.virtual-phone-apps__app {
  background-color: rgba(255, 255, 255, .06);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.virtual-phone-apps__app .virtual-phone-apps__app-overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, .3) 0%, rgba(0, 0, 0, 0) 80%, rgba(255, 255, 255, .15) 100%);
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.virtual-phone-apps__app:hover .virtual-phone-apps__app-overlay {
  background: rgba(255, 255, 255, .2);
}

.virtual-phone-apps__app * {
  cursor: pointer;
}

.virtual-phone-apps__app .icon, .virtual-phone-apps__app .icon-file, .virtual-phone-apps__app .icon-file-outline {
  width: 28px;
  height: 28px;
  font-size: 28px;
}

.virtual-phone-apps__app .icon.icon-whatsapp, .virtual-phone-apps__app .icon-whatsapp.icon-file, .virtual-phone-apps__app .icon-whatsapp.icon-file-outline {
  width: 32px;
  height: 32px;
  font-size: 32px;
}

.virtual-phone-apps__app .icon.icon-message, .virtual-phone-apps__app .icon-message.icon-file, .virtual-phone-apps__app .icon-message.icon-file-outline {
  width: 24px;
  height: 24px;
  font-size: 24px;
  transform: translateY(2px);
}

.virtual-phone-apps__app .badge {
  background: var(--red);
  border: 2px solid #fff;
  border-radius: 99px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -5px;
  right: -5px;
}

.virtual-phone-apps__app--alert {
  animation: 1s step-end infinite flash;
}

@keyframes flash {
  0% {
    background: var(--accent-colour-1);
  }

  50% {
    background: var(--accent-colour-2);
  }
}

.notification.new-session-notification {
  animation: .8s step-end infinite flashNotificationRed;
}

.notification.new-session-notification h3.notification__title {
  white-space: normal;
  overflow: visible;
}

.add-decision-log {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.add-decision-log h2 {
  width: auto;
}

.add-decision-log .button-row {
  grid-column-gap: 10px;
}

.add-decision-log .input-row {
  grid-column-gap: 10px;
  grid-row-gap: 2px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  width: 100%;
  display: grid;
}

.add-decision-log .input-row .textarea {
  min-height: 100px;
  max-height: 40vh;
  margin: 0;
}

.add-decision-log > .row:last-child {
  justify-content: flex-end;
}

.add-decision-log .row {
  margin-bottom: 4px;
}

.dark-mode-switch {
  opacity: .08;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: opacity 1s cubic-bezier(.19, 1, .22, 1);
  display: flex;
}

.dark-mode-switch .icon, .dark-mode-switch .icon-file, .dark-mode-switch .icon-file-outline {
  color: #000;
  font-size: 20px;
  transition: color 1s cubic-bezier(.19, 1, .22, 1);
}

.dark-mode-switch .react-switch {
  margin: 0 8px 0 6px;
}

.dark-mode-switch .react-switch .react-switch-bg {
  transition: background-color 1s cubic-bezier(.19, 1, .22, 1);
  background-color: #000 !important;
}

.dark-mode-switch .react-switch .react-switch-bg svg {
  display: none;
}

.dark-mode-switch .react-switch .react-switch-handle {
  transition: background-color 1s cubic-bezier(.19, 1, .22, 1);
  background-color: #fff !important;
}

.dark-theme .dark-mode-switch {
  opacity: .15;
}

.dark-theme .dark-mode-switch .icon, .dark-theme .dark-mode-switch .icon-file, .dark-theme .dark-mode-switch .icon-file-outline {
  color: #fff;
}

.dark-theme .dark-mode-switch .react-switch-bg {
  background-color: #fff !important;
}

.dark-theme .dark-mode-switch .react-switch-handle {
  background-color: #000 !important;
}

.notification {
  color: var(--white);
  cursor: pointer;
  background-color: #c91717;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 12px;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 5px 0;
  padding: 1px 3px 1px 1px;
  animation: .8s cubic-bezier(.19, 1, .22, 1) slideInFromLeft;
  display: flex;
  position: relative;
}

.notification--seen {
  background-color: rgba(255, 255, 255, .5);
  color: #4b4a4a !important;
}

.notification--seen h3.notification__title, .notification--seen div.notification__time {
  color: #4b4a4a !important;
}

.notification--seen div.notification__openicon, .notification--seen .icon-check {
  color: #4b4a4a;
}

.notification:first-child {
  margin-top: 0;
}

.notification:last-child {
  margin-bottom: 0;
}

.notification * {
  cursor: pointer;
}

.notification .row {
  width: 100%;
}

.notification h3.notification__title {
  text-align: left;
  color: var(--white);
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  font-size: .875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  overflow: hidden;
  letter-spacing: 0 !important;
  text-transform: none !important;
}

.notification__time, .notification__opentime, .notification__openicon {
  padding-right: .4rem;
}

.notification__time, .notification__opentime {
  color: var(--white);
  opacity: .4;
  font-size: var(--small-font-size);
  text-align: left;
  letter-spacing: .05em;
  font-weight: 400;
}

.notification__time {
  flex-grow: 1;
}

.notification__icon-container {
  border-radius: 8px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 4px 9px 4px 4px;
  display: flex;
}

.notification__icon-container .icon, .notification__icon-container .icon-file, .notification__icon-container .icon-file-outline {
  width: 19px;
  height: 19px;
  font-size: 19px;
}

.notification__details {
  background-color: rgba(0, 0, 0, 0);
  flex-grow: 1;
  width: 0;
  padding-left: 0;
}

.notification__details .left {
  justify-content: space-evenly;
}

.participant-view .notification__opentime {
  display: none;
}

.notification--recent {
  background-color: var(--red);
  color: var(--white);
  animation: .8s cubic-bezier(.19, 1, .22, 1) slideInFromLeft, .8s step-end infinite flashNotificationRed;
}

.notification--recent .notification__details, .notification--recent .notification__details .icon, .notification--recent .notification__details .icon-file, .notification--recent .notification__details .icon-file-outline {
  animation: none;
}

.start-screen {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.start-screen p {
  color: rgba(255, 255, 255, .9);
}

.start-screen .start-screen__titles {
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  margin-top: 20%;
  display: flex;
}

.start-screen .start-screen__titles h2 {
  text-align: center;
  color: var(--view360magenta);
  margin-bottom: 4px;
  font-size: 26px;
  line-height: 26px;
}

.start-screen .start-screen__titles h3 {
  text-align: center;
  color: #4b4a4a;
  margin-bottom: 60px;
  font-size: 19px;
}

.start-screen .start-screen__titles p {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #4b4a4a;
}

.start-screen__content {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.messages-app {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.messages-app p {
  text-align: center;
  width: 66%;
  margin-top: 15%;
}

.messages-app__overlay {
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, .9) 66%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.messages-app > .row {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
}

.messages-app > .row h2 {
  color: #fff;
  margin: 0;
}

.messages-app > .row .icon-button {
  border-radius: 0;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.messages-app > .row .icon-button .icon, .messages-app > .row .icon-button .icon-file, .messages-app > .row .icon-button .icon-file-outline {
  color: #fff;
  font-size: 20px;
}

.messages-app__content {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 66%;
  padding: 0 3px 15px 0;
  display: flex;
}

.messages-app__scroller {
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 10px;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.messages-app .message-entry {
  justify-content: center;
  margin-left: 14px;
  margin-right: 14px;
  display: flex;
}

.messages-app .message-entry .message-entry__wrapper, .messages-app .message-entry button {
  border-radius: 19px;
}

.messages-app .message-entry:not(.message-entry--disabled) .message-entry__wrapper {
  border-color: #999;
}

.messages-app .message {
  background-color: var(--lightlightgrey);
  flex-shrink: 0;
  width: calc(100% - 30px);
  margin-bottom: 7px;
  margin-left: 0;
  margin-right: 30px;
  padding-bottom: 4px;
  padding-left: 13px;
  animation: 1s cubic-bezier(.19, 1, .22, 1) slideInFromLeft;
}

.messages-app .message__at, .messages-app .message__name, .messages-app .message__from {
  text-transform: uppercase;
  font-size: var(--small-font-size);
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.messages-app .message__at {
  text-align: right;
  font-weight: 400;
}

.messages-app .message__left {
  display: none;
}

.messages-app .message__right {
  padding-bottom: 4px;
}

.messages-app .message__text {
  font-size: var(--small-font-size);
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 3px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3rem;
}

.messages-app .message__translate {
  line-height: 1.3rem;
  font-size: var(--small-font-size);
  opacity: .6;
}

.messages-app .message__translate:hover {
  opacity: 1;
}

.messages-app .message--group, .messages-app .message--participant {
  background-color: var(--button-colour);
  color: var(--button-text-colour);
  margin-left: 30px;
  margin-right: 0;
  animation: none;
}

.messages-app .message--group .message__translate, .messages-app .message--participant .message__translate {
  color: #fff !important;
}

.audio-player-screen, .audio-player, .video-player {
  text-align: center;
  color: #fff;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.audio-player-screen audio, .audio-player audio, .video-player audio {
  display: none;
}

.audio-player-screen__content, .audio-player__content, .video-player__content {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  display: flex;
}

.audio-player-screen__content .preloader, .audio-player__content .preloader, .video-player__content .preloader {
  width: 64px;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
}

.audio-player-screen__content > *, .audio-player__content > *, .video-player__content > * {
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
}

.audio-player-screen__content h3, .audio-player__content h3, .video-player__content h3 {
  margin-top: 14px;
  margin-bottom: 0;
  text-align: center !important;
  color: #fff !important;
}

.audio-player-screen__content p, .audio-player__content p, .video-player__content p {
  margin-top: 0;
  margin-bottom: 2rem;
}

.audio-player-screen__content .icon-button, .audio-player__content .icon-button, .video-player__content .icon-button {
  background-color: var(--primary-button-colour);
  color: var(--primary-button-text-colour);
  width: 64px;
  height: 64px;
}

.audio-player-screen__content .icon-button:hover, .audio-player__content .icon-button:hover, .video-player__content .icon-button:hover {
  background-color: var(--primary-button-hover-colour);
}

.audio-player-screen__content .icon-button .icon, .audio-player-screen__content .icon-button .icon-file, .audio-player-screen__content .icon-button .icon-file-outline, .audio-player__content .icon-button .icon, .audio-player__content .icon-button .icon-file, .audio-player__content .icon-button .icon-file-outline, .video-player__content .icon-button .icon, .video-player__content .icon-button .icon-file, .video-player__content .icon-button .icon-file-outline {
  font-size: 2rem;
}

.audio-player-screen__clock, .audio-player__clock, .video-player__clock {
  margin: 2rem 0 3rem;
  font-size: 1.8rem;
  font-weight: 300;
}

.audio-player-screen__footer, .audio-player__footer, .video-player__footer {
  justify-content: center;
  align-items: center;
  height: 64px;
  display: flex;
}

.audio-player-screen h2, .audio-player-screen h3, .audio-player h2, .audio-player h3, .video-player h2, .video-player h3 {
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  overflow: hidden;
}

.audio-player-screen .icon-button span, .audio-player .icon-button span, .video-player .icon-button span {
  color: #fff;
}

.audio-player-screen .audio-visualiser {
  background-color: #282c44;
}

.audio-player__button-row {
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.app-header {
  background: #282c44;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  display: flex;
}

.app-header h2 {
  margin: 0;
}

.app-header .icon-button {
  background-color: none;
  border-radius: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
}

.video-player {
  overflow: hidden;
}

.video-player__content {
  justify-content: flex-start;
  padding-top: 10%;
}

.video-player__content > * {
  margin-bottom: 0;
}

.video-player__content .video-player__video-container {
  max-height: 33%;
  position: relative;
}

.video-player__content video {
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: -3px;
}

.video-player__content .video-player__clock1, .video-player__content .video-player__clock2 {
  color: #4b4a4a;
}

.video-player__content .video-player__controls-overlay {
  opacity: 0;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .5);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .1s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.video-player__content .video-player__controls-overlay * {
  cursor: pointer;
}

.video-player__content .video-player__controls-overlay:hover {
  opacity: 1;
}

.video-player__content .video-player__controls-overlay p {
  color: #000;
  text-transform: uppercase;
  padding: 0;
}

.video-player__content .video-player__controls-overlay .icon-button {
  background: none;
  width: 80px;
  height: 80px;
}

.video-player__content .video-player__controls-overlay .icon-button .icon, .video-player__content .video-player__controls-overlay .icon-button .icon-file, .video-player__content .video-player__controls-overlay .icon-button .icon-file-outline {
  color: rgba(0, 0, 0, .8);
  font-size: 2.2rem;
}

.video-player__content progress {
  background-color: rgba(128, 128, 128, .1);
  border-radius: 5px;
  height: 5px;
}

.video-player__content progress::-webkit-progress-bar {
  background-color: rgba(128, 128, 128, .1);
}

.video-player__content progress::-webkit-progress-value {
  background-color: var(--accent-colour-2);
}

.video-player__content progress::-moz-progress-bar {
  background-color: var(--accent-colour-2);
}

.video-player__content .row {
  justify-content: space-evenly;
  align-items: center;
  height: 30px;
}

.video-player__content .row.video-player__controls {
  height: auto;
  padding: 14px 0;
}

.video-player__content .row.video-player__controls .icon-button {
  background-color: var(--accent-colour-1);
}

.video-player__content .row.video-player__controls .icon-button:hover {
  background-color: var(--accent-colour-2);
}

.video-player__content .video-player__titles {
  text-align: center;
}

.video-player__content .video-player__titles h3 {
  text-align: center;
  color: #4b4a4a !important;
}

.video-player__content h3 {
  color: #4b4a4a;
  padding-left: 10px;
}

.video-player__content p {
  margin: 0 0 6px;
  padding-left: 10px;
}

.video-player__content .icon-button {
  width: 48px;
  height: 48px;
}

.video-player__content .icon-button .icon, .video-player__content .icon-button .icon-file, .video-player__content .icon-button .icon-file-outline {
  font-size: 1.5rem;
}

.video-player__content .icon-button .icon.icon-play, .video-player__content .icon-button .icon-play.icon-file, .video-player__content .icon-button .icon-play.icon-file-outline {
  transform: translateX(-2px);
}

.video-player__content .video-player__expand-section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.video-player__content .video-player__expand-section p {
  text-align: center;
  margin: 30px 20px 10px;
}

.light-mode .video_player {
  color: #000;
  background: #fff;
}

.light-mode .video_player__header {
  border-bottom: solid 1px var(--border-colour-1);
  background: #fff;
}

.light-mode .video_player .row, .light-mode .video_player .video-player__titles {
  border-bottom: solid 1px var(--border-colour-1);
}

.light-mode .video_player__content .icon-button {
  color: var(--base-text-colour);
}

.light-mode .video_player__content .icon-button:hover {
  background-color: var(--primary-button-colour);
}

.light-mode .video_player__content .icon-button:hover .icon, .light-mode .video_player__content .icon-button:hover .icon-file, .light-mode .video_player__content .icon-button:hover .icon-file-outline {
  color: var(--primary-button-text-colour);
}

.light-mode .video_player__content .icon-button .icon, .light-mode .video_player__content .icon-button .icon-file, .light-mode .video_player__content .icon-button .icon-file-outline {
  color: var(--base-text-colour);
}

.light-mode .video_player video {
  border-bottom: solid 1px var(--border-colour-1);
}

.app-header-bar {
  background-color: var(--virtual-phone__bg);
  color: var(--white);
  letter-spacing: 1px;
  flex-shrink: 0;
  align-content: center;
  justify-content: center;
  width: 100%;
  font-weight: 700;
  line-height: 30px;
  display: flex;
  overflow-x: hidden;
}

.app-header-bar--zero {
  color: rgba(255, 255, 255, .7);
  background-color: var(--virtual-phone__bg);
}

.update-screen {
  color: #fff;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.update-screen .app-header {
  background: var(--white);
}

.update-screen .app-header h2 {
  color: var(--heading-text-colour);
}

.update-screen .app-header .icon-button .icon, .update-screen .app-header .icon-button .icon-file, .update-screen .app-header .icon-button .icon-file-outline {
  color: var(--accent-colour-2);
}

.update-screen .app-header .icon-button:hover .icon, .update-screen .app-header .icon-button:hover .icon-file, .update-screen .app-header .icon-button:hover .icon-file-outline, .update-screen .app-header .icon-button:active .icon, .update-screen .app-header .icon-button:active .icon-file, .update-screen .app-header .icon-button:active .icon-file-outline {
  color: var(--white);
}

.update-screen > p {
  text-align: center;
  border-bottom: 1px solid var(--border-colour-1);
  border-top: 1px solid var(--border-colour-1);
  width: 90%;
  margin: 0;
  line-height: 31px;
}

.update-screen__content {
  flex-grow: 1;
  padding: 0 1.4rem 2rem;
  overflow: auto;
}

.update-screen__content p {
  color: #000;
  opacity: .9;
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 1.1rem;
  font-weight: normal;
}

.update-screen__translate {
  color: var(--primary-button-colour);
  border-top: solid 1px var(--border-colour-1);
  cursor: pointer;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-bottom: 5px;
  display: flex;
}

.update-screen__translate:hover {
  color: var(--primary-button-hover-colour);
}

.new-media-alert {
  flex-direction: column;
  width: 100%;
  display: flex;
  overflow-x: hidden;
}

.new-media-alert__alert {
  background-color: var(--red);
  color: var(--white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  margin-top: 3px;
  font-size: .8rem;
  animation: .8s cubic-bezier(.19, 1, .22, 1) slideInFromLeft, .8s step-end 20 flashNotificationRed;
  display: flex;
  position: relative;
}

.new-media-alert__alert:first-child {
  margin-top: 0;
}

.new-media-alert__alert > * {
  opacity: .7;
}

.new-media-alert__alert .icon-button {
  opacity: .6;
  width: 16px;
  height: 18px;
  font-size: 18px;
  position: absolute;
  top: 4px;
  right: 7px;
}

.new-media-alert__alert .icon-button:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
}

.new-media-alert__title {
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 1px;
  font-size: 1rem;
}

.voice-message-screen {
  flex-direction: column;
  align-items: center;
  padding: 0 1rem 2rem;
  display: flex;
  overflow: hidden;
}

.voice-message-screen h2 {
  color: var(--white);
  text-align: left;
  width: 100%;
  margin: 0;
  text-transform: none !important;
  font-size: 27px !important;
  font-weight: 300 !important;
  line-height: 23px !important;
}

.voice-message-screen h3 {
  text-align: left;
  width: 100%;
  margin: 0;
  color: var(--white) !important;
  font-weight: 500 !important;
  line-height: 30px !important;
}

.voice-message-screen .virtual-phone__bg2 {
  opacity: 1;
  animation-play-state: running;
}

.voice-message-screen--inactive .virtual-phone__bg2 {
  animation-play-state: paused;
}

.voice-message-screen__top-row {
  height: 60px;
}

.voice-message-screen__top-row .voice-message-screen__close-button.icon-button {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
}

.voice-message-screen__header, .voice-message-screen__content, .voice-message-screen__footer {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.voice-message-screen__header {
  background-color: rgba(255, 255, 255, .2);
  justify-content: space-evenly;
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-right: -30px;
  padding: 10px 30px 2px;
  transition: transform 1s cubic-bezier(.19, 1, .22, 1);
  position: relative;
  transform: translateY(-240px);
}

.voice-message-screen__content {
  flex-grow: 1;
  justify-content: center;
  margin-top: -60px;
  transition: margin 1s cubic-bezier(.19, 1, .22, 1);
}

.voice-message-screen__content h3 {
  text-align: center !important;
}

.voice-message-screen .icon-button {
  color: #fff;
  background-color: rgba(255, 255, 255, .2);
  width: 64px;
  height: 64px;
}

.voice-message-screen .icon-button .icon, .voice-message-screen .icon-button .icon-file, .voice-message-screen .icon-button .icon-file-outline {
  color: #fff;
}

.voice-message-screen .icon-button__text {
  text-transform: lowercase;
}

.voice-message-screen .icon-button:hover {
  background-color: var(--accent-colour-2);
}

.voice-message-screen__footer {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 25%;
}

.voice-message-screen--pending .voice-message-screen__header {
  transform: translateY(0);
}

.voice-message-screen--pending .voice-message-screen__content {
  margin-top: 0;
}

.voice-message-screen__phone-icon {
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  top: 12px;
  right: 30px;
}

.voice-message-screen__phone-icon .icon, .voice-message-screen__phone-icon .icon-file, .voice-message-screen__phone-icon .icon-file-outline {
  font-size: 20px;
}

.voice-message-screen > .icon, .voice-message-screen > .icon-file, .voice-message-screen > .icon-file-outline {
  color: var(--accent-colour-2);
  border: solid 4px var(--accent-colour-2);
  border-radius: 999px;
  margin: 1rem;
  padding: 25px;
  font-size: 64px;
}

.voice-message-screen .audio-player__play-button {
  background-color: var(--green);
}

.voice-message-screen .audio-player__close-button {
  background-color: var(--red);
}

.voice-message-screen .phone-preloader {
  align-items: center;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.voice-message-screen .phone-preloader__ring1 {
  border-color: var(--white);
}

.voice-message-screen p {
  text-align: center;
  margin-top: .7rem;
  margin-bottom: 0;
}

.voice-message-screen .button {
  margin: 1.3rem 5px 0;
}

.participant-view {
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  font-family: Open Sans, sans-serif;
  display: flex;
  overflow: hidden;
}

.participant-view h1 {
  font-family: var(--heading-font-family);
  letter-spacing: 0;
  text-align: left;
  text-transform: uppercase;
  color: var(--base-text-colour);
  font-weight: 700;
  line-height: 30px;
}

.participant-view .bubble-heading, .participant-view .modal__title, .participant-view h2 {
  font-family: var(--heading-font-family);
  text-transform: uppercase;
  font-size: var(--primary-heading-size);
  letter-spacing: 0;
  font-weight: 700;
  line-height: 20px;
}

.participant-view .bubble-heading:after, .participant-view .modal__title:after, .participant-view h2:after {
  display: none;
}

.participant-view h3 {
  letter-spacing: .05em;
  text-align: left;
  text-transform: uppercase;
  color: var(--base-text-colour);
  font-family: Open Sans, sans-serif;
  font-size: .875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
}

.participant-view .participant-header-bar {
  z-index: 2;
  width: 100%;
}

.participant-view > .row {
  flex-grow: 1;
  height: 1px;
  margin-top: 12px;
}

.participant-view .video-call {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  position: static;
}

.participant-view .virtual-phone__container {
  flex-shrink: 0;
  width: 35%;
  min-width: 390px;
  transition: width 2s cubic-bezier(.19, 1, .22, 1);
}

.participant-view .dark-mode-switch {
  width: 35%;
  transition: width 2s cubic-bezier(.19, 1, .22, 1);
  position: absolute;
  bottom: 10px;
  left: 0;
}

.participant-view__middle {
  opacity: 1;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  width: 60%;
  min-width: 700px;
  margin: 40px 0;
  transition: width 2s cubic-bezier(.19, 1, .22, 1), min-width 2s cubic-bezier(.19, 1, .22, 1), opacity 2s cubic-bezier(.19, 1, .22, 1);
  display: flex;
  position: relative;
}

.participant-view__middle h2 {
  margin-top: 8px;
  margin-bottom: 5px;
}

.participant-view .participant-decision-log {
  z-index: 2;
  width: 100%;
}

.participant-view .media-viewer {
  width: 100%;
  height: 100%;
}

.participant-view .participant-header-bar h2, .participant-view .modal h2, .participant-view .initial-information-modal h2, .participant-view__middle h2 {
  color: var(--accent-colour-2);
}

.participant-view .participant-header-bar h3, .participant-view .modal h3, .participant-view .initial-information-modal h3, .participant-view__middle h3 {
  color: #333;
  font-family: Nunito, Roboto, sans-serif;
}

.participant-view .participant-header-bar label, .participant-view .modal label, .participant-view .initial-information-modal label, .participant-view__middle label, .participant-view .participant-header-bar button, .participant-view .modal button, .participant-view .initial-information-modal button, .participant-view__middle button {
  font-family: Nunito, Roboto, sans-serif;
  font-weight: 700;
}

.participant-view .tab {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.participant-view .tab--inactive {
  transform: none;
}

.participant-view .tab--inactive:not(:hover) {
  background-color: var(--panel-background-colour);
  box-shadow: inset 0 -18px 18px -25px rgba(0, 0, 0, .2);
}

.participant-view:not(.participant-view--insession) .participant-header-bar {
  z-index: 2;
  width: 100%;
}

.participant-view:not(.participant-view--insession) .participant-view__middle {
  opacity: 0;
}

.participant-view:not(.participant-view--insession) .add-decision-log {
  display: none;
}

.participant-view:not(.participant-view--insession) .virtual-phone__container, .participant-view:not(.participant-view--insession) .dark-mode-switch {
  width: 100%;
}

.participant-view .participant-decision-log__top {
  flex-direction: column;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.participant-view .participant-decision-log__top > * {
  opacity: 1;
  transition: transform .8s cubic-bezier(.19, 1, .22, 1), opacity .8s cubic-bezier(.19, 1, .22, 1);
  transform: translateX(0);
}

.participant-view .participant-view__media-container {
  height: 0%;
  margin-bottom: 0;
}

.participant-view .participant-view__media-container .media-viewer {
  opacity: 0;
  height: 100%;
  transition: transform .8s cubic-bezier(.19, 1, .22, 1), opacity .8s cubic-bezier(.19, 1, .22, 1);
  transform: translateX(-100px);
}

.participant-view--mediaopen .participant-view__media-container {
  height: 100%;
  margin-bottom: 12px;
}

.participant-view--mediaopen .participant-view__media-container .media-viewer {
  opacity: 1;
  transform: translateX(0);
}

.participant-view--mediaopen .participant-decision-log {
  flex-shrink: 0;
  height: auto;
}

.participant-view--mediaopen .participant-decision-log__top {
  height: 0;
}

.participant-view--mediaopen .participant-decision-log__top > * {
  opacity: 0;
  transform: translateX(100px);
}

.participant-view--mediaopen:not(.participant-view--sessionactive) .participant-header-bar, .participant-view--mediaopen.participant-view--expanded .participant-header-bar {
  z-index: 2;
  width: 100%;
  position: relative;
}

.participant-view--mediaopen:not(.participant-view--sessionactive) .participant-view__middle .tab__row, .participant-view--mediaopen:not(.participant-view--sessionactive) .participant-view__middle .tabs-container, .participant-view--mediaopen:not(.participant-view--sessionactive) .participant-view__middle .participant-decision-log, .participant-view--mediaopen.participant-view--expanded .participant-view__middle .tab__row, .participant-view--mediaopen.participant-view--expanded .participant-view__middle .tabs-container, .participant-view--mediaopen.participant-view--expanded .participant-view__middle .participant-decision-log {
  display: none;
}

.participant-view--mediaopen:not(.participant-view--sessionactive) .media-viewer, .participant-view--mediaopen.participant-view--expanded .media-viewer {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.participant-view--mediaopen:not(.participant-view--sessionactive) .participant-view__media-container, .participant-view--mediaopen.participant-view--expanded .participant-view__media-container {
  margin-bottom: 0;
}

.participant-view:not(.participant-view--mediaopen) .participant-view__media-container .media-viewer input, .participant-view:not(.participant-view--mediaopen) .participant-view__media-container .media-viewer select, .participant-view:not(.participant-view--mediaopen) .participant-view__media-container .media-viewer textarea, .participant-view:not(.participant-view--mediaopen) .participant-view__media-container .media-viewer button {
  display: none;
}

.participant-view {
  background-color: var(--white);
  transition: background-color 1s cubic-bezier(.19, 1, .22, 1);
}

.dark-theme .participant-view {
  background-color: var(--dark-mode-background-colour);
}

[hidden] {
  display: none !important;
}

.react-fine-uploader-gallery-nodrop-container, .react-fine-uploader-gallery-dropzone {
  background-color: #fafafa;
  border-radius: 6px;
  min-height: 310px;
  max-height: 490px;
  padding: 15px 15px 15px 5px;
  position: relative;
  overflow-y: hidden;
}

.react-fine-uploader-gallery-dropzone {
  border: 2px dashed #00abc7;
}

.react-fine-uploader-gallery-dropzone-upload-icon {
  width: 36px;
  height: 36px;
  margin-bottom: -6px;
  margin-right: 10px;
}

.react-fine-uploader-gallery-nodrop-container {
  border: 2px solid #00abc7;
}

.react-fine-uploader-gallery-dropzone-active {
  background: #fdfdfd;
  border: 2px solid #00abc7;
}

.react-fine-uploader-gallery-dropzone-content, .react-fine-uploader-gallery-nodrop-content {
  opacity: .25;
  text-align: center;
  width: 100%;
  font-size: 36px;
  position: absolute;
  top: 38%;
  left: 0;
}

.react-fine-uploader-gallery-file-input-container {
  color: #fff;
  float: left;
  text-align: center;
  background: #00abc7;
  border: 1px solid #37b7cc;
  border-radius: 3px;
  width: 105px;
  margin-left: 10px;
  padding: 7px 10px;
  display: inline;
}

.react-fine-uploader-gallery-file-input-container:hover {
  background: #33b6cc;
}

.react-fine-uploader-gallery-file-input-container:focus {
  outline: 1px dotted #000;
}

.react-fine-uploader-gallery-file-input-content {
  margin-top: -2px;
  display: inline-block;
}

.react-fine-uploader-gallery-file-input-upload-icon {
  fill: #fff;
  width: 24px;
  height: 24px;
  margin-bottom: -6px;
  margin-right: 5px;
}

.react-fine-uploader-gallery-progress-bar, .react-fine-uploader-gallery-total-progress-bar {
  border-radius: 3px;
}

.react-fine-uploader-gallery-progress-bar-container, .react-fine-uploader-gallery-total-progress-bar-container {
  background: #f2f2f2;
  border-radius: 3px;
  position: absolute;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, .2);
}

.react-fine-uploader-gallery-total-progress-bar-container {
  width: 70%;
  height: 25px;
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
}

.react-fine-uploader-gallery-progress-bar, .react-fine-uploader-gallery-total-progress-bar {
  height: inherit;
  background: #00abc7;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, .2);
}

.react-fine-uploader-gallery-progress-bar-container {
  opacity: .9;
  z-index: 1;
  width: 90%;
  height: 15px;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.react-fine-uploader-gallery-files {
  clear: both;
  max-height: 450px;
  padding-top: 15px;
  padding-left: 0;
  list-style: none;
  overflow-y: auto;
}

.react-fine-uploader-gallery-files-enter {
  opacity: .01;
}

.react-fine-uploader-gallery-files-enter.react-fine-uploader-gallery-files-enter-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.react-fine-uploader-gallery-files-exit {
  opacity: 1;
}

.react-fine-uploader-gallery-files-exit.react-fine-uploader-gallery-files-exit-active {
  opacity: .01;
  transition: opacity .3s ease-in;
}

.react-fine-uploader-gallery-file {
  vertical-align: top;
  background-color: #fff;
  border-radius: 9px;
  width: 130px;
  height: 165px;
  margin: 0 25px 25px 10px;
  font-size: 13px;
  line-height: 16px;
  display: inline-block;
  position: relative;
  box-shadow: 0 3px 3px rgba(0, 0, 0, .22);
}

.react-fine-uploader-gallery-thumbnail-container {
  text-align: center;
  height: 130px;
  display: block;
}

.react-fine-uploader-gallery-thumbnail {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.react-fine-uploader-gallery-thumbnail-icon-backdrop, .react-fine-uploader-gallery-upload-failed-icon, .react-fine-uploader-gallery-upload-success-icon {
  opacity: .5;
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-fine-uploader-gallery-upload-failed-icon, .react-fine-uploader-gallery-upload-success-icon {
  z-index: 1;
  width: 60px;
  height: 60px;
}

.react-fine-uploader-gallery-upload-success-icon {
  fill: green;
}

.react-fine-uploader-gallery-upload-failed-icon {
  fill: red;
}

.react-fine-uploader-gallery-thumbnail-icon-backdrop {
  background-color: #fff;
  border-radius: 30px;
  width: 50px;
  height: 50px;
}

.react-fine-uploader-gallery-file-footer {
  padding-left: 5px;
  padding-right: 5px;
}

.react-fine-uploader-gallery-filename {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  display: block;
  overflow: hidden;
}

.react-fine-uploader-gallery-filesize {
  float: right;
  display: block;
}

.react-fine-uploader-gallery-status {
  font-style: italic;
}

.react-fine-uploader-gallery-cancel-button:hover svg, .react-fine-uploader-gallery-delete-button:hover svg, .react-fine-uploader-gallery-pause-resume-button:hover svg, .react-fine-uploader-gallery-retry-button:hover svg {
  fill: gray;
}

.react-fine-uploader-gallery-cancel-button:focus, .react-fine-uploader-gallery-delete-button:focus, .react-fine-uploader-gallery-pause-resume-button:focus, .react-fine-uploader-gallery-retry-button:focus {
  outline: none;
}

.react-fine-uploader-gallery-cancel-button:focus svg, .react-fine-uploader-gallery-delete-button:focus svg, .react-fine-uploader-gallery-pause-resume-button:focus svg, .react-fine-uploader-gallery-retry-button:focus svg {
  fill: gray;
}

.react-fine-uploader-gallery-cancel-button, .react-fine-uploader-gallery-delete-button, .react-fine-uploader-gallery-pause-resume-button, .react-fine-uploader-gallery-retry-button {
  background: none;
  border: 0;
  position: absolute;
}

.react-fine-uploader-gallery-cancel-button, .react-fine-uploader-gallery-delete-button {
  top: -12px;
  right: -18px;
}

.react-fine-uploader-gallery-pause-resume-button, .react-fine-uploader-gallery-retry-button {
  top: -12px;
  left: -18px;
}

.iti {
  display: inline-block;
  position: relative;
}

.iti * {
  box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input {
  z-index: 0;
  margin-right: 0;
  padding-right: 36px;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.iti input[type="text"] {
  z-index: 0;
  margin-right: 0;
  padding-right: 36px;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.iti input[type="tel"] {
  z-index: 0;
  margin-right: 0;
  padding-right: 36px;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.iti__flag-container {
  padding: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.iti__selected-flag {
  z-index: 1;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
  display: flex;
  position: relative;
}

.iti__arrow {
  border-top: 4px solid #555;
  border-left: 3px solid rgba(0, 0, 0, 0);
  border-right: 3px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  margin-left: 6px;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  z-index: 2;
  text-align: left;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  margin: 0 0 0 -1px;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-y: scroll;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
}

.iti__country-list--dropup {
  margin-bottom: -1px;
  bottom: 100%;
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}

.iti__flag-box {
  width: 20px;
  display: inline-block;
}

.iti__divider {
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.iti__country {
  outline: none;
  padding: 5px 10px;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, .05);
}

.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
}

.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}

.iti--allow-dropdown input, .iti--separate-dial-code input {
  margin-left: 0;
  padding-left: 52px;
  padding-right: 6px;
}

.iti--allow-dropdown input[type="text"] {
  margin-left: 0;
  padding-left: 52px;
  padding-right: 6px;
}

.iti--allow-dropdown input[type="tel"] {
  margin-left: 0;
  padding-left: 52px;
  padding-right: 6px;
}

.iti--separate-dial-code input[type="text"] {
  margin-left: 0;
  padding-left: 52px;
  padding-right: 6px;
}

.iti--separate-dial-code input[type="tel"] {
  margin-left: 0;
  padding-left: 52px;
  padding-right: 6px;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  left: 0;
  right: auto;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, .05);
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0);
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, .05);
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  z-index: 1060;
  padding: 1px;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  position: fixed;
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
}

.iti-mobile .iti__country-list {
  width: 100%;
  max-height: 100%;
}

.iti-mobile .iti__country {
  padding: 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}

.iti__flag.iti__ac {
  background-position: 0 0;
  height: 10px;
}

.iti__flag.iti__ad {
  background-position: -22px 0;
  height: 14px;
}

.iti__flag.iti__ae {
  background-position: -44px 0;
  height: 10px;
}

.iti__flag.iti__af {
  background-position: -66px 0;
  height: 14px;
}

.iti__flag.iti__ag {
  background-position: -88px 0;
  height: 14px;
}

.iti__flag.iti__ai {
  background-position: -110px 0;
  height: 10px;
}

.iti__flag.iti__al {
  background-position: -132px 0;
  height: 15px;
}

.iti__flag.iti__am {
  background-position: -154px 0;
  height: 10px;
}

.iti__flag.iti__ao {
  background-position: -176px 0;
  height: 14px;
}

.iti__flag.iti__aq {
  background-position: -198px 0;
  height: 14px;
}

.iti__flag.iti__ar {
  background-position: -220px 0;
  height: 13px;
}

.iti__flag.iti__as {
  background-position: -242px 0;
  height: 10px;
}

.iti__flag.iti__at {
  background-position: -264px 0;
  height: 14px;
}

.iti__flag.iti__au {
  background-position: -286px 0;
  height: 10px;
}

.iti__flag.iti__aw {
  background-position: -308px 0;
  height: 14px;
}

.iti__flag.iti__ax {
  background-position: -330px 0;
  height: 13px;
}

.iti__flag.iti__az {
  background-position: -352px 0;
  height: 10px;
}

.iti__flag.iti__ba {
  background-position: -374px 0;
  height: 10px;
}

.iti__flag.iti__bb {
  background-position: -396px 0;
  height: 14px;
}

.iti__flag.iti__bd {
  background-position: -418px 0;
  height: 12px;
}

.iti__flag.iti__be {
  background-position: -440px 0;
  height: 15px;
}

.iti__flag.iti__bf {
  background-position: -460px 0;
  height: 14px;
}

.iti__flag.iti__bg {
  background-position: -482px 0;
  height: 12px;
}

.iti__flag.iti__bh {
  background-position: -504px 0;
  height: 12px;
}

.iti__flag.iti__bi {
  background-position: -526px 0;
  height: 12px;
}

.iti__flag.iti__bj {
  background-position: -548px 0;
  height: 14px;
}

.iti__flag.iti__bl {
  background-position: -570px 0;
  height: 14px;
}

.iti__flag.iti__bm {
  background-position: -592px 0;
  height: 10px;
}

.iti__flag.iti__bn {
  background-position: -614px 0;
  height: 10px;
}

.iti__flag.iti__bo {
  background-position: -636px 0;
  height: 14px;
}

.iti__flag.iti__bq {
  background-position: -658px 0;
  height: 14px;
}

.iti__flag.iti__br {
  background-position: -680px 0;
  height: 14px;
}

.iti__flag.iti__bs {
  background-position: -702px 0;
  height: 10px;
}

.iti__flag.iti__bt {
  background-position: -724px 0;
  height: 14px;
}

.iti__flag.iti__bv {
  background-position: -746px 0;
  height: 15px;
}

.iti__flag.iti__bw {
  background-position: -768px 0;
  height: 14px;
}

.iti__flag.iti__by {
  background-position: -790px 0;
  height: 10px;
}

.iti__flag.iti__bz {
  background-position: -812px 0;
  height: 14px;
}

.iti__flag.iti__ca {
  background-position: -834px 0;
  height: 10px;
}

.iti__flag.iti__cc {
  background-position: -856px 0;
  height: 10px;
}

.iti__flag.iti__cd {
  background-position: -878px 0;
  height: 15px;
}

.iti__flag.iti__cf {
  background-position: -900px 0;
  height: 14px;
}

.iti__flag.iti__cg {
  background-position: -922px 0;
  height: 14px;
}

.iti__flag.iti__ch {
  background-position: -944px 0;
  height: 15px;
}

.iti__flag.iti__ci {
  background-position: -961px 0;
  height: 14px;
}

.iti__flag.iti__ck {
  background-position: -983px 0;
  height: 10px;
}

.iti__flag.iti__cl {
  background-position: -1005px 0;
  height: 14px;
}

.iti__flag.iti__cm {
  background-position: -1027px 0;
  height: 14px;
}

.iti__flag.iti__cn {
  background-position: -1049px 0;
  height: 14px;
}

.iti__flag.iti__co {
  background-position: -1071px 0;
  height: 14px;
}

.iti__flag.iti__cp {
  background-position: -1093px 0;
  height: 14px;
}

.iti__flag.iti__cr {
  background-position: -1115px 0;
  height: 12px;
}

.iti__flag.iti__cu {
  background-position: -1137px 0;
  height: 10px;
}

.iti__flag.iti__cv {
  background-position: -1159px 0;
  height: 12px;
}

.iti__flag.iti__cw {
  background-position: -1181px 0;
  height: 14px;
}

.iti__flag.iti__cx {
  background-position: -1203px 0;
  height: 10px;
}

.iti__flag.iti__cy {
  background-position: -1225px 0;
  height: 14px;
}

.iti__flag.iti__cz {
  background-position: -1247px 0;
  height: 14px;
}

.iti__flag.iti__de {
  background-position: -1269px 0;
  height: 12px;
}

.iti__flag.iti__dg {
  background-position: -1291px 0;
  height: 10px;
}

.iti__flag.iti__dj {
  background-position: -1313px 0;
  height: 14px;
}

.iti__flag.iti__dk {
  background-position: -1335px 0;
  height: 15px;
}

.iti__flag.iti__dm {
  background-position: -1357px 0;
  height: 10px;
}

.iti__flag.iti__do {
  background-position: -1379px 0;
  height: 14px;
}

.iti__flag.iti__dz {
  background-position: -1401px 0;
  height: 14px;
}

.iti__flag.iti__ea {
  background-position: -1423px 0;
  height: 14px;
}

.iti__flag.iti__ec {
  background-position: -1445px 0;
  height: 14px;
}

.iti__flag.iti__ee {
  background-position: -1467px 0;
  height: 13px;
}

.iti__flag.iti__eg {
  background-position: -1489px 0;
  height: 14px;
}

.iti__flag.iti__eh {
  background-position: -1511px 0;
  height: 10px;
}

.iti__flag.iti__er {
  background-position: -1533px 0;
  height: 10px;
}

.iti__flag.iti__es {
  background-position: -1555px 0;
  height: 14px;
}

.iti__flag.iti__et {
  background-position: -1577px 0;
  height: 10px;
}

.iti__flag.iti__eu {
  background-position: -1599px 0;
  height: 14px;
}

.iti__flag.iti__fi {
  background-position: -1621px 0;
  height: 12px;
}

.iti__flag.iti__fj {
  background-position: -1643px 0;
  height: 10px;
}

.iti__flag.iti__fk {
  background-position: -1665px 0;
  height: 10px;
}

.iti__flag.iti__fm {
  background-position: -1687px 0;
  height: 11px;
}

.iti__flag.iti__fo {
  background-position: -1709px 0;
  height: 15px;
}

.iti__flag.iti__fr {
  background-position: -1731px 0;
  height: 14px;
}

.iti__flag.iti__ga {
  background-position: -1753px 0;
  height: 15px;
}

.iti__flag.iti__gb {
  background-position: -1775px 0;
  height: 10px;
}

.iti__flag.iti__gd {
  background-position: -1797px 0;
  height: 12px;
}

.iti__flag.iti__ge {
  background-position: -1819px 0;
  height: 14px;
}

.iti__flag.iti__gf {
  background-position: -1841px 0;
  height: 14px;
}

.iti__flag.iti__gg {
  background-position: -1863px 0;
  height: 14px;
}

.iti__flag.iti__gh {
  background-position: -1885px 0;
  height: 14px;
}

.iti__flag.iti__gi {
  background-position: -1907px 0;
  height: 10px;
}

.iti__flag.iti__gl {
  background-position: -1929px 0;
  height: 14px;
}

.iti__flag.iti__gm {
  background-position: -1951px 0;
  height: 14px;
}

.iti__flag.iti__gn {
  background-position: -1973px 0;
  height: 14px;
}

.iti__flag.iti__gp {
  background-position: -1995px 0;
  height: 14px;
}

.iti__flag.iti__gq {
  background-position: -2017px 0;
  height: 14px;
}

.iti__flag.iti__gr {
  background-position: -2039px 0;
  height: 14px;
}

.iti__flag.iti__gs {
  background-position: -2061px 0;
  height: 10px;
}

.iti__flag.iti__gt {
  background-position: -2083px 0;
  height: 13px;
}

.iti__flag.iti__gu {
  background-position: -2105px 0;
  height: 11px;
}

.iti__flag.iti__gw {
  background-position: -2127px 0;
  height: 10px;
}

.iti__flag.iti__gy {
  background-position: -2149px 0;
  height: 12px;
}

.iti__flag.iti__hk {
  background-position: -2171px 0;
  height: 14px;
}

.iti__flag.iti__hm {
  background-position: -2193px 0;
  height: 10px;
}

.iti__flag.iti__hn {
  background-position: -2215px 0;
  height: 10px;
}

.iti__flag.iti__hr {
  background-position: -2237px 0;
  height: 10px;
}

.iti__flag.iti__ht {
  background-position: -2259px 0;
  height: 12px;
}

.iti__flag.iti__hu {
  background-position: -2281px 0;
  height: 10px;
}

.iti__flag.iti__ic {
  background-position: -2303px 0;
  height: 14px;
}

.iti__flag.iti__id {
  background-position: -2325px 0;
  height: 14px;
}

.iti__flag.iti__ie {
  background-position: -2347px 0;
  height: 10px;
}

.iti__flag.iti__il {
  background-position: -2369px 0;
  height: 15px;
}

.iti__flag.iti__im {
  background-position: -2391px 0;
  height: 10px;
}

.iti__flag.iti__in {
  background-position: -2413px 0;
  height: 14px;
}

.iti__flag.iti__io {
  background-position: -2435px 0;
  height: 10px;
}

.iti__flag.iti__iq {
  background-position: -2457px 0;
  height: 14px;
}

.iti__flag.iti__ir {
  background-position: -2479px 0;
  height: 12px;
}

.iti__flag.iti__is {
  background-position: -2501px 0;
  height: 15px;
}

.iti__flag.iti__it {
  background-position: -2523px 0;
  height: 14px;
}

.iti__flag.iti__je {
  background-position: -2545px 0;
  height: 12px;
}

.iti__flag.iti__jm {
  background-position: -2567px 0;
  height: 10px;
}

.iti__flag.iti__jo {
  background-position: -2589px 0;
  height: 10px;
}

.iti__flag.iti__jp {
  background-position: -2611px 0;
  height: 14px;
}

.iti__flag.iti__ke {
  background-position: -2633px 0;
  height: 14px;
}

.iti__flag.iti__kg {
  background-position: -2655px 0;
  height: 12px;
}

.iti__flag.iti__kh {
  background-position: -2677px 0;
  height: 13px;
}

.iti__flag.iti__ki {
  background-position: -2699px 0;
  height: 10px;
}

.iti__flag.iti__km {
  background-position: -2721px 0;
  height: 12px;
}

.iti__flag.iti__kn {
  background-position: -2743px 0;
  height: 14px;
}

.iti__flag.iti__kp {
  background-position: -2765px 0;
  height: 10px;
}

.iti__flag.iti__kr {
  background-position: -2787px 0;
  height: 14px;
}

.iti__flag.iti__kw {
  background-position: -2809px 0;
  height: 10px;
}

.iti__flag.iti__ky {
  background-position: -2831px 0;
  height: 10px;
}

.iti__flag.iti__kz {
  background-position: -2853px 0;
  height: 10px;
}

.iti__flag.iti__la {
  background-position: -2875px 0;
  height: 14px;
}

.iti__flag.iti__lb {
  background-position: -2897px 0;
  height: 14px;
}

.iti__flag.iti__lc {
  background-position: -2919px 0;
  height: 10px;
}

.iti__flag.iti__li {
  background-position: -2941px 0;
  height: 12px;
}

.iti__flag.iti__lk {
  background-position: -2963px 0;
  height: 10px;
}

.iti__flag.iti__lr {
  background-position: -2985px 0;
  height: 11px;
}

.iti__flag.iti__ls {
  background-position: -3007px 0;
  height: 14px;
}

.iti__flag.iti__lt {
  background-position: -3029px 0;
  height: 12px;
}

.iti__flag.iti__lu {
  background-position: -3051px 0;
  height: 12px;
}

.iti__flag.iti__lv {
  background-position: -3073px 0;
  height: 10px;
}

.iti__flag.iti__ly {
  background-position: -3095px 0;
  height: 10px;
}

.iti__flag.iti__ma {
  background-position: -3117px 0;
  height: 14px;
}

.iti__flag.iti__mc {
  background-position: -3139px 0;
  height: 15px;
}

.iti__flag.iti__md {
  background-position: -3160px 0;
  height: 10px;
}

.iti__flag.iti__me {
  background-position: -3182px 0;
  height: 10px;
}

.iti__flag.iti__mf {
  background-position: -3204px 0;
  height: 14px;
}

.iti__flag.iti__mg {
  background-position: -3226px 0;
  height: 14px;
}

.iti__flag.iti__mh {
  background-position: -3248px 0;
  height: 11px;
}

.iti__flag.iti__mk {
  background-position: -3270px 0;
  height: 10px;
}

.iti__flag.iti__ml {
  background-position: -3292px 0;
  height: 14px;
}

.iti__flag.iti__mm {
  background-position: -3314px 0;
  height: 14px;
}

.iti__flag.iti__mn {
  background-position: -3336px 0;
  height: 10px;
}

.iti__flag.iti__mo {
  background-position: -3358px 0;
  height: 14px;
}

.iti__flag.iti__mp {
  background-position: -3380px 0;
  height: 10px;
}

.iti__flag.iti__mq {
  background-position: -3402px 0;
  height: 14px;
}

.iti__flag.iti__mr {
  background-position: -3424px 0;
  height: 14px;
}

.iti__flag.iti__ms {
  background-position: -3446px 0;
  height: 10px;
}

.iti__flag.iti__mt {
  background-position: -3468px 0;
  height: 14px;
}

.iti__flag.iti__mu {
  background-position: -3490px 0;
  height: 14px;
}

.iti__flag.iti__mv {
  background-position: -3512px 0;
  height: 14px;
}

.iti__flag.iti__mw {
  background-position: -3534px 0;
  height: 14px;
}

.iti__flag.iti__mx {
  background-position: -3556px 0;
  height: 12px;
}

.iti__flag.iti__my {
  background-position: -3578px 0;
  height: 10px;
}

.iti__flag.iti__mz {
  background-position: -3600px 0;
  height: 14px;
}

.iti__flag.iti__na {
  background-position: -3622px 0;
  height: 14px;
}

.iti__flag.iti__nc {
  background-position: -3644px 0;
  height: 10px;
}

.iti__flag.iti__ne {
  background-position: -3666px 0;
  height: 15px;
}

.iti__flag.iti__nf {
  background-position: -3686px 0;
  height: 10px;
}

.iti__flag.iti__ng {
  background-position: -3708px 0;
  height: 10px;
}

.iti__flag.iti__ni {
  background-position: -3730px 0;
  height: 12px;
}

.iti__flag.iti__nl {
  background-position: -3752px 0;
  height: 14px;
}

.iti__flag.iti__no {
  background-position: -3774px 0;
  height: 15px;
}

.iti__flag.iti__np {
  background-position: -3796px 0;
  height: 15px;
}

.iti__flag.iti__nr {
  background-position: -3811px 0;
  height: 10px;
}

.iti__flag.iti__nu {
  background-position: -3833px 0;
  height: 10px;
}

.iti__flag.iti__nz {
  background-position: -3855px 0;
  height: 10px;
}

.iti__flag.iti__om {
  background-position: -3877px 0;
  height: 10px;
}

.iti__flag.iti__pa {
  background-position: -3899px 0;
  height: 14px;
}

.iti__flag.iti__pe {
  background-position: -3921px 0;
  height: 14px;
}

.iti__flag.iti__pf {
  background-position: -3943px 0;
  height: 14px;
}

.iti__flag.iti__pg {
  background-position: -3965px 0;
  height: 15px;
}

.iti__flag.iti__ph {
  background-position: -3987px 0;
  height: 10px;
}

.iti__flag.iti__pk {
  background-position: -4009px 0;
  height: 14px;
}

.iti__flag.iti__pl {
  background-position: -4031px 0;
  height: 13px;
}

.iti__flag.iti__pm {
  background-position: -4053px 0;
  height: 14px;
}

.iti__flag.iti__pn {
  background-position: -4075px 0;
  height: 10px;
}

.iti__flag.iti__pr {
  background-position: -4097px 0;
  height: 14px;
}

.iti__flag.iti__ps {
  background-position: -4119px 0;
  height: 10px;
}

.iti__flag.iti__pt {
  background-position: -4141px 0;
  height: 14px;
}

.iti__flag.iti__pw {
  background-position: -4163px 0;
  height: 13px;
}

.iti__flag.iti__py {
  background-position: -4185px 0;
  height: 11px;
}

.iti__flag.iti__qa {
  background-position: -4207px 0;
  height: 8px;
}

.iti__flag.iti__re {
  background-position: -4229px 0;
  height: 14px;
}

.iti__flag.iti__ro {
  background-position: -4251px 0;
  height: 14px;
}

.iti__flag.iti__rs {
  background-position: -4273px 0;
  height: 14px;
}

.iti__flag.iti__ru {
  background-position: -4295px 0;
  height: 14px;
}

.iti__flag.iti__rw {
  background-position: -4317px 0;
  height: 14px;
}

.iti__flag.iti__sa {
  background-position: -4339px 0;
  height: 14px;
}

.iti__flag.iti__sb {
  background-position: -4361px 0;
  height: 10px;
}

.iti__flag.iti__sc {
  background-position: -4383px 0;
  height: 10px;
}

.iti__flag.iti__sd {
  background-position: -4405px 0;
  height: 10px;
}

.iti__flag.iti__se {
  background-position: -4427px 0;
  height: 13px;
}

.iti__flag.iti__sg {
  background-position: -4449px 0;
  height: 14px;
}

.iti__flag.iti__sh {
  background-position: -4471px 0;
  height: 10px;
}

.iti__flag.iti__si {
  background-position: -4493px 0;
  height: 10px;
}

.iti__flag.iti__sj {
  background-position: -4515px 0;
  height: 15px;
}

.iti__flag.iti__sk {
  background-position: -4537px 0;
  height: 14px;
}

.iti__flag.iti__sl {
  background-position: -4559px 0;
  height: 14px;
}

.iti__flag.iti__sm {
  background-position: -4581px 0;
  height: 15px;
}

.iti__flag.iti__sn {
  background-position: -4603px 0;
  height: 14px;
}

.iti__flag.iti__so {
  background-position: -4625px 0;
  height: 14px;
}

.iti__flag.iti__sr {
  background-position: -4647px 0;
  height: 14px;
}

.iti__flag.iti__ss {
  background-position: -4669px 0;
  height: 10px;
}

.iti__flag.iti__st {
  background-position: -4691px 0;
  height: 10px;
}

.iti__flag.iti__sv {
  background-position: -4713px 0;
  height: 12px;
}

.iti__flag.iti__sx {
  background-position: -4735px 0;
  height: 14px;
}

.iti__flag.iti__sy {
  background-position: -4757px 0;
  height: 14px;
}

.iti__flag.iti__sz {
  background-position: -4779px 0;
  height: 14px;
}

.iti__flag.iti__ta {
  background-position: -4801px 0;
  height: 10px;
}

.iti__flag.iti__tc {
  background-position: -4823px 0;
  height: 10px;
}

.iti__flag.iti__td {
  background-position: -4845px 0;
  height: 14px;
}

.iti__flag.iti__tf {
  background-position: -4867px 0;
  height: 14px;
}

.iti__flag.iti__tg {
  background-position: -4889px 0;
  height: 13px;
}

.iti__flag.iti__th {
  background-position: -4911px 0;
  height: 14px;
}

.iti__flag.iti__tj {
  background-position: -4933px 0;
  height: 10px;
}

.iti__flag.iti__tk {
  background-position: -4955px 0;
  height: 10px;
}

.iti__flag.iti__tl {
  background-position: -4977px 0;
  height: 10px;
}

.iti__flag.iti__tm {
  background-position: -4999px 0;
  height: 14px;
}

.iti__flag.iti__tn {
  background-position: -5021px 0;
  height: 14px;
}

.iti__flag.iti__to {
  background-position: -5043px 0;
  height: 10px;
}

.iti__flag.iti__tr {
  background-position: -5065px 0;
  height: 14px;
}

.iti__flag.iti__tt {
  background-position: -5087px 0;
  height: 12px;
}

.iti__flag.iti__tv {
  background-position: -5109px 0;
  height: 10px;
}

.iti__flag.iti__tw {
  background-position: -5131px 0;
  height: 14px;
}

.iti__flag.iti__tz {
  background-position: -5153px 0;
  height: 14px;
}

.iti__flag.iti__ua {
  background-position: -5175px 0;
  height: 14px;
}

.iti__flag.iti__ug {
  background-position: -5197px 0;
  height: 14px;
}

.iti__flag.iti__um {
  background-position: -5219px 0;
  height: 11px;
}

.iti__flag.iti__un {
  background-position: -5241px 0;
  height: 14px;
}

.iti__flag.iti__us {
  background-position: -5263px 0;
  height: 11px;
}

.iti__flag.iti__uy {
  background-position: -5285px 0;
  height: 14px;
}

.iti__flag.iti__uz {
  background-position: -5307px 0;
  height: 10px;
}

.iti__flag.iti__va {
  background-position: -5329px 0;
  height: 15px;
}

.iti__flag.iti__vc {
  background-position: -5346px 0;
  height: 14px;
}

.iti__flag.iti__ve {
  background-position: -5368px 0;
  height: 14px;
}

.iti__flag.iti__vg {
  background-position: -5390px 0;
  height: 10px;
}

.iti__flag.iti__vi {
  background-position: -5412px 0;
  height: 14px;
}

.iti__flag.iti__vn {
  background-position: -5434px 0;
  height: 14px;
}

.iti__flag.iti__vu {
  background-position: -5456px 0;
  height: 12px;
}

.iti__flag.iti__wf {
  background-position: -5478px 0;
  height: 14px;
}

.iti__flag.iti__ws {
  background-position: -5500px 0;
  height: 10px;
}

.iti__flag.iti__xk {
  background-position: -5522px 0;
  height: 15px;
}

.iti__flag.iti__ye {
  background-position: -5544px 0;
  height: 14px;
}

.iti__flag.iti__yt {
  background-position: -5566px 0;
  height: 14px;
}

.iti__flag.iti__za {
  background-position: -5588px 0;
  height: 14px;
}

.iti__flag.iti__zm {
  background-position: -5610px 0;
  height: 14px;
}

.iti__flag.iti__zw {
  background-position: -5632px 0;
  height: 10px;
}

.iti__flag {
  background-color: #dbdbdb;
  background-image: url("flags.efd385eb.png");
  background-position: 20px 0;
  background-repeat: no-repeat;
  height: 15px;
  box-shadow: 0 0 1px #888;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("flags@2x.276cbc14.png");
  }
}

.iti__flag.iti__np {
  background-color: rgba(0, 0, 0, 0);
}

/*# sourceMappingURL=index.ebc2bbf4.css.map */
