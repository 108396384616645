.media-viewer {
	// position: absolute;
	// top: 0;
	// left: 0;
	// right: 0;
	z-index: 2;
	/* width: 200px; */
	/* height: 200px; */
	padding: 12px 18px 8px 18px;
	background: var(--panel-background-colour);
	border: solid 1px var(--border-colour-1);
	border-radius: 10px;
	max-width: 100%;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;

	margin-bottom: 1rem;
	flex-grow: 1;

	h2 {
		flex-shrink: 0;
		margin-bottom: 12px !important;
	}

	// .row {
	// 	align-items: center;
	// 	justify-content: center;
	// }

	&__footer {
		width: 100%;
		display: flex;
		flex-direction: row;
		// align-items: center;
		justify-content: space-between;
	}

	&__media-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		height: 1px;
		margin-bottom: 8px;
		width: 100%;

		/* Update text: */
		p {
			width: 90%;
			max-width: 840px;
			max-height: 100%;
			overflow: auto;
			white-space: pre-wrap;
			border-radius: 10px;
			padding: 14px 24px;
			background-color: white;
			border: solid 1px var(--border-colour-1);
		}

		.toolbar {
			display: none !important;
		}
	}

	img {
		object-fit: contain;
		max-width: 100%;
		max-height: 100%;
		cursor: zoom-in;
	}

	video {
		object-fit: contain;
		max-width: 100%;
		max-height: 100%;
		width: 100%;
		cursor: zoom-in;
	}

	.button {
		margin: 0 8px;
	}

	.pdf-iframe,
	.pdf-viewer {
		max-height: 100%;
		margin-right: -8px;

		border-top: solid 1px var(--border-colour-1);
		border-bottom: solid 1px var(--border-colour-1);

		*::-webkit-scrollbar-track {
			border-radius: 0;
		}

		// overflow-x: hidden;
		.react-pdf__Document {
			max-width: 100%;
		}

		.react-pdf__Page {
			margin-bottom: 16px;
			margin-right: 0px;
			border: solid 1px var(--border-colour-1);
			// border-left: none;
			// border-right: none;
			// margin-right: 5px;
		}

		.react-pdf__Page:first-child {
			border-top: none;
		}

		.react-pdf__Page:last-child {
			border-bottom: none;
		}
		.react-pdf__Page__textContent,
		.react-pdf__Page__annotations {
			display: none;
		}
		.react-pdf__Page__canvas {
			width: auto !important;
			height: auto !important;
			max-width: 100%;
		}
	}

	img,
	video {
		border: solid 1px var(--border-colour-1);
	}
}
