$orange: #f07e26;
$offorange: #aaaaaa;
$darkorange: #444444;
$lightblue: #004c72;
$blue: #0c1a2f;
$darkblue: hsl(216, 59%, 8%);
$orange: #f07e26;

$darkteal: #006853;
$lightteal: #1de9b6;
$green: #15b542;
$grey: #232323;
$lightgrey: #eef1f2;

body.tsoc {
	--accent-colour-1: #{$lightteal};
	--header-border-colour: #{$lightteal};

	--button-colour: #{$darkteal};
	--button-hover-colour: #{$lightteal};
	--accent-colour-2: #{$darkteal};
	--button-2-hover-colour: #{$darkteal};

	--header-colour: #{$darkteal};
	--header-text-colour: white;

	--border-colour-2: #{$lightteal};

	// --heading-colour: #{$blue};
	--heading-text-colour: #{$darkteal};
	--heading-border-colour: #{$lightteal};

	--sidebar-colour: #{$grey};

	// --login-text-colour: #{white};
	--login-button-colour: #{$darkteal};
	--login-button-icon-colour: white;

	--login-button-hover-colour: #{$lightteal};
	--login-button-hover-icon-colour: white;

	--login-background-colour: #{$darkteal};
	--logo-1: url('/src/themes/tsoc/Department_for_Transport_reversed_withline.svg');
	// --logo-3: url('/src/themes/networkrail/network_logo_320.png');
	--logo-4: url('/src/themes/tsoc/dft-widelogo-reverse.svg');
	--logo-5: url('/src/themes/tsoc/dft-widelogo.svg');
	--logo-5-width: 175px;
	--logo-4-width: 175px;

	--scrollbar-background-colour: #{transparentize($orange, 0.9)};

	--primary-button-colour: #{$green};
}
/*
I have attached a couple of versions of our corporate logo for ease. 

Please see below the preferred colour combination in our current brand guidelines. 

If possible, we would love to have something along the lines of 
‘Transport Security Operations Centre’, 
‘TSOC’ or ‘TSOC Training and Exercising’ on that page as well.

#006853
#1DE9B6
#15B542
#232323
#EEF1F2

*/
