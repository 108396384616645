.language-selector {
	max-width: 100%;

	&__option {
		display: flex;
		align-items: center;
		padding: 0.4rem 1rem;
		font-size: 1.1rem;
		justify-content: space-between;
		&--focused,
		&:hover {
			background-color: var(--accent-colour-1);
			color: white;
		}
		span {
			margin: 0 !important;
		}
		span:nth-child(2) {
			opacity: 0.5;
		}
	}

	&__control &__placeholder {
		color: #bbbbbb;
	}

	&__input {
		input {
			transition: none;
		}
	}

	&__indicators {
		span {
			margin: 5px 0 !important;
		}
	}

	&__single-value {
		line-height: 1.5rem;
	}
}
