.tab__row {
	display: flex;
	transform: translateY(1px);
	// margin-top: 0.8rem;
}

.tab {
	border: solid 1px var(--border-colour-1);
	padding: 2px 2rem 0 2rem;
	border-top-left-radius: $section-border-radius;
	border-top-right-radius: $section-border-radius;
	margin-right: 0.6rem;
	transition: border-color 300ms, background-color 300ms;
	cursor: pointer;

	* {
		cursor: pointer;
	}

	h3 {
		line-height: 2rem !important;
		margin: 0;
		font-family: $base-font-family;
	}
}

.tab--inactive {
	background-color: $white;
	border-bottom: none;
	transform: translateY(-1px);

	&:hover {
		transition: none;
		border-top-color: var(--button-colour);
		border-right-color: var(--button-colour);
		border-left-color: var(--button-colour);
		background-color: var(--button-colour);
		h3 {
			color: white;
		}
	}
}

.tab--active {
	border: solid 1px var(--primary-button-colour);
	background-color: var(--primary-button-colour);
	h3 {
		color: white !important;
	}
}

.tab__content--active {
	border-top-left-radius: 0;
}

.tab__content--inactive {
	display: none;
}

.tabs-container {
	flex-grow: 1;
}
