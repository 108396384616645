@use 'sass:math';

@mixin tutorConfigPanel() {
	max-width: 800px;
	width: 100%;
	display: flex;
	flex-direction: column;

	background-color: $config-panel-background-colour;
	// padding: 8px 16px;

	&__top {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		flex-shrink: 0;
		min-height: 68px;
		padding-left: 16px;
		padding-right: 8px;
		// margin-bottom: 8px;
	}

	&__content {
		overflow: auto;
		min-height: 72px; // Space for preloader
		padding-left: 8px;
		padding-right: 8px;
	}
}

@mixin configPanelCard() {
	border: solid 1px $border-color;
	border-radius: $section-border-radius;

	background-color: white;

	margin: 0px;
	margin-bottom: math.div($vertical-spacing, 3);
	padding: 16px 20px;
}

$browser-context: 16; // Default

@function pxToEms($pixels, $context: $browser-context) {
	@return #{$pixels/$context}em;
}

@function relativePixels($pixels) {
	@return #{calc((1rem / 16) * $pixels)};
}

@mixin simpleRow() {
	border: solid 3px var(--base-text-colour);
	border-radius: $section-border-radius;
	display: flex;
	padding: 8px 16px;
	margin: 4px;
	background-color: white;
	display: flex;
	color: var(--base-text-colour);
	width: 100%;

	.name {
		font-weight: bold;
		text-align: left;
		text-transform: capitalize;
	}
	.icon {
		flex-grow: 1;
		position: relative;
		top: -1px;
		height: auto;
		text-align: left;
		margin-left: 20px;
	}
}

@mixin ellipsisTruncate() {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

@mixin caps() {
	text-transform: uppercase;
	letter-spacing: 1px;
}
