.new-media-alert {
	// padding: 0;
	// margin: 0;
	// border: none;
	// background: none;
	// font-size: 0;
	// line-height: 0;
	// text-align: left;
	// position: relative;
	// display: block;
	width: 100%;
	// height: 100%;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;

	&__alert {
		position: relative;
		height: 45px;
		width: 100%;

		margin-top: 3px;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		background-color: var(--red);
		color: var(--white);
		font-size: 0.8rem;

		animation: 0.8s $default-easing 0s 1 slideInFromLeft, 0.8s steps(1) 0s 20 flashNotificationRed;

		&:first-child {
			margin-top: 0;
		}

		> * {
			opacity: 0.7;
		}

		.icon-button {
			height: 18px;
			font-size: 18px;
			width: 16px;
			position: absolute;
			top: 4px;
			right: 7px;
			opacity: 0.6;
			&:hover {
				background-color: transparent;
				opacity: 1;
			}
		}
	}
	&__title {
		font-size: 1rem;
		text-transform: uppercase;
		opacity: 1;
		margin-bottom: 1px;
	}
}
