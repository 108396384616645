.video-call-buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 7px;
	margin-bottom: 6px;

	.icon-button {
		margin: 0 6px 5px;
		padding: 7px;
		height: 48px;
		width: 48px;
		min-width: 48px;
		border-radius: 5px;

		.badge {
			bottom: -4px;
			right: -4px;
			border: none;
		}
	}

	.icon-button {
		border: 1px solid transparent;
	}

	.icon-button:not(.icon-button--selected):not(:hover):not(:active) {
		background-color: white;
		border: 1px solid $mediumgrey;
	}

	/* Hang Up button */
	.video-call-buttons__hang-up.icon-button:not(.icon-button--selected):not(:hover):not(:active) {
		background-color: $red;
		border-color: $red;
		transition: background-color 0ms;

		.icon {
			color: white;
		}
	}
}

.tutor-view {
	.video-call-buttons {
		.icon-button {
			border-radius: 99px;
		}
	}
}
