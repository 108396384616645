.groups-list {
	border: solid 1px var(--border-colour-1);
	border-radius: $section-border-radius;
	padding-top: 1rem;

	display: flex;
	flex-direction: column;
	align-items: stretch;

	flex-grow: 1;
	flex-shrink: 0;

	width: 100%;
	max-width: 100%;
	min-width: 100%;

	font-weight: bold;
	color: var(--base-text-colour);
	padding-left: 1rem;
	padding-right: 1rem;

	&__group {
		margin: 0 0 0.5rem 0;
		padding: 0;

		border-radius: 999px;

		border: solid 1px transparent;
		background-color: $list-item-background-color;
		transition: background-color 200ms, border-color 200ms;

		display: flex;
		align-items: center;
		text-transform: capitalize;
		overflow: hidden;

		cursor: pointer;
		* {
			cursor: pointer;
		}
		span {
			@include ellipsisTruncate;
		}
	}

	&__group:hover {
		background-color: $list-item-selected-background-color;
	}

	&__group--selected,
	&__group--selected:hover {
		border: solid 1px var(--accent-colour-1);
		background-color: $list-item-selected-background-color;
	}

	.dot {
		width: 2rem;
		height: 2rem; // same as .circle in messages
		border-radius: 50%;
		margin: 5px;
		margin-right: 0.8rem;
		flex-shrink: 0;
	}

	.none-found {
		background-color: transparent;
		text-align: center;
	}

	.badge {
		background-color: $red;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		float: right;
		margin-left: 1rem;
	}
}
