$content-border-color: $border-color;
$content-border-radius: $section-border-radius;
$content-border-colour: var(--accent-colour-1);
$content-border-width: 2px;
$max-height: 25vh;

.initial-information {
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: box-shadow 0.3s;
	border: solid 1px $content-border-color;
	border-radius: $content-border-radius;
	background-color: white;

	min-height: 6rem;

	padding: 0;
	padding-bottom: 0.5rem;
	overflow: hidden;

	// position: relative;

	&__title {
		margin-bottom: -19px !important;
		display: inline-block;
		z-index: 1;
	}

	p {
		white-space: pre-wrap;
		margin: 0;
	}

	&__text-wrapper {
		width: 100%;
		overflow: hidden;
		max-height: $max-height;
		overflow-y: auto;

		margin-top: 1rem;
		padding: 0.2rem 1.5rem 0.2rem 1.5rem;
		margin-bottom: 0.5rem;
	}

	&__translate {
		flex-shrink: 0;
		font-size: $small-font-size;
		line-height: $small-font-size;
		margin-top: 0.1rem;
		margin-bottom: 0.1rem;
		opacity: 0.8;

		// position: absolute;
		// bottom: 1px;

		transition: color 200ms;
		color: var(--accent-colour-2) !important;

		cursor: pointer;
		* {
			cursor: pointer;
		}

		&:hover,
		&--active {
			opacity: 1;
		}
	}
}

.initial-information--with-shadow {
	border-bottom: solid 1px rgba(0, 0, 0, 0.17);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06), 0 6px 6px rgba(0, 0, 0, 0.1);
}
