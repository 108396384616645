.add-decision-log {
	display: flex;
	flex-direction: column;
	width: 100%;

	h2 {
		width: auto;
	}

	.button-row {
		grid-column-gap: 10px;
	}

	.input-row {
		width: 100%;

		display: grid;
		grid-column-gap: 10px;
		grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
		grid-row-gap: 2px;

		.textarea {
			margin: 0;
			min-height: 100px;
			max-height: 40vh;
		}
	}
	> .row:last-child {
		justify-content: flex-end;
	}
	.row {
		margin-bottom: 4px;
	}
}
