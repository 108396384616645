.messages-app {
	width: 100%;
	height: 100%;
	overflow: hidden;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	p {
		margin-top: 15%;
		width: 66%;
		text-align: center;
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		// background-color: rgba(0, 0, 0, 0.1);
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 66%, rgba(0, 0, 0, 0) 100%);
		z-index: -1;
	}

	> .row {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;

		h2 {
			color: white;
			margin: 0;
		}

		.icon-button {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 40px;
			height: 40px;
			padding: 0;
			border-radius: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			.icon {
				color: white;
				font-size: 20px;
			}
		}
	}

	&__content {
		background-color: white;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 3px 15px 0;
		height: 66%;
	}

	&__scroller {
		overflow-x: hidden;
		overflow-y: auto;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 10px;
	}

	.message-entry {
		display: flex;
		justify-content: center;
		margin-left: 14px;
		margin-right: 14px;

		.message-entry__wrapper {
			border-radius: 19px;
		}

		button {
			border-radius: 19px;
		}
	}

	.message-entry:not(.message-entry--disabled) {
		.message-entry__wrapper {
			border-color: #999999;
		}
	}

	.message {
		width: calc(100% - 30px);
		background-color: var(--lightlightgrey);
		padding-left: 13px;
		padding-bottom: 4px;

		margin-bottom: 7px;

		flex-shrink: 0;

		margin-left: 0;
		margin-right: 30px;
		animation: 1s $easing 0s 1 slideInFromLeft;

		&__at,
		&__name,
		&__from {
			text-transform: uppercase;
			font-size: var(--small-font-size);
			font-style: normal;
			font-weight: 700;
			line-height: 14px;
			letter-spacing: 1px;
			text-align: left;
		}

		&__at {
			text-align: right;
			font-weight: 400;
		}

		&__top {
			// margin-bottom: 0px;
		}

		&__left {
			display: none;
		}
		&__right {
			padding-bottom: 4px;
		}

		&__text {
			font-size: var(--small-font-size);
			font-style: normal;
			font-weight: 400;
			line-height: 1.3rem;
			letter-spacing: 0em;
			text-align: left;
			margin-bottom: 3px;
		}

		&__translate {
			line-height: 1.3rem;
			font-size: var(--small-font-size);
			opacity: 0.6;
		}
		&__translate:hover {
			opacity: 1;
		}

		&--group,
		&--participant {
			animation: none;
			background-color: var(--button-colour);
			color: var(--button-text-colour);
			margin-left: 30px;
			margin-right: 0;
			.message__translate {
				color: white !important;
			}
		}
	}
}
