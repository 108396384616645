.none-found {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 58px;
	margin: 6px 0px;
	padding: 0px 16px;

	border: none;
	border-radius: $section-border-radius;

	background-color: rgba(255, 255, 255, 1);
	color: rgba(0, 0, 0, 0.5);

	font-size: var(--primary-heading-size);
	font-weight: 400;

	user-select: none;
}
