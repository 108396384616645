.media-phone-modal {
	&__main {
		width: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;

		> .icon {
			color: var(--accent-colour-2);
			font-size: 64px;
			border: solid 4px var(--accent-colour-2);
			border-radius: 999px;
			padding: 25px;
			margin: 1rem;
		}

		> .phone-preloader {
			margin-top: 1.2rem;
			margin-bottom: 1.2rem;
		}

		h2 {
			margin-bottom: 2rem;
		}

		p {
			text-align: center;
			margin-top: 0rem;
			margin-bottom: 0.7rem;
		}

		.button {
			margin: 1.3rem 5px 0 5px;
		}
	}
}

.modal-media-for-phone {
	width: 500px;
	max-width: 100%;
	min-width: 500px;
	.modal__header {
		height: 32px;
		position: relative;
		.modal__title {
			text-align: center;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.icon-button {
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}
