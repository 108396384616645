$lightgrey: #ebebeb;
$darkgrey: #4b4a4a;
$black: #1f1f1f;

$yellow-1: hsl(44, 70%, 58%);
$yellow-2: hsl(44, 90%, 64%);

body.n324 {
	--accent-colour-1: #{$lightgrey};

	--button-colour: #{$yellow-1};
	--button-hover-colour: #{$yellow-2};
	--accent-colour-2: #{$black};
	--button-2-hover-colour: #{$yellow-1};

	--header-colour: #{$black};
	--header-text-colour: white;
	--header-border-colour: #{$yellow-1};

	--border-colour-2: #{$lightgrey};

	--heading-colour: #{$black};
	--heading-text-colour: #{$black};
	--heading-border-colour: #{$lightgrey};

	--sidebar-colour: #{$darkgrey};

	--login-button-colour: #{$lightgrey};
	--login-button-icon-colour: #{$black};

	--login-button-hover-colour: #{$yellow-1};
	--login-button-hover-icon-colour: white;

	--login-background-colour: #{$black};
	--logo-4-width: 60px;
	--logo-1: url('/src/themes/324/logo-white.png');
	--logo-3: url('/src/themes/324/logo.png');
	--logo-4: url('/src/themes/324/logo-star.png');
	--scrollbar-background-colour: #{transparentize($yellow-1, 0.9)};

	--primary-button-colour: black;
	--primary-button-hover-colour: #{$nickel};
}
