/*
 * Stylesheet for App view
 */

.app {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	margin: 0 auto;

	display: flex;
	flex-direction: row;
	align-items: stretch;
}
