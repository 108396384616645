body {
	padding: 0;
	margin: 0;
	overflow: hidden;
	color: var(--base-text-colour);
}

* {
	box-sizing: border-box;
	cursor: default;
	scrollbar-width: thin;
}

.row {
	display: flex;
	flex-direction: row;
}

.spacer {
	width: 1px;
	flex-grow: 1;
}

/* Emergency exceptions */
// div.message__text,
// div.decision-log__box {
// 	user-select: text !important;
// 	cursor: auto !important;
// }
