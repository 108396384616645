.full-screen-participant {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	background-color: black;

	// background-color: $login-background-color;
	// background-image: var(--logo-1);
	// background-size: 80px;
	// background-repeat: no-repeat;
	// background-position: center center;

	transition: border-color 700ms, box-shadow 700ms;

	// margin: 0.2rem 0rem;
	// border-radius: 0;

	// width: $full-screen-video-width;
	// box-sizing: content-box;

	video {
		width: 100%;
		max-width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	&__display-name {
		position: absolute;
		bottom: 64px;
		left: 0;
		right: 0;

		width: 100%;
		max-width: 100%;

		text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 4px black;
		color: white;
		font-size: 1.5rem;
		line-height: 4rem;
		text-align: center;
		font-weight: normal;

		padding-top: 2px;
		padding-left: 0.5rem;
		padding-right: 0.5rem;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	// &:hover {
	// 	.full-screen-participant__display-name {
	// 		color: black;
	// 		font-weight: bold;
	// 		text-shadow: none;
	// 		background-color: rgba(255, 255, 255, 0.8);
	// 	}
	// }

	&__icons {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		text-shadow: 0px 0px 2px black, 0px 0px 4px black;
		.icon {
			margin: 1rem;
			font-size: 3rem;
		}
	}

	&--novideo {
		video {
			display: none;
		}
	}

	&--screenshare {
		video {
			object-fit: contain;
			width: calc(100% - (var(--filmstrip-width) + 17px));
		}
	}

	&--talking-1 {
		border-color: var(--accent-colour-2);
		box-shadow: 0px 0px 0px 1px var(--accent-colour-2);
	}
	&--talking-2 {
		box-shadow: 0px 0px 2px 1px var(--accent-colour-2);
	}
	&--talking-3 {
		box-shadow: 0px 0px 3px 2px var(--accent-colour-2);
	}

	&__error {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		font-size: 1rem;
	}

	// .icon {
	// 	position: absolute;
	// 	top: 10px;
	// 	left: 15px;
	// }
}
