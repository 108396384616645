.chat {
	min-width: 300px;
	max-width: 300px;
	width: 300px;

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem 0;

	border-right: 1px solid var(--border-colour-1);

	&__scroller {
		flex-grow: 1;
		height: 1px;
		margin: 1rem 0;
		overflow-y: auto;
		padding: 0 0.6rem;
	}

	.message {
		margin-bottom: 8px;
	}

	&__scroller,
	.message-entry {
		width: 100%;
		padding: 0 0.6rem;
	}

	&__recipient-row {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;

		label {
			margin-left: 8px;
		}
		.input {
			flex-grow: 1;
			margin: 0;
			margin-bottom: 7px;
			margin: 6px;
			margin-right: 10px;
			width: 1px;
		}
	}

	.message-entry__wrapper {
		border-radius: $input-border-radius;
	}
}
