$sidebar-button-background-color: var(--accent-colour-1);
$sidebar-button-icon-color: white;
$sidebar-button-hover-background-color: var(--accent-colour-2);
$sidebar-button-hover-icon-color: white;

$sidebar-button-border-color: var(--accent-colour-1);

$width: 90px;

.group-sidebar {
	display: flex;
	flex-direction: column;
	align-items: center;

	background-color: $white;
	min-width: $width;
	max-width: $width;
	padding: 1rem 0.2rem;

	&__top,
	&__bottom {
		display: flex;
		flex-direction: column;
	}
	&__top {
		flex-grow: 1;
	}

	// ===============================================================================================

	.team-badge {
		margin-bottom: 2rem;
		&__label {
			width: 1rem;
			display: flex;
			justify-content: center;
		}
		&__circle {
			width: $width * 0.6;
			height: $width * 0.6;
		}
	}

	.team-badge__label,
	.sidebar-button__label {
		@include caps;
		font-size: 0.7rem;
		text-align: center;
		margin-top: 0.4rem;
	}

	// ===============================================================================================

	.sidebar-button {
		display: flex;
		flex-direction: column;
		align-items: center;
		outline: none;

		.icon-button {
			width: $width * 0.6;
			height: $width * 0.6;
			border-radius: 99px;
			background-color: $sidebar-button-background-color;
			// border: solid 1px $sidebar-button-border-color;
			.icon {
				color: $sidebar-button-icon-color;
			}
		}
	}

	.sidebar-button:hover,
	.sidebar-button:focus {
		outline: none;
		cursor: pointer;
		* {
			cursor: pointer;
		}

		.icon-button {
			// border: solid 1px $sidebar-button-background-color;
			background-color: $sidebar-button-hover-background-color;
			.icon {
				color: $sidebar-button-hover-icon-color;
			}
		}
	}
}
