.error {
	background-color: $red;
	color: white;
	text-align: center;
	padding: 10px 50px;
	border-radius: $section-border-radius;
	font-size: 17px;
	max-width: 100%;
	@include ellipsisTruncate;
}
