.participant.participant {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	overflow: hidden;

	h2 {
		line-height: 32px;
	}

	p {
		line-height: 20px;
		font-size: var(--small-font-size);
		text-transform: uppercase;
		color: rgba(0, 0, 0, 0.5);

		.icon {
			color: rgba(0, 0, 0, 0.5);
			margin-right: 0.5rem;
			margin-left: 0.5rem;
		}
	}

	h2,
	p {
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.col {
		position: relative;
		overflow: visible;
		align-items: center;
		justify-content: center;

		p {
			text-align: center;
			left: 0;
			right: 0;
			bottom: -18px;
		}
	}

	.dot {
		width: 24px;
		height: 24px;
		border-radius: 99px;
		transition: background-color 500ms ease-out;
	}

	.left {
		flex-grow: 1;
		width: 1px;
	}

	.right {
		flex-direction: row;
		align-items: center;
		width: auto;
		flex-grow: 0;
		flex-shrink: 0;
		opacity: 1;

		> * {
			margin: 0;
			margin-left: 1rem;
		}
		.dot {
			margin-left: 0;
		}

		.pill {
			margin-top: 5px;
			margin-bottom: 4px;
			min-width: 8.5rem;
		}
	}
}
