// $orange: #f07e26;
// $offorange: #aaaaaa;
// $darkorange: #444444;
// $lightblue: #004c72;
// $blue: #0c1a2f;
// $darkblue: hsl(216, 59%, 8%);
// $orange: #f07e26;

$optimism: #F78F31;
$stability: #1B8485;
$safety: #33B77F;
$energy: #BC120B;
$wisdom: #FFA513;
$trust: #102544;
$darkgreen: #084459;

body.ncosh {
	--accent-colour-1: #{$trust};
	--header-border-colour: #{$trust};

	--button-colour: #{$darkgreen};
	--button-hover-colour: #{$stability};
	--accent-colour-2: #{$darkgreen};
	--button-2-hover-colour: #{$safety};

	--header-colour: #{$stability};
	--header-text-colour: white;

	--border-colour-2: #{$stability};

	// --heading-colour: #{$blue};
	--heading-text-colour: #{$trust};
	--heading-border-colour: #{$stability};

	--sidebar-colour: #{$stability};

	--login-logo-width: 400px;
	// --login-text-colour: #{white};
	--login-button-colour: #{$trust};
	--login-button-icon-colour: white;

	--login-button-hover-colour: #{$wisdom};
	--login-button-hover-icon-colour: white;

	--login-background-colour: #{$darkgreen};
	// --logo-4-width: 275px;
	--logo-1: url('/src/themes/ncosh/ncosh_logo_white.svg');
	--logo-3: url('/src/themes/ncosh/ncosh_english_logo.svg');
	--logo-4: url('/src/themes/ncosh/ncosh_emblem_white.svg');
	--logo-5: url('/src/themes/ncosh/ncosh_emblem_sm.svg');
	--logo-5-width: 35px;

	--scrollbar-background-colour: #{transparentize($optimism, 0.9)};

	.status-bar__logo {
		height: 70% !important;
	}
	
	--primary-button-colour: #{$optimism};
  --primary-button-hover-colour: #{$wisdom};
}
