$easing: cubic-bezier(0.19, 1, 0.22, 1); // ease-out-exponential

.notification {
	position: relative;
	display: flex;
	align-items: center;
	padding: 1px 3px 1px 1px;
	height: auto;
	margin: 5px 0;
	border-radius: 12px;
	width: 100%;

	background-color: $lightred;
	color: var(--white);
	border: solid 1px transparent;
	animation: 0.8s $easing 0s 1 slideInFromLeft;

	&--seen {
		background-color: rgba(255, 255, 255, 0.5);
		color: $darkgrey !important;

		h3.notification__title {
			color: $darkgrey !important;
		}

		div.notification__time {
			color: $darkgrey !important;
		}

		div.notification__openicon, .icon-check {
			color: $darkgrey;
		}
	}

	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}

	cursor: pointer;
	* {
		cursor: pointer;
	}

	// &:hover {
	// 	background-color: $darkgrey;
	// }

	// &--seen:hover {
	// 	background-color: rgba(255, 255, 255, 0.7);
	// 	color: $darkgrey;
	// }

	.row {
		width: 100%;
	}

	h3.notification__title {
		font-size: relativePixels(14);
		font-style: normal;
		font-weight: 600;
		line-height: 21px;
		letter-spacing: 0em !important;
		text-align: left;
		text-transform: none !important;
		margin: 0;
		color: var(--white);

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__time,
	&__opentime,
	&__openicon {
		padding-right: 0.4rem;
	}

	&__time,
	&__opentime {
		color: var(--white);
		opacity: 0.4;
		font-size: var(--small-font-size);
		font-weight: 400;
		text-align: left;
		letter-spacing: 0.05em;
	}

	&__time {
		flex-grow: 1;
	}

	&__icon-container {
		margin: 4px;
		margin-right: 9px;
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		.icon {
			font-size: 19px;
			height: 19px;
			width: 19px;
		}
	}

	&__details {
		padding-left: 0;
		width: 0px;
		flex-grow: 1;
		background-color: transparent;
		.left {
			justify-content: space-evenly;
		}
	}
}

.participant-view {
	.notification__opentime {
		display: none;
	}
}

.notification--recent {
	.notification__details,
	.notification__details .icon {
		animation: none;
	}

	background-color: var(--red);
	color: var(--white);
	animation: 0.8s $easing 0s 1 slideInFromLeft, 0.8s steps(1) 0s infinite flashNotificationRed;
}

// =================================================================================================
