$background-colour: var(--header-colour);
$text-colour: var(--header-text-colour);
$border-colour: var(--header-border-colour);
$bottom-border: solid 3px $border-colour;
$status-bar-height: 4rem;

$status-bar-padding: 0px 20px 2px 20px;
$status-bar-logo-width: var(--logo-4-width);
$status-bar-logo-padding-right: 20px;
$status-bar-logo-margin-right: 20px;
$status-bar-title-width: 292px;
$status-bar-contents-height: 29px;

$spacing: 1.5rem;

.status-bar {
	display: flex;
	flex-direction: column;

	width: 100%;
	max-width: 100%;

	padding: 0;
	flex-grow: 0;
	flex-shrink: 0;
	z-index: 500;
	position: relative;

	color: $text-colour;

	&__top {
		height: $status-bar-height;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: $background-colour;
		border-bottom: solid 3px $border-colour;
	}

	&__stats {
		font-size: 0.9rem;
		text-align: right;
		margin-right: $spacing;
		transform: translateY(1px);
	}

	&__stat-label {
		opacity: 0.5;
		margin-right: 0.5rem;
	}

	.icon-button {
		border-radius: 0;
		width: $status-bar-height;
		height: calc(#{$status-bar-height} - 3px);
		// margin-right: -0.2rem;
		.icon {
			color: white;
		}
	}

	// 	&__button {
	// 	cursor: pointer;
	// 	position: relative;
	// 	outline: none;
	// 	width: $button-size;
	// 	height: $button-size;
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;

	// 	// Display label on button hover (unless on touch devices)
	// 	.no-touch &:hover .sidebar__label {
	// 		display: flex;
	// 		left: $button-size;
	// 	}

	// 	// For a11y, it must be possible to tab-focus on to the button itself, so we need to apply
	// 	// the hover style in that case
	// 	&:focus .icon-button:not(.icon-button--selected) {
	// 		background-color: $sidebar-button-hover-background-color;
	// 	}
	// }

	&__logo {
		width: $status-bar-logo-width;
		// width: auto;
		padding-right: $status-bar-logo-padding-right;
		height: 100%;
		flex-shrink: 0;

		background-image: var(--logo-4);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		// margin-left: $spacing;
		// margin-right: $spacing;
	}

	&__session-title {
		color: $border-colour;
		font-weight: $base-font-weight;
	}

	&__session-title,
	&__case-title {
		font-size: 1.1rem;
		// height: $status-bar-contents-height;
		overflow: visible;
		line-height: 1.5rem;
		// @include ellipsisTruncate;
	}

	&__case-title {
		font-weight: bold;
		flex-grow: 1;
		padding-left: 1rem;
		// transform: translateX(-1px);
		transform: translateY(2px);
		font-family: var(--heading-font-family);
	}

	&__translate {
		line-height: 1.3rem;
		font-size: 12px;
		opacity: 0.6;
	}
	&__translate:hover {
		opacity: 1;
	}

	.status-bar-message,
	.previous-session-message {
		flex-grow: 1;
		flex-shrink: 1;
		// margin: 0 1rem;
		margin-top: -3px; /* Overlap border */
	}

	.countdown {
		// padding-left: $spacing;
		// padding-right: $spacing;
		margin: 0;
	}
}
