.group-release-modal {
	// Content
	text-align: center;
	width: 400px;

	&__group {
		@include simpleRow();
		margin: 4px 0px;

		cursor: pointer;
		* {
			cursor: pointer;
		}
	}

	&__group--released {
		background-color: #dddddd;
		color: lighten($off-black, 20%);
		border-color: #dddddd;
		.icon {
			color: lighten($off-black, 20%);
		}
	}

	&__sentat {
		font-size: 0.88em;
		opacity: 0.8;
	}
}
