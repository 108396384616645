$width: 30px;

.group-colour-dot {
	width: $width;
	height: $width;
	border-radius: 99px;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: background-color 500ms ease-out;

	.icon {
		display: block;
		font-size: 14px;
		color: white;
		width: 14px;
		height: 14px;
		text-align: center;
	}
}
