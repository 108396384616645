.dark-mode-switch {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	opacity: 0.08;
	transition: opacity 1s $ease-out-exponential;

	.icon {
		color: black;
		transition: color 1s $ease-out-exponential;
		font-size: 20px;
	}

	.react-switch {
		margin: 0 8px 0 6px;
		.react-switch-bg {
			background-color: black !important;
			transition: background-color 1s $ease-out-exponential;
			svg {
				display: none;
			}
		}
		.react-switch-handle {
			background-color: white !important;
			transition: background-color 1s $ease-out-exponential;
		}
	}
}

.dark-theme {
	.dark-mode-switch {
		opacity: 0.15;

		.icon {
			color: white;
		}
		.react-switch-bg {
			background-color: white !important;
		}
		.react-switch-handle {
			background-color: black !important;
		}
	}
}
