$lightgrey: #ebebeb;
$darkgrey: #4b4a4a;
$black: #000000;
$purple: #9A46CF;
$blue: #00B9E4;
$pink: #F53F5B;

body.uh {
	--base-font-family: 'Arial', sans-serif;
	--login-font-family: 'Arial', sans-serif;
	--heading-font-family: 'Arial', sans-serif;
	--accent-colour-1: #{$lightgrey};
	--header-border-colour:$blue;

	--button-colour: #{$black};
	--button-hover-colour: #{$purple};
	--accent-colour-2: #{$black};
	--button-2-hover-colour: #{$purple};

	--header-colour: #{$black};
	--header-text-colour: white;
	--header-border-colour: #{$blue};

	--border-colour-2: #{$lightgrey};

	--heading-colour: #{$black};
	--heading-text-colour: #{$black};
	--heading-border-colour: #{$lightgrey};

	--primary-button-colour: #{$purple};

	--login-logo-width: 490px;

	--sidebar-colour: #{$black};

	--login-button-colour: #{$lightgrey};
	--login-button-icon-colour: #{$black};

	--button-colour: #{$black};
	--button-hover-colour: #{$purple};


	--login-button-hover-colour: #{$purple};
	--login-button-hover-icon-colour: white;

	--login-background-colour: #{$purple};
	--logo-4-width: 60px;
	--logo-1: url('/src/themes/uh/uh_bg_purple.svg');
	--logo-3: url('/src/themes/uh/uh_symbol_white.svg');
	--logo-4: url('/src/themes/uh/uh_symbol_white.svg');
	--logo-5: url('/src/themes/uh/uh_symbol_black.svg');
	--scrollbar-background-colour: #{transparentize($purple, 0.9)};

	--primary-button-colour: black;
	--primary-button-hover-colour: #{$purple};

	--panel-background-colour: #{$white};

	.login h1 {
		color: $purple;
		font-weight: 800;
	}

	.login h2 {
		color: $black;
		font-weight: 800;
	}

	.status-bar-message {
		background-color: $pink;
	}

	.session .msg {
		background-color: $blue;
	}
}
