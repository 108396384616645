.session {
	@include configPanelCard();

	border-radius: $section-border-radius;

	.msg {
		background: var(--primary-button-colour);
		color: var(--primary-button-text-colour);
		// border-radius: 99px;
		text-align: center;
		font-size: 16px;
		padding: 6px 16px;
		margin-left: -21px;
		margin-right: -21px;
		margin-top: -17px;
		margin-bottom: 4px;
		border-top-right-radius: 10px;
		line-height: 20px;
	}

	.msg.inRed {
		color: white;
		background: $red;
	}

	label {
		display: inline-block;
		margin: 0.8rem 0;
	}

	.top {
		display: flex;
		flex-direction: row;
		margin-bottom: 8px;

		.left {
			flex-grow: 1;
			margin-right: 20px;
		}
		.textarea {
			min-height: 72px;
		}
	}

	.textarea {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
		margin-top: 4px;
		margin-bottom: 4px;
		padding: 6px 10px;
		flex-grow: 1;
		resize: vertical;
	}

	.session__duration {
		position: relative;

		> span:first-child {
			font-weight: bold;
		}
		.input {
			width: 50px;
			display: inline-block;
			margin: 0px 10px;
		}
	}

	.session__duration-warning {
		text-align: right;
		white-space: nowrap;
		font-size: 0.8rem;
		color: $red;
		margin-right: 1rem;
		margin-top: 2px;
	}

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
		&.centred {
			justify-content: center;
		}
	}

	.row-spacer {
		flex-grow: 1;
	}

	.session__index {
		border-radius: 50%;
		background-color: rgba(60, 60, 60, 0.1);
		height: 32px;
		width: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		font-weight: bold;
		padding-top: 2px;
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		flex-basis: 128px;
		flex-shrink: 0;
	}

	input,
	select {
		font-family: inherit;
		padding: 4px 6px;
		border-radius: $input-border-radius;
		border: solid 1px $input-border-color;
		font-size: $base-font-size;
		outline: none;
		max-width: 100%;
		margin: 2px 6px;
	}

	input {
		width: 64px;
	}
}
