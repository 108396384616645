.tutor-videoconf {
	@include tutorConfigPanel();
	.section {
		.pill,
		.switch {
			position: absolute !important;
			top: 1.2rem;
			right: 1.5rem;
		}
		.pill {
			top: 1.3rem;
		}
		.row {
			justify-content: flex-end;
		}
	}
}

.tutor-videoconf__pill--online {
	background-color: var(--team-colour-green);
}
.tutor-videoconf__pill--starting {
	background-color: var(--team-colour-orange);
}
.tutor-videoconf__pill--stopping {
	background-color: var(--team-colour-orange);
}
.tutor-videoconf__pill--enabled {
	background-color: var(--team-colour-green);
}
