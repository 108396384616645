// =================================================================================================
$animation__slideInUp__duration: 300ms;
$animation__slideInUp__distance: 100px;

// =================================================================================================

.animation__slideInUp-enter {
	opacity: 0;
	transform: translateY($animation__slideInUp__distance);
}

.animation__slideInUp-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: opacity $animation__slideInUp__duration, transform $animation__slideInUp__duration;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.animation__slideInUp-enter-done {
	transform: translateY(0);
	opacity: 1;
}

.animation__slideInUp-exit {
	transform: translateY(0);
	opacity: 1;
}

.animation__slideInUp-exit-active {
	opacity: 0;
	transform: translateY($animation__slideInUp__distance);
	transition: opacity $animation__slideInUp__duration, transform $animation__slideInUp__duration;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.animation__slideInUp-exit-done {
	opacity: 0;
	transform: translateY($animation__slideInUp__distance);
}

@keyframes slideInFromLeft {
	0% {
		transform: translateX(-40%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes flashNotificationRed {
	0% {
		background-color: var(--red);
		color: var(--white);
	}
	50% {
		background-color: var(--darkred);
		color: var(--white);
	}
}

@keyframes slideInFromRight {
	0% {
		transform: translateX(40%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
