$message-entry-height: 2.3rem;

.message-entry {
	display: flex;
	flex-direction: row;
	position: relative;

	label {
		position: absolute;
		top: 1px;
		left: 0;
		height: calc($message-entry-height + 2px);
		margin-top: calc(($message-entry-height - 14px) / 2);
		line-height: 14px;
		height: 14px + 6px;
		padding: 0 1rem;
		text-transform: none;
		transition: top 0.1s $default-easing, padding 0.1s $default-easing;

		border-radius: 99px;
		background: none;
	}

	&.message-entry--focus {
		label {
			top: calc(-1 * (14px + 20px));
			padding: 3px 0.7rem;
			background: white;
		}
	}

	&__wrapper {
		flex-grow: 1;
		border: solid 1px $input-border-color;
		border-radius: $section-border-radius;
		overflow: hidden;
	}

	textarea {
		width: 100%;
		border: none;
		display: flex;
		align-items: center;
		line-height: calc($base-line-height + 2px);
		height: $message-entry-height;
		max-height: 7rem;
		resize: none;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.button {
		margin-left: 0.5rem;
		button {
			margin: 0;
			padding-left: 1rem;
			padding-right: 1rem;
			text-align: center;
			height: $message-entry-height;
		}
		.button__text {
			transform: translateY(1px);
		}
	}
}

.message-entry--disabled {
	.message-entry__wrapper {
		border: solid 1px $input-disabled-border-colour;
	}
}

.message-entry:not(.message-entry--disabled) {
	.message-entry__wrapper {
		&:hover,
		&:focus-within {
			border-color: var(--accent-colour-1);
		}
	}
}
