@import '../fonts/lato/lato'; // --------------------- Base font
@import '../fonts/nunito/nunito'; // ----------------- Headings
@import '../fonts/open-sans/open-sans'; // ----------- Participant view
@import '../fonts/rubrik/rubrik'; // ----------------- Logo font - used for login page headings
@import '../fonts/icomoon/style'; // ----------------- Icons

$base-font-family: Lato, Calibri, sans-serif;
$heading-font-family: Nunito, Roboto, sans-serif;
$login-font-family: Rubrik, sans-serif;

$base-font-size: 1rem; // 16px
$base-line-height: calc((1rem / 16) * 17); // 17px
$base-font-weight: 500;
$heading-font-weight: 700;

$small-font-size: calc((1rem / 16) * 13); // 13px
$button-font-size: calc((1rem / 16) * 13); // 13px
$heading-font-size: calc((1rem / 16) * 25); // 25px
$secondary-font-size: calc((1rem / 16) * 20); // 20px

$primary-heading-size: calc((1rem / 16) * 18); // 18px
$subheading-font-size: 1rem; // 16px

:root {
	--base-font-size: #{$base-font-size};
	--base-font-family: #{$base-font-family};
	--base-line-height: #{$base-line-height};
	--base-font-weight: #{$base-font-weight};

	--heading-font-size: #{$heading-font-size};
	--heading-font-family: #{$heading-font-family};
	--heading-font-weight: #{$heading-font-weight};

	--login-font-family: #{$login-font-family};

	--secondary-font-size: #{$secondary-font-size};

	--primary-heading-size: #{$primary-heading-size};
	--subheading-font-size: #{$subheading-font-size};
	--button-font-size: #{$button-font-size};
	--small-font-size: #{$small-font-size};
}

html {
	font-family: $base-font-family;
	line-height: $base-line-height;
	font-weight: $base-font-weight;
	font-size: $base-font-size;
	color: var(--base-text-colour);
}

h1 {
	font-size: var(--secondary-font-size);
}

h2 {
	font-family: $heading-font-family;
	font-size: $primary-heading-size;
	font-weight: $heading-font-weight;
	color: var(--heading-text-colour);
}

h3 {
	font-family: $heading-font-family;
	font-size: $subheading-font-size;
	margin: 8px 0px;
	color: var(--base-text-colour);
}

p {
	font-size: $base-font-size;
	line-height: $base-line-height * 1.4;
	color: $paragraph-text-color;
}

label {
	font-family: $base-font-family;
	font-size: calc((1rem / 16) * 14); // 14px
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding-left: 3px;
	padding-top: 1px;
	color: var(--base-text-colour);
}

h1,
h2,
h3,
p,
label {
	// overflow: hidden;
	// white-space: nowrap;
	// text-overflow: ellipsis;
}
