.modal-edit-media {
	.edit-media-modal {
		width: 500px;

		.edit-media-modal__enabled-row {
			display: flex;
			align-items: center;
			flex-direction: row;
			label {
				flex-grow: 1;
			}
		}

		.input {
			label {
				margin: 5px 0;
			}
		}

		.input:first-child {
			margin-top: 0;
		}

		p {
			margin-top: -0.5rem;
			padding-left: 3px;
		}

		.modal-footer {
			margin-top: 2rem;
			display: flex;
			justify-content: center;
			// justify-content: flex-end;
			.button {
				margin-left: 1rem;
			}
		}
	}
}
