input,
textarea {
	user-select: auto;
}

select {
	background-color: var(--input-background-colour);
	cursor: pointer;
}

textarea {
	font-family: inherit;
	padding: 8px;
	border-radius: $input-border-radius;
	border: solid 1px var(--border-colour-1);
	font-size: 1em;
	outline: none;
	max-width: 100%;
}

input[type='text'],
input[type='number'] {
	font-family: inherit;
	padding: 4px 6px;
	border-radius: $input-border-radius;
	border: solid 1px $input-border-color;
	font-size: 1em;
	outline: none;
	max-width: 100%;
	background-color: var(--input-background-colour);
	transition: all 0.3s $ease-out-exponential;
}

.input {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 16px 0px;

	input,
	textarea,
	select,
	.flag-select {
		font-family: inherit;
		padding: 6px 10px;
		border-radius: $input-border-radius;
		border: solid 1px $input-border-color;
		font-size: 1em;
		outline: none;
		max-width: 100%;
		background-color: var(--input-background-colour);
		transition: all 0.3s $ease-out-exponential;
		color: var(--input-text-colour);
	}
}

input,
textarea,
select {
	&:active,
	&:focus {
		border-color: $active-input-border-color;
	}
}

input:not(:disabled):not(:focus),
textarea:not(:disabled):not(:focus),
select:not(:disabled):not(:focus) {
	&:hover {
		border-color: $hover-input-border-color;
	}
}

.input.row {
	display: flex;
	flex-direction: row;
	align-items: center;
	label {
		margin-right: 1rem;
	}
}

input[type='number']:not(:disabled),
input[type='text']:not(:disabled),
textarea:not(:disabled) {
	cursor: text !important;
}

.input--disabled {
	textarea {
		border-color: $input-disabled-border-colour;
	}
	input {
		background-color: $input-disabled-background;
		color: #989898;
		border-color: transparent;
	}
}

select:disabled,
textarea:disabled {
	opacity: 1;
	border-color: $input-disabled-border-colour;
	background-color: $input-disabled-background;
	cursor: default;
}

// If a field has required="required" but no value...
select:not(:valid):not(:disabled),
input:not(:valid):not(:disabled) {
	border-color: $error-input-border-color;
}

.input--error {
	input,
	textarea,
	select {
		border-color: $error-input-border-color;
	}
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: var(--placeholder-colour);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
[placeholder] {
	text-overflow: ellipsis;
}

.input .input__eye {
	height: 100%;
	position: absolute;
	right: 12px;
	top: 0;
	cursor: pointer;
	background-color: transparent;
	fill: rgba(128, 128, 128, 0.2);
	width: 24px;
	* {
		cursor: pointer;
	}
	svg {
		height: 100%;
		width: 100%;
	}
}

// To make space for eye (display password)
.input input[type='password'] {
	padding-right: 40px;
}

.input .input__caps-msg {
	color: rgba(255, 255, 255, 0.9);
	position: absolute;
	height: 16px;
	bottom: -24px;
	font-size: 1rem;
	width: 100%;
	text-align: center;
}

/* For Arabic users, input fields should be RTL by default. 
   However, we will switch them to LTR with JS if they start typing non-arabic characters. */
.lang-ar {
	input,
	select,
	textarea {
		direction: rtl;
	}
}
